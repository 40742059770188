<template>
    <v-container>
       <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="68">
        AI is preparing your resource
      </v-progress-circular>
    </v-overlay>
     <v-card max-width="1100" class="mx-auto">

     
            <v-data-table
    :headers="headers"
    :items="licences"
    class="elevation-1 pa-5 mb-15"
    :search="search"
     
  >
  <template
      v-slot:[`item.users`]="{ item }"

      >
      <v-icon @click="selecteditem= item, viewUsersDialog = true"> mdi-eye</v-icon>
    </template>
            </v-data-table>
            <v-btn
            @click="addDialog=true">add new</v-btn>
     </v-card>

 <v-dialog v-model="viewUsersDialog" max-width="400" scrollable>
<v-card>
    <v-card-title>Details ({{selecteditem.schoolname}})  <v-spacer /> <v-icon @click="viewUsersDialog = false">mdi-close </v-icon></v-card-title>
    <v-card-text class="mt-3">
    <v-text-field
    v-model="selecteditem.schoolname"
    label="School Name"
    > </v-text-field>
    <v-text-field
    v-model="selecteditem.quantity"
    label="Quantity"
    > </v-text-field>
    <v-text-field
    v-model="selecteditem.expiration"
    label="Expire"
    > </v-text-field>
     <v-text-field
    v-model="selecteditem.domain"
    label="Domain"
    > </v-text-field>
       
  </v-card-text>
  <v-divider></v-divider>
    <v-card-text> 
      
        <v-container>
        <v-text-field v-model="enterUsers" label="New user" />
    <v-btn color="primary" @click="addUsers()">Update/Save</v-btn>

    <v-list>
      <v-list-item v-for="(item, index) in selecteditem.users" :key="index">
        <v-list-item-content>
          {{ item }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn color="error" @click="removeUser(index)">Remove</v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>

<!-- 
          {{ selecteditem.users }}
          <br />
          <v-textarea
          v-model="enterUsers"
          label="Enter new users separated with comma"
          >

          </v-textarea>
          <v-btn @click="addUsers()">Add</v-btn> -->


    </v-card-text>
  
</v-card>
 </v-dialog>
      <v-dialog v-model="addDialog" max-width="400" persistent >
<v-card>
  <v-toolbar dark color="red"
  >
    <v-toolbar-title> Add Licence</v-toolbar-title>
  </v-toolbar>
  <v-card-text class="mt-3">
    <v-text-field
    v-model="newLicense.schoolname"
    label="School Name"
    > </v-text-field>
    <v-text-field
    v-model="newLicense.quantity"
    label="Quantity"
    > </v-text-field>
    <v-text-field
    v-model="newLicense.expiration"
    label="Expire"
    > </v-text-field>
     <v-text-field
    v-model="newLicense.domain"
    label="Domain"
    > </v-text-field>
       
  </v-card-text>
  <v-card-actions>
    <v-spacer />
    <v-btn
    outlined
    @click="addDialog=false"
    >Cancel</v-btn>
    <v-btn
color="primary" outlined
    @click="Save()">Add</v-btn>
  </v-card-actions>
</v-card>
    </v-dialog>
<!-- <v-card>
  <v-btn @click="uploadStandards()"> Upload Standards</v-btn>
</v-card> -->

    <v-card>
       <div class="voice">
        <v-btn color="red" @click="startSpeechToTxt">speech-to-text</v-btn>
    <div class="speech-to-txt" >Speech to txt</div>        
    <p>{{transcription_}}</p>
</div>
    </v-card>
    <v-card class="mt10">
      <v-text-field v-model="videoID"> video id</v-text-field>
      <v-btn color="blue" @click="getText()">get text</v-btn>
    </v-card>
    <v-card class="mt-10">
      <v-card-title> Test prompts</v-card-title>
      <v-card-text>
        <v-textarea  v-model="request" label="request"></v-textarea>
        <v-btn @click="sendRequest()">Send OpenAi</v-btn>
        <v-btn @click="sendRequestClaude()">Send Claude</v-btn>
        <div v-html="response"></div>
        
      </v-card-text>
    </v-card>
    </v-container>
</template>
<script>
import { db } from "../firebase";
//import { firebaseAuth } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { YoutubeTranscript } from 'youtube-transcript';
export default {
  
  data() {
    return {
      runtimeTranscription_: "",
       transcription_: [],
       lang_: "es-ES",
        licences:[],
        search:"",
        request:"",
        response:"",
        videoID:"",
        isLoading:false,
        addDialog:false, 
        selecteditem:{},
        viewUsersDialog:false,
        enterUsers:"",
        newLicense:{
           schoolname :"",
           quantity: "",
           expiration:"",
           domain:"",
           users:[]
        }
    }
    },
    computed: {
  headers() {
      let headers=[
          { text: "School", value: "schoolname" },
          { text: "Quantity", value: "quantity" },
          { text: "Expire", value: "expiration" },
          { text: "Licence", value: "id" },
          { text: "Users", value: "users" },
  ]
  return headers
  }
},
    methods: {
      startSpeechToTxt() {
    // initialisation of voicereco
    
    window.SpeechRecognition =
    window.SpeechRecognition || 
    window.webkitSpeechRecognition;
    const recognition = new window.SpeechRecognition();
    recognition.lang = this.lang_;
    recognition.interimResults = true;

    // event current voice reco word
    recognition.addEventListener("result", event => {      
      var text = Array.from(event.results)
        .map(result => result[0])
        .map(result => result.transcript)
        .join("");
      this.runtimeTranscription_ = text;
    });
    // end of transcription
    recognition.addEventListener("end", () => {
      this.transcription_.push(this.runtimeTranscription_);
      this.runtimeTranscription_ = "";
      recognition.stop();
    });
     recognition.start();
   },

     async getText(){
        YoutubeTranscript.fetchTranscript(this.videoID).then(console.log);
      },
      async sendRequestClaude(){
        this.isLoading = true;

      let  promt= "\n\nHuman: {"+this.request+"}\n\nAssistant:"

      const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenaiForTestCaude");
        const { data } = await functionRef({
          promt: promt,
        });

        this.response = data.result;
        this.isLoading = false;
        
       
       } catch (error) {
        this.isLoading = false;
        this.response = null;
       
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }

      },
      async sendRequest(){
       this.isLoading = true;

      let  promt= [
          {
            role: "system",
            content: "You are an helpfull assistant and educator",
          },
          {
            role: "user",
            content:this.request
          },
        
        ]
      
      const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenaiForTest");
        const { data } = await functionRef({
          promt: promt,
        });

        this.response = data.result;
        this.isLoading = false;
        
       
       } catch (error) {
        this.isLoading = false;
        this.response = null;
       
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }

      },
      async  addUsers(){
        if(this.enterUsers !== ""){   
            this.enterUsers.split(',').forEach(element => {
                this.selecteditem.users.push(element)   
            })
          }
            try {
        const userRef = db.collection("schoollicence").doc(this.selecteditem.id);
       
         await userRef.update({
          users:this.selecteditem.users,
          domain:this.selecteditem.domain,
          schoolname:this.selecteditem.schoolname,
          quantity:this.selecteditem.quantity,
          expiration:this.selecteditem.expiration,
        });
       this.viewUsersDialog=false
       this.enterUsers=""
      } catch (error) {
        console.error("Error adding document:", error);
      }
    
        },
       async removeUser(index){
        this.selecteditem.users.splice(index, 1)
        
            try {
        const userRef = db.collection("schoollicence").doc(this.selecteditem.id);
       
         await userRef.update({
users:this.selecteditem.users
        });
     
      } catch (error) {
        console.error("Error adding document:", error);
      }

 
        },

        async Save() {
        this.isLoading = true

     let newlicence = this.newLicense
     try {
        const userRef = db.collection("schoollicence");
       
        const docRef = await userRef.add(newlicence);
        console.log(docRef)
       // this.id=docRef.id
      } catch (error) {
        console.error("Error adding document:", error);
      }
       this.isLoading = false
       this.addDialog = false

        },

        async updateLicenses(){
    this.licences=[...[]]

     try {
await db.collection('schoollicence').get().then((res)=>{
res.forEach((doc)=> {
    // You can add the document ID to the object if needed
    var docData = doc.data();
    docData.id = doc.id;

    // Add the document data to the array
    this.licences.push(docData);
   
  });
})
  
   } catch (error) {
    console.error('Error adding document:', error);
  }
  
    },

    //hardcoded standard upload use onece a while
   async uploadStandards(){
    this.isLoading = true

     let Standards = {
      "state":"NY",
      "name":"NextGen NewYork",
      "uploaded":"Nov,2023",
      "standards":
      [
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.1.",
    "Description": "Print upper- and lowercase letters in their name → Print many upper- and lowercase letters → Print all upper- and lowercase letters."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.10.",
    "Description": "Use verbs to convey a sense of past, present, and future (e.g., Yesterday I walked home; Today I walk home; Tomorrow I will walk home). → Form and use the past tense of frequently occurring irregular verbs (e.g., sat, hid, told)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.11.",
    "Description": "Use frequently occurring adjectives. → Use adjectives or adverbs appropriately."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.12.",
    "Description": "Use frequently occurring conjunctions (e.g., and, but, or, so because)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.13.",
    "Description": "Understand and use simple and compound sentences in speech or writing (e.g., The child read the book; The child read the book, but she did not watch the movie)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.2.",
    "Description": "Use frequently occurring nouns and verbs (orally) → Use frequently occurring nouns and verbs. →Use common, proper, and possessive nouns. → Use collective nouns (e.g., group)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.3.",
    "Description": "Form regular plural nouns orally (e.g., dog, dogs; wish, wishes)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.4.",
    "Description": "Form and use frequently occurring irregular plural nouns (e.g., feet, children, mice, fish)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.5.",
    "Description": "Use singular and plural nouns with matching verbs in basic sentences (e.g., The boy jumps; The boys jump)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.6.",
    "Description": "Understand and use interrogatives (question words—e.g., who, what, where, when, why, how)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.7.",
    "Description": "Use frequently occurring prepositions orally (e.g., to, from, in, out, on, off, for, of, by, with)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.8.",
    "Description": "Produce and expand complete sentences in shared language activities."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.9.",
    "Description": "Use personal, possessive, and indefinite pronouns (e.g., I, me, my; they, them, their, anyone, everything). → Use reflexive pronouns (e.g., myself, ourselves)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL2.1.",
    "Description": "Capitalize the first letter of their name. → Capitalize dates and names of people. → Capitalize the first word in a sentence and the pronoun I. → Capitalize names, places, and holidays."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL2.2.",
    "Description": "Attempt to write symbols or letters to represent words."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL2.3.",
    "Description": "Recognize and name end punctuation. →Use end punctuation for sentences."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL2.4.",
    "Description": "Write a letter or letters for most consonant and short-vowel sounds (phonemes)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL2.5.",
    "Description": "Spell simple words phonetically, drawing on knowledge of sound-letter relationships. → Spell unknown words phonetically, drawing on phonemic awareness and spelling conventions. →Use conventional spelling for words with common spelling patterns and for frequently occurring irregular words.→ Generalize learned spelling patterns when writing words (e.g., cage → badge; boy → boil)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL2.6.",
    "Description": "Use commas in dates and to separate single words in a series. → Use commas in greetings and closings of letters."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL2.7.",
    "Description": "Use an apostrophe to form contractions and frequently occurring possessives."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL2.8.",
    "Description": "Consult reference materials as needed to check and correct spellings."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL4a.",
    "Description": "Identify new meanings for familiar words and apply them accurately (e.g., knowing duck is a bird and learning the verb to duck)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL4b.",
    "Description": "Use the most frequently occurring inflections and affixes (e.g., -ed, -s, re-, un-, pre-, -ful, -less) as a clue to the meaning of a word."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL5a.",
    "Description": "Sort common objects into categories (e.g., shapes, foods) for understanding of the concepts the categories represent."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL5b.",
    "Description": "Demonstrate understanding of frequently occurring verbs and adjectives by relating them to their opposites (antonyms)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL5c.",
    "Description": "Use words to identify and describe the world, making connections between words and their use (e.g., places at home that are colorful)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL5d.",
    "Description": "Distinguish shades of meaning among verbs describing the same general action (e.g., walk, march, strut, prance) by acting out the meanings."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KLS1a.",
    "Description": "Follow agreed-upon rules for discussions, including listening to others, taking turns, and staying on topic."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF1a.",
    "Description": "Follow words from left to right, top to bottom, and page by page."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF1b.",
    "Description": "Recognize that spoken words are represented in written language by specific sequences of letters."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF1c.",
    "Description": "Understand that words are separated by spaces in print."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF1d.",
    "Description": "Recognize and name all upper- and lowercase letters of the alphabet."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF1e.",
    "Description": "Identify the front cover, back cover, and title page of a book."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF2a.",
    "Description": "Recognize and produce spoken rhyming words."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF2b.",
    "Description": "Count, blend and segment syllables in spoken words."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF2c.",
    "Description": "Blend and segment onsets and rimes of spoken words."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF2d.",
    "Description": "Blend and segment individual sounds (phonemes) in spoken one-syllable words."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF2e.",
    "Description": "Create new words by manipulating the phonemes orally in one-syllable words."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF3a.",
    "Description": "Demonstrate one-to-one letter-sound correspondence by producing the primary sound or most frequent sound for each consonant."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF3b.",
    "Description": "Decode short vowel sounds with common spellings."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF3c.",
    "Description": "Decode some regularly spelled one-syllable words."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF3d.",
    "Description": "Read common high-frequency words by sight."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF3e.",
    "Description": "Begins in 1st Grade."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KSL1b.",
    "Description": "Participate in conversations through multiple exchanges."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades Prekindergarten→Grade 0.0833333333333333"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades P→2:"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL3.",
    "Description": "(Begins in grade 2)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL5.",
    "Description": "Explore and discuss word relationships and word meanings."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KL6.",
    "Description": "Use words and phrases acquired through conversations, reading and being read to, and responding to texts."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KR1.",
    "Description": "Ask and answer questions about a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KR2.",
    "Description": "Retell stories or share key details from a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KR3.",
    "Description": "Identify characters, settings, major events in a story, or pieces of information in a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KR4.",
    "Description": "Identify specific words that express feelings and senses. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KR5.",
    "Description": "Identify literary and informational texts. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KR6.",
    "Description": "Name the author and illustrator and define the role of each in presenting the ideas in a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KR7.",
    "Description": "Describe the relationship between illustrations and the text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KR8.",
    "Description": "Identify specific information to support ideas in a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KR9.",
    "Description": "Make connections between self, text, and the world. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF1.",
    "Description": "Demonstrate understanding of the organization and basic features of print."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF2.",
    "Description": "Demonstrate understanding of spoken words, syllables, and sounds (phonemes)."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF3.",
    "Description": "Know and apply grade-level phonics and word analysis skills in decoding words."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KRF4:",
    "Description": "Will engage with emergent level texts and read-alouds to demonstrate comprehension."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KSL1.",
    "Description": "Participate in collaborative conversations with peers and adults in small and large groups and during play."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KSL2.",
    "Description": "Participate in a conversation about features of diverse texts and formats."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KSL3.",
    "Description": "Ask and answer questions to clarify what the speaker says."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KSL4.",
    "Description": "Name familiar people, places, things, and events with detail."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KSL5.",
    "Description": "Create visual displays to support descriptions."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KSL6.",
    "Description": "Express thoughts, feelings, and ideas clearly and completely."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KW1.",
    "Description": "Use a combination of drawing, dictating, oral expression, and/or writing to state an opinion about a familiar topic and state a reason to support that topic."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KW2.",
    "Description": "Use a combination of drawing, dictating, oral expression, and/or writing to name a familiar topic and supply information."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KW3.",
    "Description": "Use a combination of writing, drawing, dictating, and/or oral expression to narrate an event or events in a sequence."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KW4.",
    "Description": "Create a poem, dramatization, art work, or other response to a text, author, or personal experience."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KW5.",
    "Description": "Begins in Grade 4"
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KW6.",
    "Description": "Ask questions and participate in shared research to answer questions and to build knowledge."
  },
  {
    "Course": "ELA",
    "Grade": "K",
    "Standard": "KW7.",
    "Description": "Recall relevant information from experiences or gather information from provided sources to answer a question."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.1.",
    "Description": "Print upper- and lowercase letters in their name → Print many upper- and lowercase letters → Print all upper- and lowercase letters."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.10.",
    "Description": "Use verbs to convey a sense of past, present, and future (e.g., Yesterday I walked home; Today I walk home; Tomorrow I will walk home). → Form and use the past tense of frequently occurring irregular verbs (e.g., sat, hid, told)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.11.",
    "Description": "Use frequently occurring adjectives. → Use adjectives or adverbs appropriately."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.12.",
    "Description": "Use frequently occurring conjunctions (e.g., and, but, or, so because)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.13.",
    "Description": "Understand and use simple and compound sentences in speech or writing (e.g., The child read the book; The child read the book, but she did not watch the movie)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.2.",
    "Description": "Use frequently occurring nouns and verbs (orally) → Use frequently occurring nouns and verbs. →Use common, proper, and possessive nouns. → Use collective nouns (e.g., group)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.3.",
    "Description": "Form regular plural nouns orally (e.g., dog, dogs; wish, wishes)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.4.",
    "Description": "Form and use frequently occurring irregular plural nouns (e.g., feet, children, mice, fish)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.5.",
    "Description": "Use singular and plural nouns with matching verbs in basic sentences (e.g., The boy jumps; The boys jump)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.6.",
    "Description": "Understand and use interrogatives (question words—e.g., who, what, where, when, why, how)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.7.",
    "Description": "Use frequently occurring prepositions orally (e.g., to, from, in, out, on, off, for, of, by, with)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.8.",
    "Description": "Produce and expand complete sentences in shared language activities."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.9.",
    "Description": "Use personal, possessive, and indefinite pronouns (e.g., I, me, my; they, them, their, anyone, everything). → Use reflexive pronouns (e.g., myself, ourselves)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L2.1.",
    "Description": "Capitalize the first letter of their name. → Capitalize dates and names of people. → Capitalize the first word in a sentence and the pronoun I. → Capitalize names, places, and holidays."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L2.2.",
    "Description": "Attempt to write symbols or letters to represent words."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L2.3.",
    "Description": "Recognize and name end punctuation. →Use end punctuation for sentences."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L2.4.",
    "Description": "Write a letter or letters for most consonant and short-vowel sounds (phonemes)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L2.5.",
    "Description": "Spell simple words phonetically, drawing on knowledge of sound-letter relationships. → Spell unknown words phonetically, drawing on phonemic awareness and spelling conventions. →Use conventional spelling for words with common spelling patterns and for frequently occurring irregular words.→ Generalize learned spelling patterns when writing words (e.g., cage → badge; boy → boil)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L2.6.",
    "Description": "Use commas in dates and to separate single words in a series. → Use commas in greetings and closings of letters."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L2.7.",
    "Description": "Use an apostrophe to form contractions and frequently occurring possessives."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L2.8.",
    "Description": "Consult reference materials as needed to check and correct spellings."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L4a.",
    "Description": "Use sentence-level context as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L4b.",
    "Description": "Use frequently occurring affixes as a clue to the meaning of a word."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L4c.",
    "Description": "Identify frequently occurring root words (e.g., look) and their inflectional forms (e.g., looks, looked, looking)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L5a.",
    "Description": "Sort words into categories (e.g., colors, clothing) to gain a sense of the concepts the categories represent."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L5b.",
    "Description": "Define words by category and by one or more key attributes (e.g., a duck is a bird that swims; a tiger is a large cat with stripes)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L5c.",
    "Description": "Use words for identification and description, making connections between words and their use (e.g., places at home that are cozy)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L5d.",
    "Description": "Distinguish shades of meaning among verbs differing in manner (e.g., look, peek, glance, stare, glare, scowl) and adjectives differing in intensity (e.g., large, gigantic) by defining or choosing them or by acting out the meanings."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1LS1a.",
    "Description": "Follow agreed-upon rules for discussions and participate by actively listening, taking turns, and staying on topic."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF1a.",
    "Description": "Recognize the distinguishing features of a sentence (e.g., first word, capitalization, ending punctuation)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF2a.",
    "Description": "Blend and segment onsets and rhymes of spoken one-syllable words."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF2b.",
    "Description": "Count, blend and segment single syllable words that include consonant blends like claw and trip."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF2c.",
    "Description": "Create new words by manipulating individual sounds (phonemes) in spoken one-syllable words."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF2d.",
    "Description": "#NAME?"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF3a.",
    "Description": "Know the letter-sound correspondences for common blends and consonant digraphs (e.g. sh, ch, th)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF3b.",
    "Description": "Decode long vowel sounds in regularly spelled one-syllable words (for example, final –e conventions and common vowel teams)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF3c.",
    "Description": "Decode regularly spelled one-syllable words. Determine the number of syllables in a printed word by using knowledge that every syllable must have a vowel sound."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF3d.",
    "Description": "Recognize and identify root words and simple suffixes (e.g. run, runs, walk, walked)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF3e.",
    "Description": "Read most common high-frequency words by sight."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF4a.",
    "Description": "Read grade-level text orally with accuracy, appropriate rate, and expression on successive readings."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF4b.",
    "Description": "Use context to confirm or self-correct word recognition and understanding, rereading as necessary."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1SL1b.",
    "Description": "Build on others’ talk in conversations by responding to the comments of others through multiple exchanges."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1SL1c.",
    "Description": "Ask questions to clear up any confusion about topics and texts under discussion."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades Prekindergarten→Grade 0.0833333333333333"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades P→2:"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L3.",
    "Description": "(Begins in grade 2)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from an array of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L5.",
    "Description": "Demonstrate understanding of word relationships and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1L6.",
    "Description": "Use words and phrases acquired through conversations, reading and being read to, and responding to texts, including using frequently occurring conjunctions to signal simple relationships (e.g., because)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1R1.",
    "Description": "Develop and answer questions about key ideas and details in a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1R2.",
    "Description": "Identify a main topic or central idea in a text and retell important details. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1R3.",
    "Description": "Describe characters, settings, and major events in a story, or pieces of information in a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1R4.",
    "Description": "Identify specific words that express feelings and senses. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1R5.",
    "Description": "Identify a variety of genres and explain major differences between literary texts and informational texts. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1R6.",
    "Description": "Describe how illustrations and details support the point of view or purpose of the text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1R7.",
    "Description": "Use illustrations and details in literary and informational texts to discuss story elements and/or topics. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1R8.",
    "Description": "Identify specific information an author or illustrator gives that supports ideas in a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1R9.",
    "Description": "Make connections between self and text (texts and other people/ world). (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF1.",
    "Description": "Demonstrate understanding of the organization and basic features of print."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF2.",
    "Description": "Demonstrate understanding of spoken words, syllables, and sounds (phonemes)."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF3.",
    "Description": "Know and apply grade-level phonics and word analysis skills in decoding words."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1RF4.",
    "Description": "Read grade-level text with sufficient accuracy and fluency to support comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1SL1.",
    "Description": "Participate in collaborative conversations with diverse peers and adults in small and large groups and during play."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1SL2.",
    "Description": "Develop and answer questions about key details in diverse texts and formats."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1SL3.",
    "Description": "Develop and answer questions to clarify what the speaker says and identify a speaker’s point of view."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1SL4.",
    "Description": "Describe familiar people, places, things, and events with relevant details expressing ideas clearly."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1SL5.",
    "Description": "Create visual displays to support descriptions to clarify ideas, thoughts, and feelings."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1SL6.",
    "Description": "Express thoughts, feelings, and ideas clearly, using complete sentences when appropriate to task, situation, and audience."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1W1.",
    "Description": "Write an opinion on a topic; give two or more reasons to support that opinion."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1W2.",
    "Description": "Write an informative/explanatory text to introduce a topic, supplying some facts to develop points."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1W3.",
    "Description": "Write narratives which recount real or imagined experiences or events or a short sequence of events."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1W4.",
    "Description": "Create a poem, dramatization, art work, or other response to a text, author, theme or personal experience."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1W5.",
    "Description": "Begins in Grade 4"
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1W6.",
    "Description": "Ask questions and participate in shared research to answer questions and to build knowledge."
  },
  {
    "Course": "ELA",
    "Grade": 1,
    "Standard": "1W7.",
    "Description": "Recall relevant information from experiences or gather information from provided sources to answer a question."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.1.",
    "Description": "Print upper- and lowercase letters in their name → Print many upper- and lowercase letters → Print all upper- and lowercase letters."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.10.",
    "Description": "Use verbs to convey a sense of past, present, and future (e.g., Yesterday I walked home; Today I walk home; Tomorrow I will walk home). → Form and use the past tense of frequently occurring irregular verbs (e.g., sat, hid, told)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.11.",
    "Description": "Use frequently occurring adjectives. → Use adjectives or adverbs appropriately."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.12.",
    "Description": "Use frequently occurring conjunctions (e.g., and, but, or, so because)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.13.",
    "Description": "Understand and use simple and compound sentences in speech or writing (e.g., The child read the book; The child read the book, but she did not watch the movie)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.2.",
    "Description": "Use frequently occurring nouns and verbs (orally) → Use frequently occurring nouns and verbs. →Use common, proper, and possessive nouns. → Use collective nouns (e.g., group)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.3.",
    "Description": "Form regular plural nouns orally (e.g., dog, dogs; wish, wishes)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.4.",
    "Description": "Form and use frequently occurring irregular plural nouns (e.g., feet, children, mice, fish)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.5.",
    "Description": "Use singular and plural nouns with matching verbs in basic sentences (e.g., The boy jumps; The boys jump)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.6.",
    "Description": "Understand and use interrogatives (question words—e.g., who, what, where, when, why, how)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.7.",
    "Description": "Use frequently occurring prepositions orally (e.g., to, from, in, out, on, off, for, of, by, with)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.8.",
    "Description": "Produce and expand complete sentences in shared language activities."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.9.",
    "Description": "Use personal, possessive, and indefinite pronouns (e.g., I, me, my; they, them, their, anyone, everything). → Use reflexive pronouns (e.g., myself, ourselves)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L2.1.",
    "Description": "Capitalize the first letter of their name. → Capitalize dates and names of people. → Capitalize the first word in a sentence and the pronoun I. → Capitalize names, places, and holidays."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L2.2.",
    "Description": "Attempt to write symbols or letters to represent words."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L2.3.",
    "Description": "Recognize and name end punctuation. →Use end punctuation for sentences."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L2.4.",
    "Description": "Write a letter or letters for most consonant and short-vowel sounds (phonemes)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L2.5.",
    "Description": "Spell simple words phonetically, drawing on knowledge of sound-letter relationships. → Spell unknown words phonetically, drawing on phonemic awareness and spelling conventions. →Use conventional spelling for words with common spelling patterns and for frequently occurring irregular words.→ Generalize learned spelling patterns when writing words (e.g., cage → badge; boy → boil)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L2.6.",
    "Description": "Use commas in dates and to separate single words in a series. → Use commas in greetings and closings of letters."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L2.7.",
    "Description": "Use an apostrophe to form contractions and frequently occurring possessives."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L2.8.",
    "Description": "Consult reference materials as needed to check and correct spellings."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L3a.",
    "Description": "Compare formal and informal uses of English."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L4a.",
    "Description": "Use sentence-level context as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L4b.",
    "Description": "Determine the meaning of the new word formed when a known prefix is added to a known word (e.g., happy/unhappy, tell/retell)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L4c.",
    "Description": "Use a known root word as a clue to the meaning of an unknown word with the same root (e.g., addition, additional)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L4d.",
    "Description": "Use knowledge of the meaning of individual words to predict the meaning of compound words (e.g., birdhouse, lighthouse, housefly; bookshelf, notebook, bookmark)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L4e.",
    "Description": "Use glossaries and beginning dictionaries to determine or clarify the meaning of words and phrases."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L5a.",
    "Description": "Identify real-life connections between words and their use."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L5b.",
    "Description": "Use words for identification and description, making connections between words and their use (e.g., describe foods that are spicy or juicy)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L5c.",
    "Description": "Distinguish shades of meaning among closely related verbs (e.g., toss, throw, hurl) and closely related adjectives (e.g., thin, slender, skinny, scrawny)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF3a.",
    "Description": "Distinguish long and short vowels when reading regularly spelled one-syllable words."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF3b.",
    "Description": "Decode short and long vowel sounds in two-syllable words (for example, peanut, sailboat, sunshine, and invite)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF3c.",
    "Description": "Decode regularly spelled two-syllable words."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF3d.",
    "Description": "Recognize and identify root words and common prefixes (e.g. re, reread, un, undo, hop, hopping)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF3e.",
    "Description": "Read all common high-frequency worlds by sight."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF4a.",
    "Description": "Read grade-level text orally with accuracy, appropriate rate, and expression on successive readings."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF4b.",
    "Description": "Use context to confirm or self-correct word recognition and understanding, rereading as necessary."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL1a.",
    "Description": "Follow agreed-upon rules for discussions and participate by actively listening, taking turns, and staying on topic."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL1b.",
    "Description": "Build on others’ talk in conversations by linking their comments to the remarks of others through multiple exchanges."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL1c.",
    "Description": "Ask for clarification and further explanation as needed about topics and texts under discussion."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL1d.",
    "Description": "Consider individual differences when communicating with others."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades Prekindergarten→Grade 0.0833333333333333"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades P→2:"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L3.",
    "Description": "Use knowledge of language and its conventions when writing, speaking, reading, or listening."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from an array of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L5.",
    "Description": "Demonstrate understanding of word relationships and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2L6.",
    "Description": "Use words and phrases acquired through conversations, reading and being read to, and responding to texts, including using adjectives and adverbs to describe (e.g., When other kids are happy that makes me happy)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2R1.",
    "Description": "Develop and answer questions to demonstrate an understanding of key ideas and details in a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2R2.",
    "Description": "Identify a main topic or central idea and retell key details in a text; summarize portions of a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2R3.",
    "Description": "In literary texts, describe how characters respond to major events and challenges. (RL) In informational texts, describe the connections between ideas, concepts, or a series of events. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2R4.",
    "Description": "Explain how words and phrases in a text suggest feelings and appeal to the senses. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2R5.",
    "Description": "Describe the overall structure of a text, including describing how the beginning introduces the text and the ending concludes the text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2R6.",
    "Description": "Identify examples of how illustrations, text features, and details support the point of view or purpose of the text. (RI&RL) Integration of Knowledge and Ideas"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2R7.",
    "Description": "Demonstrate understanding of story elements and/or topics by applying information gained from illustrations or text features. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2R8.",
    "Description": "Explain how specific points the author or illustrator makes in a text are supported by relevant reasons. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2R9.",
    "Description": "Make connections between self and text (texts and other people/ world). (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF1.",
    "Description": "There is not a grade 2 standard for this concept. Please see preceding grades for more information."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF2.",
    "Description": "There is not a grade 2 standard for this concept. Please see preceding grades for more information."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF3.",
    "Description": "Know and apply grade-level phonics and word analysis skills in decoding words."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2RF4.",
    "Description": "Read grade-level text with sufficient accuracy and fluency to support comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL1.",
    "Description": "Participate in collaborative conversations with diverse peers and adults in small and large groups and during play."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL2.",
    "Description": "Recount or describe key ideas or details of diverse texts and formats."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL3.",
    "Description": "Develop and answer questions about what a speaker says; agree or disagree with the speaker’s point of view, providing a reason(s)."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL4.",
    "Description": "Describe people, places, things, and events with relevant details, expressing ideas and feelings clearly."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL5.",
    "Description": "Include digital media and/or visual displays in presentations to clarify ideas, thoughts, and feelings."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2SL6.",
    "Description": "Express thoughts, feelings, and ideas clearly, adapting language according to context."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2W1.",
    "Description": "Write an opinion to support claim(s), using clear reasons and relevant evidence."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2W2.",
    "Description": "Write informative/explanatory texts that introduce a topic, use facts and other information to develop points, and provide a concluding statement or section."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2W3.",
    "Description": "Write narratives which recount real or imagined experiences or a short sequence of events, including details to describe actions, thoughts, and feelings; use temporal words to signal event order, and provide a sense of closure."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme or personal experience."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2W5.",
    "Description": "Begins in Grade 4"
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2W6.",
    "Description": "Ask questions and participate in shared research to answer questions and to build knowledge."
  },
  {
    "Course": "ELA",
    "Grade": 2,
    "Standard": "2W7.",
    "Description": "Recall relevant information from experiences or gather information from provided sources to answer a question."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 3→5:"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.1.",
    "Description": "Produce simple, compound, and complex sentences."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.10.",
    "Description": "Form and use the perfect verb tenses (e.g., I had walked; I have walked; I will have walked)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.11.",
    "Description": "Use verb tense to convey various times, sequences, states, and conditions."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.12.",
    "Description": "Recognize and correct inappropriate shifts in verb tense."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.13.",
    "Description": "Ensure subject-verb and pronoun-antecedent agreement."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.14.",
    "Description": "Use coordinating and subordinating conjunctions."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.15.",
    "Description": "Use and identify prepositional phrases."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.16.",
    "Description": "Produce complete sentences, recognizing and correcting inappropriate fragments and run-ons."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.17.",
    "Description": "Correctly use frequently confused words (e.g., to, too, two; there, their)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.2.",
    "Description": "Explain the function of nouns, pronouns, verbs, adjectives, and adverbs in general as well as in particular sentences."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.3.",
    "Description": "Use relative pronouns (who, whose, whom, which, that) and relative adverbs (where, when, why)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.4.",
    "Description": "Explain the function of conjunctions, prepositions, and interjections in general as well as in particular sentences."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.5.",
    "Description": "Form and use regular and irregular plural nouns."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.6.",
    "Description": "Use abstract nouns."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.7.",
    "Description": "Form and use regular and irregular verbs."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.8.",
    "Description": "Form and use the simple verb tenses (e.g., I walked; I walk; I will walk)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L1.9.",
    "Description": "Form and use the progressive verb tenses (e.g., I was walking; I am walking; I will be walking)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 3→5:"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.1.",
    "Description": "Capitalize appropriate words in titles."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.10.",
    "Description": "Use spelling patterns, rules, and generalizations (e.g., word families, position-based spellings, syllable patterns, ending rules, meaningful word parts) in writing words. → Spell grade-appropriate words correctly, consulting references as needed."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.11.",
    "Description": "Use quotation marks or italics to indicate titles of works."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.2.",
    "Description": "Use correct capitalization."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.3.",
    "Description": "Use commas in addresses."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.4.",
    "Description": "Use commas and quotation marks in dialogue. → Use commas and quotation marks to mark direct speech and quotations from a text."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.5.",
    "Description": "Use a comma before a coordinating conjunction in a compound sentence."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.6.",
    "Description": "Use a comma to separate an introductory element from the rest of the sentence."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.7.",
    "Description": "Use punctuation to separate items in a series."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.8.",
    "Description": "Form and use possessives."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L2.9.",
    "Description": "Use conventional spelling for high-frequency and other studied words, and to add suffixes to base words (e.g., sitting, smiled, cries, happiness)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L3.",
    "Description": "Use knowledge of language and its conventions when writing, speaking, reading, or listening."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L3a.",
    "Description": "Choose words and phrases for effect."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L3b.",
    "Description": "Recognize and observe differences between the conventions of spoken and written standard English."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases based, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L4a.",
    "Description": "Use sentence-level context as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L4b.",
    "Description": "Determine the meaning of the new word formed when a known affix is added to a known word (e.g., agreeable/disagreeable, comfortable/ uncomfortable, care/careless, heat/preheat)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L4c.",
    "Description": "Use a known root word as a clue to the meaning of an unknown word with the same root (e.g., company, companion)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L4d.",
    "Description": "Use glossaries or beginning dictionaries to determine or clarify the precise meaning of key words and phrases."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L5.",
    "Description": "Demonstrate understanding of word relationships and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L5a.",
    "Description": "Distinguish the literal and nonliteral meanings of words and phrases in context (e.g., take steps)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L5b.",
    "Description": "Use words for identification and description, making connections between words and their use (e.g., describe people who are friendly or helpful)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L5c.",
    "Description": "Distinguish shades of meaning among related words that describe states of mind or degrees of certainty (e.g., knew, believed, suspected, heard, wondered)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3L6.",
    "Description": "Acquire and accurately use conversational, general academic, and content-specific words and phrases, including those that signal spatial and temporal relationships (e.g., After dinner that night we went out for dessert)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3R4.",
    "Description": "Determine the meaning of words, phrases, figurative language, and academic and content-specific words. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3R5.",
    "Description": "In literary texts, identify parts of stories, dramas, and poems using terms such as chapter, scene, and stanza. (RL) In informational texts, identify and use text features to build comprehension. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3R6.",
    "Description": "Discuss how the reader’s point of view or perspective may differ from that of the author, narrator or characters in a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3R7.",
    "Description": "Explain how specific illustrations or text features contribute to what is conveyed by the words in a text (e.g., create mood, emphasize character or setting, or determine where, when, why, and how key events occur). (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3R8.",
    "Description": "Explain how claims in a text are supported by relevant reasons and evidence. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3R9.",
    "Description": "Recognize genres and make connections to other texts, ideas, cultural perspectives, eras, personal events, and situations. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3R1.",
    "Description": "Ask and answer questions to locate relevant and specific details in a text to support an answer or inference. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3R2.",
    "Description": "Determine a theme or central idea and explain how it is supported by key details; summarize portions of a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3R3.",
    "Description": "In literary texts, describe character traits, motivations, or feelings, drawing on specific details from the text. (RL) In informational texts, describe the relationship between a series of events, ideas, concepts, or steps in a text, using language that pertains to time, sequence, and cause/effect. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF4.",
    "Description": "Read grade-level text with sufficient accuracy and fluency to support comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF4a.",
    "Description": "Read grade-level text across genres orally with accuracy, appropriate rate, and expression on successive readings."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF4b.",
    "Description": "Use context to confirm or self-correct word recognition and understanding, rereading as necessary."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF3.",
    "Description": "Know and apply grade-level phonics and word analysis skills in decoding words."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF3a.",
    "Description": "Identify and know the meaning of the most common prefixes and suffixes."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF3b.",
    "Description": "There is not a grade 3 standard for this concept."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF3c.",
    "Description": "Decode multi-syllabic words."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF3d.",
    "Description": "Identify, know the meanings of, and decode words with suffixes (e.g., -full, -action, -it)"
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF3e.",
    "Description": "Recognize and read grade-appropriate irregularly spelled words."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF2.",
    "Description": "There is not a grade 3 standard for this concept. Please see preceding grades for more information."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3RF1.",
    "Description": "There is not a grade 3 standard for this concept. Please see preceding grades for more information."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3SL1.",
    "Description": "Engage effectively in a range of collaborative discussions with diverse partners, expressing ideas clearly, and building on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3LS1a.",
    "Description": "Come to discussions prepared, having read or studied required material; draw on that preparation and other information known about the topic to explore ideas under discussion."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3SL1b.",
    "Description": "Follow agreed-upon norms for discussions by actively listening, taking turns, and staying on topic."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3SL1c.",
    "Description": "Ask questions to check understanding of information presented and link comments to the remarks of others."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3SL1d.",
    "Description": "Explain their own ideas and understanding of the discussion."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3SL2.",
    "Description": "Determine the main ideas and supporting details or information presented in diverse texts and formats (e.g., including visual, quantitative, and oral)."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3SL3.",
    "Description": "Ask and answer questions in order to evaluate a speaker’s point of view, offering appropriate elaboration and detail."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3SL4.",
    "Description": "Report on a topic or text, tell a story, or recount an experience with appropriate facts and relevant, descriptive details, speaking clearly at an understandable pace."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3SL5.",
    "Description": "Include digital media and/or visual displays in presentations to emphasize certain facts or details."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3SL6.",
    "Description": "Identify contexts that call for formal English versus/or informal discourse."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W6.",
    "Description": "Conduct research to answer questions, including self-generated questions, and to build knowledge."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W7.",
    "Description": "Recall relevant information from experiences or gather information from multiple sources; take brief notes on sources and sort evidence into provided categories."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W1.",
    "Description": "Write an argument to support claim(s), using clear reasons and relevant evidence."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W1a.",
    "Description": "Introduce a claim, supported by details, and organize the reasons and evidence logically."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W1b.",
    "Description": "Use precise language and content-specific vocabulary."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W1c.",
    "Description": "Use linking words and phrases to connect ideas within categories of information."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W1d.",
    "Description": "Provide a concluding statement or section."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W2.",
    "Description": "Write informative/explanatory texts to explore a topic and convey ideas and information relevant to the subject."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W2a.",
    "Description": "Introduce a topic and organize related information together."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W2b.",
    "Description": "Develop a topic with facts, definitions, and details; include illustrations when useful for aiding comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W2c.",
    "Description": "Use precise language and domain-specific vocabulary."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W2d.",
    "Description": "Use linking words and phrases to connect ideas within categories of information."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W2e.",
    "Description": "Provide a concluding statement or section."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective techniques, descriptive details, and clear event sequences."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W3a.",
    "Description": "Establish a situation and introduce a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W3b.",
    "Description": "Use descriptions of actions, thoughts, and feelings to develop experiences and events or show the response of characters to situations."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W3c.",
    "Description": "Use words and phrases related to time to signal event order."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W3d.",
    "Description": "Provide a conclusion."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme or personal experience."
  },
  {
    "Course": "ELA",
    "Grade": 3,
    "Standard": "3W5.",
    "Description": "Begins in Grade 4"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.1.",
    "Description": "Produce simple, compound, and complex sentences."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.10.",
    "Description": "Form and use the perfect verb tenses (e.g., I had walked; I have walked; I will have walked)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.11.",
    "Description": "Use verb tense to convey various times, sequences, states, and conditions."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.12.",
    "Description": "Recognize and correct inappropriate shifts in verb tense."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.13.",
    "Description": "Ensure subject-verb and pronoun-antecedent agreement."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.14.",
    "Description": "Use coordinating and subordinating conjunctions."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.15.",
    "Description": "Use and identify prepositional phrases."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.16.",
    "Description": "Produce complete sentences, recognizing and correcting inappropriate fragments and run-ons."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.17.",
    "Description": "Correctly use frequently confused words (e.g., to, too, two; there, their)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.2.",
    "Description": "Explain the function of nouns, pronouns, verbs, adjectives, and adverbs in general as well as in particular sentences."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.3.",
    "Description": "Use relative pronouns (who, whose, whom, which, that) and relative adverbs (where, when, why)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.4.",
    "Description": "Explain the function of conjunctions, prepositions, and interjections in general, as well as in particular sentences."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.5.",
    "Description": "Form and use regular and irregular plural nouns."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.6.",
    "Description": "Use abstract nouns."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.7.",
    "Description": "Form and use regular and irregular verbs."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.8.",
    "Description": "Form and use the simple verb tenses (e.g., I walked; I walk; I will walk)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.9.",
    "Description": "Form and use the progressive verb tenses (e.g., I was walking; I am walking; I will be walking)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.1.",
    "Description": "Capitalize appropriate words in titles."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.10.",
    "Description": "Use spelling patterns, rules, and generalizations (e.g., word families, position-based spellings, syllable patterns, ending rules, meaningful word parts) in writing words. → Spell grade-appropriate words correctly, consulting references as needed."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.11.",
    "Description": "Use quotation marks or italics to indicate titles of works."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.2.",
    "Description": "Use correct capitalization."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.3.",
    "Description": "Use commas in addresses."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.4.",
    "Description": "Use commas and quotation marks in dialogue. → Use commas and quotation marks to mark direct speech and quotations from a text."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.5.",
    "Description": "Use a comma before a coordinating conjunction in a compound sentence."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.6.",
    "Description": "Use a comma to separate an introductory element from the rest of the sentence."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.7.",
    "Description": "Use punctuation to separate items in a series."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.8.",
    "Description": "Form and use possessives."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.9.",
    "Description": "Use conventional spelling for high-frequency and other studied words, and to add suffixes to base words (e.g., sitting, smiled, cries, happiness)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L3a.",
    "Description": "Choose words and phrases to convey ideas precisely."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L3b.",
    "Description": "Choose punctuation for effect."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L3c.",
    "Description": "Distinguish between contexts that call for formal English (e.g., presenting ideas) and situations where informal discourse is appropriate (e.g., small-group discussion)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L4a.",
    "Description": "Use context (e.g., definitions, examples, or restatements in text) as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L4b.",
    "Description": "Use common, grade-appropriate Greek and Latin affixes and roots as clues to the meaning of a word (e.g., telegraph, photograph, autograph)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L4c.",
    "Description": "Consult reference materials (e.g., dictionaries, glossaries, thesauruses) to find the pronunciation and determine or clarify the precise meaning of key words and phrases."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L5a.",
    "Description": "Explain the meaning of simple similes and metaphors in context."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L5b.",
    "Description": "Recognize and explain the meaning of common idioms, adages, and proverbs."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L5c.",
    "Description": "Demonstrate understanding of words by relating them to their antonyms and synonyms."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4LS1a.",
    "Description": "Come to discussions prepared, having read or studied required material; draw on that preparation and other information known about the topic to explore ideas under discussion."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4RF3a.",
    "Description": "Use combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology (e.g., roots and affixes) to read accurately unfamiliar multisyllabic words in context and out of context."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4RF3b.",
    "Description": "Use combined knowledge of all letter-sound correspondences, syllabification patterns, and morphology (e.g. roots, prefixes, and suffixes) to read accurately unfamiliar multisyllabic words in and out of context."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4RF4a.",
    "Description": "Read grade-level text across genres orally with accuracy, appropriate rate, and expression on successive readings."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4RF4b.",
    "Description": "Use context to confirm or self-correct word recognition and understanding, rereading as necessary."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4SL1b.",
    "Description": "Follow agreed-upon norms for discussions and carry out assigned roles."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4SL1c.",
    "Description": "Pose and respond to specific questions to clarify or follow up on information, and make comments that contribute to the discussion and link to the remarks of others."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4SL1d.",
    "Description": "Review the relevant ideas expressed and explain their own ideas and understanding of the discussion."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W1a.",
    "Description": "Introduce a precise claim, supported by well-organized facts and details, and organize the reasons and evidence logically."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W1b.",
    "Description": "Use precise language and content-specific vocabulary."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W1c.",
    "Description": "Use transitional words and phrases to connect ideas within categories of information."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W1d.",
    "Description": "Provide a concluding statement or section related to the argument presented."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W2a.",
    "Description": "Introduce a topic clearly and organize related information in paragraphs and sections."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W2b.",
    "Description": "Develop ideas on a topic with facts, definitions, concrete details, or other relevant information; include text features when useful for aiding comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W2c.",
    "Description": "Use precise language and domain-specific vocabulary."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W2d.",
    "Description": "Use transitional words and phrases to connect ideas within categories of information."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W2e.",
    "Description": "Provide a concluding statement or section related to the information or explanation presented."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W3a.",
    "Description": "Establish a situation and introduce a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W3b.",
    "Description": "Use dialogue and description of actions, thoughts, and feelings to develop experiences and events or show the responses of characters to situations."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W3c.",
    "Description": "Use transitional words and phrases to manage the sequence of events."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W3d.",
    "Description": "Use concrete words and phrases and sensory details to convey experiences and events precisely."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W3e.",
    "Description": "Provide a conclusion that follows from the narrated experiences or events."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 3→5:"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 3→5:"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L3.",
    "Description": "Use knowledge of language and its conventions when writing, speaking, reading, or listening."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L5.",
    "Description": "Demonstrate understanding of figurative language, word relationships, and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4L6.",
    "Description": "Acquire and accurately use general academic and content-specific words and phrases, including those that signal precise actions, emotions, or states of being (e.g., quizzed, whined, stammered) and that are basic to a particular topic (e.g., wildlife, conservation, and endangered when discussing animal preservation)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4R1.",
    "Description": "Locate and refer to relevant details and evidence when explaining what a text says explicitly/implicitly and make logical inferences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4R2.",
    "Description": "Determine a theme or central idea of text and explain how it is supported by key details; summarize a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4R3.",
    "Description": "In literary texts, describe a character, setting, or event, drawing on specific details in the text. (RL) In informational texts, explain events, procedures, ideas, or concepts, including what happened and why, based on specific evidence from the text. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4R4.",
    "Description": "Determine the meaning of words, phrases, figurative language, academic, and content-specific words. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4R5.",
    "Description": "In literary texts, identify and analyze structural elements, using terms such as verse, rhythm, meter, characters, settings, dialogue, stage directions. (RL) In informational texts, identify the overall structure using terms such as sequence, comparison, cause/effect, and problem/solution. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4R6.",
    "Description": "In literary texts, compare and contrast the point of view from which different stories are narrated, including the difference between first- and third-person narrations. (RL) In informational texts, compare and contrast a primary and secondary source on the same event or topic. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4R7.",
    "Description": "Identify information presented visually, orally, or quantitatively (e.g., in charts, graphs, diagrams, time lines, animations, illustrations, and explain how the information contributes to an understanding of the text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4R8.",
    "Description": "Explain how claims in a text are supported by relevant reasons and evidence. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4R9.",
    "Description": "Recognize genres and make connections to other texts, ideas, cultural perspectives, eras, personal events, and situations. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4RF1.",
    "Description": "There is not a grade 4 standard for this concept. Please see preceding grades for more information."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4RF2.",
    "Description": "There is not a grade 4 standard for this concept. Please see preceding grades for more information."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4RF3.",
    "Description": "Know and apply grade-level phonics and word analysis skills in decoding words."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4RF4.",
    "Description": "Read grade-level text with sufficient accuracy and fluency to support comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4SL1.",
    "Description": "Engage effectively in a range of collaborative discussions with diverse partners, expressing ideas clearly, and building on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4SL2.",
    "Description": "Paraphrase portions of information presented in diverse formats (e.g., including visual, quantitative, and oral)."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4SL3.",
    "Description": "Identify and evaluate the reasons and evidence a speaker provides to support particular points."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4SL4.",
    "Description": "Report on a topic or text, tell a story, or recount an experience with appropriate facts and relevant, descriptive details, speaking clearly at an understandable pace and volume appropriate for audience."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4SL5.",
    "Description": "Include digital media and/or visual displays in presentations to emphasize main ideas or themes."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4SL6.",
    "Description": "Distinguish between contexts that call for formal English versus/or informal discourse; use formal English when appropriate to task and situation."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W1.",
    "Description": "Write an argument to support claim(s), using clear reasons and relevant evidence."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W2.",
    "Description": "Write informative/explanatory texts to explore a topic and convey ideas and information relevant to the subject."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective technique, descriptive details, and clear event sequences."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme, or personal experience."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W5.",
    "Description": "Draw evidence from literary or informational texts to respond and support analysis, reflection, and research by applying grade 4 reading standards."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W6.",
    "Description": "Conduct research to answer questions, including self-generated questions, and to build knowledge through investigating multiple aspects of a topic."
  },
  {
    "Course": "ELA",
    "Grade": 4,
    "Standard": "4W7.",
    "Description": "Recall relevant information from experiences or gather relevant information from multiple sources; take notes and categorize information, and provide a list of sources."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.1.",
    "Description": "Produce simple, compound, and complex sentences."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.10.",
    "Description": "Form and use the perfect verb tenses (e.g., I had walked; I have walked; I will have walked)."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.11.",
    "Description": "Use verb tense to convey various times, sequences, states, and conditions."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.12.",
    "Description": "Recognize and correct inappropriate shifts in verb tense."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.13.",
    "Description": "Ensure subject-verb and pronoun-antecedent agreement."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.14.",
    "Description": "Use coordinating and subordinating conjunctions."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.15.",
    "Description": "Use and identify prepositional phrases."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.16.",
    "Description": "Produce complete sentences, recognizing and correcting inappropriate fragments and run-ons."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.17.",
    "Description": "Correctly use frequently confused words (e.g., to, too, two; there, their)."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.2.",
    "Description": "Explain the function of nouns, pronouns, verbs, adjectives, and adverbs in general as well as in particular sentences."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.3.",
    "Description": "Use relative pronouns (who, whose, whom, which, that) and relative adverbs (where, when, why)."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.4.",
    "Description": "Explain the function of conjunctions, prepositions, and interjections in general, as well as in particular sentences."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.5.",
    "Description": "Form and use regular and irregular plural nouns."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.6.",
    "Description": "Use abstract nouns."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.7.",
    "Description": "Form and use regular and irregular verbs."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.8.",
    "Description": "Form and use the simple verb tenses (e.g., I walked; I walk; I will walk)."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.9.",
    "Description": "Form and use the progressive verb tenses (e.g., I was walking; I am walking; I will be walking)."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.1.",
    "Description": "Capitalize appropriate words in titles."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.10.",
    "Description": "Use spelling patterns, rules, and generalizations (e.g., word families, position-based spellings, syllable patterns, ending rules, meaningful word parts) in writing words. → Spell grade-appropriate words correctly, consulting references as needed."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.11.",
    "Description": "Use quotation marks or italics to indicate titles of works."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.2.",
    "Description": "Use correct capitalization."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.3.",
    "Description": "Use commas in addresses."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.4.",
    "Description": "Use commas and quotation marks in dialogue. → Use commas and quotation marks to mark direct speech and quotations from a text."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.5.",
    "Description": "Use a comma before a coordinating conjunction in a compound sentence."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.6.",
    "Description": "Use a comma to separate an introductory element from the rest of the sentence."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.7.",
    "Description": "Use punctuation to separate items in a series."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.8.",
    "Description": "Form and use possessives."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.9.",
    "Description": "Use conventional spelling for high-frequency and other studied words, and to add suffixes to base words (e.g., sitting, smiled, cries, happiness)."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L3a.",
    "Description": "Expand, combine, and reduce sentences for meaning, reader/listener interest, and style."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L3b.",
    "Description": "Compare and contrast the varieties of English (e.g., dialects, registers) used in stories, dramas, or poems."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L4a.",
    "Description": "Use context (e.g., cause/effect relationships and comparisons in text) as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L4b.",
    "Description": "Use common, grade-appropriate Greek and Latin affixes and roots as clues to the meaning of a word (e.g., photograph, photosynthesis)."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L4c.",
    "Description": "Consult reference materials (e.g., dictionaries, glossaries, thesauruses) to find the pronunciation and determine or clarify the precise meaning of key words and phrases."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L5a.",
    "Description": "Interpret figurative language, including similes and metaphors, in context."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L5b.",
    "Description": "Recognize and explain the meaning of common idioms, adages, and proverbs."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L5c.",
    "Description": "Use the relationship between particular words (e.g., synonyms, antonyms, homographs) to better understand each of the words."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5LS1a.",
    "Description": "Come to discussions prepared, having read or studied required material; draw on that preparation and other information known about the topic to explore ideas under discussion."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5RF3a.",
    "Description": "Use combined knowledge of all letter-sound correspondences, syllabication patterns, and morphology (e.g., roots and affixes) to read accurately unfamiliar multisyllabic words in context and out of context."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5RF4a.",
    "Description": "Read grade-level text across genres orally with accuracy, appropriate rate, and expression on successive readings."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5RF4b.",
    "Description": "Use context to confirm or self-correct word recognition and understanding, rereading as necessary."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5SL1b.",
    "Description": "Follow agreed-upon norms for discussions and carry out assigned roles."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5SL1c.",
    "Description": "Pose and respond to specific questions by making comments that contribute to the discussion and elaborate on the remarks of others."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5SL1d.",
    "Description": "Consider the ideas expressed and draw conclusion about information and knowledge gained from the discussions."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W1a.",
    "Description": "Introduce a precise claim and organize the reasons and evidence logically."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W1b.",
    "Description": "Provide logically ordered reasons that are supported by facts and details from various sources."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W1c.",
    "Description": "Use precise language and content-specific vocabulary while offering an opinion on a topic."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W1d.",
    "Description": "Use appropriate transitional words, phrases, and clauses to clarify and connect ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W1e.",
    "Description": "Provide a concluding statement or section related to the argument presented."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W1f.",
    "Description": "Maintain a style and tone appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W2a.",
    "Description": "Introduce a topic clearly, provide a general focus, and organize related information logically."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W2b.",
    "Description": "Develop a topic with facts, definitions, concrete details, quotations, or other relevant information; include text features, illustrations, and multimedia to aid comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W2c.",
    "Description": "Use precise language and domain-specific vocabulary to explain a topic."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W2d.",
    "Description": "Use appropriate transitional/linking words, phrases, and clauses to clarify and connect ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W2e.",
    "Description": "Provide a concluding statement or section related to the information or explanation presented."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W2f.",
    "Description": "Establish a style aligned to a subject area or task."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W3a.",
    "Description": "Establish a situation and introduce a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W3b.",
    "Description": "Use narrative techniques, such as dialogue and description, to develop experiences and events or show the responses of characters to situations."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W3c.",
    "Description": "Use a variety of transitional words, phrases, and clauses to manage the sequence of events."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W3d.",
    "Description": "Use concrete words and phrases and sensory details to convey experiences and events precisely."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W3e.",
    "Description": "Provide a conclusion that follows from the narrated experiences or events."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 3→5:"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 3→5:"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L3.",
    "Description": "Use knowledge of language and its conventions when writing, speaking, reading, or listening."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L5.",
    "Description": "Demonstrate understanding of figurative language, word relationships, and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5L6.",
    "Description": "Acquire and accurately use general academic and content-specific words and phrases, including those that signal contrast, addition, and other logical relationships (e.g., however, although, nevertheless, similarly, moreover, in addition)."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5R1.",
    "Description": "Locate and refer to relevant details and evidence when explaining what a text says explicitly/implicitly and make logical inferences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5R2.",
    "Description": "Determine a theme or central idea and explain how it is supported by key details; summarize a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5R3.",
    "Description": "In literary texts, compare and contrast two or more characters, settings, and events, drawing on specific details in the text. (RL) In informational texts, explain the relationships or interactions between two or more individuals, events, ideas, or concepts based on specific evidence from the text. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5R4.",
    "Description": "Determine the meaning of words, phrases, figurative language, academic, and domain- specific words and analyze their effect on meaning, tone, or mood. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5R5.",
    "Description": "In literary texts, explain how a series of chapters, scenes, or stanzas fits together to determine the overall structure of a story, drama, or poem. (RL) In informational texts, compare and contrast the overall structure in two or more texts using terms such as sequence, comparison, cause/effect, and problem/solution. (RL)"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5R6.",
    "Description": "In literary texts, explain how a narrator’s or speaker’s point of view influences how events are described. (RL) In informational texts, analyze multiple accounts of the same event or topic, noting important similarities and differences in the point of view they represent. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5R7.",
    "Description": "Analyze how visual and multimedia elements contribute to meaning of literary and informational texts. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5R8.",
    "Description": "Explain how claims in a text are supported by relevant reasons and evidence, identifying which reasons and evidence support which claims. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5R9.",
    "Description": "Use established criteria to categorize texts and make informed judgments about quality; make connections to other texts, ideas, cultural perspectives, eras and personal experiences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5RF1.",
    "Description": "There is not a grade 5 standard for this concept. Please see preceding grades for more information."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5RF2.",
    "Description": "There is not a grade 5 standard for this concept. Please see preceding grades for more information."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5RF3.",
    "Description": "Know and apply grade-level phonics and word analysis skills in decoding words."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5RF4.",
    "Description": "Read grade-level text with sufficient accuracy and fluency to support comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5SL1.",
    "Description": "Engage effectively in a range of collaborative discussions with diverse partners; express ideas clearly and persuasively, and build on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5SL2.",
    "Description": "Summarize information presented in diverse format (e.g., including visual, quantitative, and oral)."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5SL3.",
    "Description": "Identify and evaluate the reasons and evidence a speaker provides to support particular points."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5SL4.",
    "Description": "Report on a topic or text or present an opinion, sequencing ideas logically and using appropriate facts and relevant, descriptive details to support main ideas or themes; speak clearly at an understandable pace and volume appropriate for audience."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5SL5.",
    "Description": "Include digital media and/or visual displays in presentations to emphasize and enhance main ideas or themes."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5SL6.",
    "Description": "Adapt speech to a variety of contexts and tasks, using formal English when appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W1.",
    "Description": "Write an argument to support claims with clear reasons and relevant evidence."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W2.",
    "Description": "Write informative/explanatory texts to explore a topic and convey ideas and information relevant to the subject."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective techniques, descriptive details, and clear event sequences."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme, or personal experience."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W5.",
    "Description": "Draw evidence from literary or informational texts to respond and support analysis, reflection, and research by applying the Grade 5 Reading Standards."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W6.",
    "Description": "Conduct research to answer questions, including self-generated questions, and to build knowledge through investigation of multiple aspects of a topic using multiple sources."
  },
  {
    "Course": "ELA",
    "Grade": 5,
    "Standard": "5W7.",
    "Description": "Recall relevant information from experiences or gather relevant information from multiple sources; summarize or paraphrase; avoid plagiarism and provide a list of sources."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.1.",
    "Description": "Ensure that pronouns are in the proper case (subjective, objective, and possessive)."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.2.",
    "Description": "Recognize and correct inappropriate shifts in pronoun number and person."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.3.",
    "Description": "Recognize and correct pronouns that have unclear or ambiguous antecedents."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.4.",
    "Description": "Explain the function of phrases and clauses in general, as well as in specific sentences."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.5.",
    "Description": "Place phrases and clauses within a sentence, recognizing and correcting misplaced and dangling modifiers."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.6.",
    "Description": "Use simple, compound, complex, and compound-complex sentences to signal differing relationships among ideas."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.7.",
    "Description": "Explain the function of verbals (gerunds, participles, infinitives)."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.8.",
    "Description": "Form and use verbs in the active and passive voice."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.9.",
    "Description": "Recognize and correct inappropriate verb shifts."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L2.1.",
    "Description": "Use punctuation (commas, parentheses, dashes, hyphens) to clarify and enhance writing."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L2.2.",
    "Description": "Use punctuation (comma, ellipsis, dash) to indicate a pause or break."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L2.3.",
    "Description": "Use an ellipsis to indicate an omission."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L3a.",
    "Description": "Vary sentence patterns for meaning, reader/listener interest, and style."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L3b.",
    "Description": "Maintain consistency in style and tone."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L4a.",
    "Description": "Use context (e.g., the overall meaning of a sentence or paragraph; a word’s position or function in a sentence) as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L4b.",
    "Description": "Use common, grade-appropriate Greek or Latin affixes and roots as clues to the meaning of a word (e.g., audience, auditory, audible)."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L4c.",
    "Description": "Consult reference materials (e.g., dictionaries, glossaries, thesauruses) to find the pronunciation of a word or determine or clarify its precise meaning or its part of speech."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L4d.",
    "Description": "Verify the preliminary determination of the meaning of a word or phrase (e.g., by checking the inferred meaning in context or in a dictionary)."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L5a.",
    "Description": "Interpret figurative language, including personification, in context."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L5b.",
    "Description": "Use the relationship between particular words (e.g., cause/effect, part/whole, item/category) to better understand each of the words."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L5c.",
    "Description": "Distinguish among the connotations of words with similar denotations (e.g., stingy, scrimping, economical, unwasteful, thrifty)."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6LS1a.",
    "Description": "Come to discussions prepared, having read or studied required material; draw on that preparation by referring to evidence on the topic, text, or issue to probe and reflect on ideas under discussion."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6SL1b.",
    "Description": "Follow norms for collegial discussions, set specific goals and deadlines, and define individual roles as needed."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6SL1c.",
    "Description": "Pose and respond to specific questions with elaboration and detail by making comments that contribute to the topic, text, or issue under discussion."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6SL1d.",
    "Description": "Consider the ideas expressed and demonstrate understanding of multiple perspectives through reflection and paraphrasing."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W1a.",
    "Description": "Introduce a precise claim, acknowledge and distinguish the claim from a counterclaim, and organize the reasons and evidence logically."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W1b.",
    "Description": "Support claim(s) with clear reasons and relevant evidence, using credible sources while demonstrating an understanding of the topic or text."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W1c.",
    "Description": "Use precise language and content-specific vocabulary to argue a claim."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W1d.",
    "Description": "Use appropriate transitions to create cohesion and clarify the relationships among ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W1e.",
    "Description": "Provide a concluding statement or section that explains the significance of the argument presented."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W1f.",
    "Description": "Maintain a style and tone appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W2a.",
    "Description": "Introduce a topic clearly; organize ideas, concepts, and information using strategies such as definition, classification, comparison/contrast, and cause/effect."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W2b.",
    "Description": "Develop a topic with relevant facts, definitions, concrete details, quotations, or other information and examples; include formatting, graphics, and multimedia when useful to aid comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W2c.",
    "Description": "Use precise language and domain-specific vocabulary to explain a topic."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W2d.",
    "Description": "Use appropriate transitions to create cohesion and clarify the relationships among ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W2e.",
    "Description": "Provide a concluding statement or section that explains the significance of the information presented."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W2f.",
    "Description": "Establish and maintain a style appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W3a.",
    "Description": "Engage the reader by introducing a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W3b.",
    "Description": "Use narrative techniques, such as dialogue and description, to develop experiences, events, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W3c.",
    "Description": "Use a variety of transitional words, phrases, and clauses to convey sequence and signal shifts from one time frame or setting to another."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W3d.",
    "Description": "Use precise words and phrases, relevant descriptive details, and sensory language to convey experiences and events."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W3e.",
    "Description": "Provide a conclusion that follows from and reflects on what is experienced, observed, or resolved over the course of the narrative."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 6→8:"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 6→8:"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L3.",
    "Description": "Use knowledge of language and its conventions when writing, speaking, reading, or listening."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L5.",
    "Description": "Demonstrate understanding of figurative language, word relationships, and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6L6.",
    "Description": "Acquire and accurately use general academic and content-specific words and phrases; apply vocabulary knowledge when considering a word or phrase important to comprehension or expression."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6R1.",
    "Description": "Cite textual evidence to support an analysis of what the text says explicitly/implicitly and make logical inferences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6R2.",
    "Description": "Determine a theme or central idea of a text and how it is developed by key supporting details over the course of a text; summarize a text (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6R3.",
    "Description": "In literary texts, describe how events unfold, as well as how characters respond or change as the plot moves toward a resolution. (RL) In informational texts, analyze how individuals, events, and ideas are introduced, relate to each other, and are developed. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6R4.",
    "Description": "Determine the meaning of words and phrases as they are used in a text, including figurative and connotative meanings. Analyze the impact of specific word choices on meaning, tone, and mood, including words with multiple meanings. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6R5.",
    "Description": "In literary texts, analyze how a particular sentence, paragraph, stanza, chapter, scene, or section fits into the overall structure of a text and how it contributes to the development of theme/central idea, setting, or plot. (RL) In informational texts, analyze how a particular sentence, paragraph, chapter, or section fits into the overall structure of a text and how it contributes to the development of theme/ central ideas. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6R6.",
    "Description": "Identify the point of view and explain how it is developed and conveys meaning. (RI&RL) Explain how an author’s geographic location or culture affects his or her perspective. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6R7.",
    "Description": "Compare and contrast how different formats, including print and digital media, contribute to the understanding of a subject. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6R8.",
    "Description": "Trace and evaluate the development of an argument and specific claims in texts, distinguishing claims that are supported by reasons and relevant evidence from claims that are not. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6R9.",
    "Description": "Use established criteria in order to evaluate the quality of texts. Make connections to other texts, ideas, cultural perspectives, eras, and personal experiences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6SL1.",
    "Description": "Engage effectively in a range of collaborative discussions with diverse partners; express ideas clearly and persuasively, and build on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6SL2.",
    "Description": "Interpret information presented in diverse formats (e.g., including visual, quantitative, and oral) and explain how it relates to a topic, text, or issue under study."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6SL3.",
    "Description": "Delineate a speaker’s argument and specific claims, distinguishing claims that are supported by reasons and evidence from claims that are not."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6SL4.",
    "Description": "Present claims and findings, sequencing ideas logically and using relevant descriptions, facts, and details to accentuate main ideas or themes; use appropriate eye contact, adequate volume, and clear enunciation."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6SL5.",
    "Description": "Include digital media and/or visual displays in presentations to clarify information and emphasize and enhance main ideas or themes."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6SL6.",
    "Description": "Adapt speech to a variety of contexts and tasks, demonstrating command of formal English when indicated or appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W1.",
    "Description": "Write arguments to support claims with clear reasons and relevant evidence."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W2.",
    "Description": "Write informative/explanatory texts to examine a topic and convey ideas, concepts, and information through the selection, organization, and analysis of relevant content."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective techniques, descriptive details and sequencing"
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme, or personal experience."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W5.",
    "Description": "Draw evidence from literary or informational texts to support analysis, reflection, and research. Apply grade 6 Reading standards to both literary and informational text, where applicable."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W6.",
    "Description": "Conduct research to answer questions, including self-generated questions, drawing on multiple sources and refocusing the inquiry when appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 6,
    "Standard": "6W7.",
    "Description": "Gather relevant information from multiple sources; assess the credibility of each source; quote or paraphrase the data and conclusions of others; avoid plagiarism and provide basic bibliographic information for sources."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.1.",
    "Description": "Ensure that pronouns are in the proper case (subjective, objective, and possessive)."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.2.",
    "Description": "Recognize and correct inappropriate shifts in pronoun number and person."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.3.",
    "Description": "Recognize and correct pronouns that have unclear or ambiguous antecedents."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.4.",
    "Description": "Explain the function of phrases and clauses in general, as well as in specific sentences."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.5.",
    "Description": "Place phrases and clauses within a sentence, recognizing and correcting misplaced and dangling modifiers."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.6.",
    "Description": "Use simple, compound, complex, and compound-complex sentences to signal differing relationships among ideas."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.7.",
    "Description": "Explain the function of verbals (gerunds, participles, infinitives)."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.8.",
    "Description": "Form and use verbs in the active and passive voice."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.9.",
    "Description": "Recognize and correct inappropriate verb shifts."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L2.1.",
    "Description": "Use punctuation (commas, parentheses, dashes, hyphens) to clarify and enhance writing."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L2.2.",
    "Description": "Use punctuation (comma, ellipsis, dash) to indicate a pause or break."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L2.3.",
    "Description": "Use an ellipsis to indicate an omission."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L3a.",
    "Description": "Choose language that expresses ideas precisely and concisely, recognizing and eliminating wordiness and redundancy."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L4a.",
    "Description": "Use context (e.g., the overall meaning of a sentence or paragraph; a word’s position or function in a sentence) as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L4b.",
    "Description": "Use common, grade-appropriate Greek or Latin affixes and roots as clues to the meaning of a word (e.g., belligerent, bellicose, rebel)."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L4c.",
    "Description": "Consult general and specialized reference materials (e.g., dictionaries, glossaries, thesauruses), both print and digital, to find the pronunciation of a word or determine or clarify its precise meaning or its part of speech."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L4d.",
    "Description": "Verify the preliminary determination of the meaning of a word or phrase (e.g., by checking the inferred meaning in context or in a dictionary)."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L5a.",
    "Description": "Interpret figurative language, including allusions, in context."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L5b.",
    "Description": "Use the relationship between particular words (e.g., synonym/antonym, analogy) to better understand each of the words."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L5c.",
    "Description": "Distinguish among the connotations of words with similar denotations (e.g., refined, respectful, polite, diplomatic, condescending)."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7LS1a.",
    "Description": "Come to discussions prepared, having read or researched material under study; draw on that preparation by referring to evidence on the topic, text, or issue to probe and reflect on ideas under discussion."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7SL1b.",
    "Description": "Follow norms for collegial discussions, track progress toward specific goals and deadlines, and define individual roles as needed."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7SL1c.",
    "Description": "Pose questions that elicit elaboration and respond to others’ questions and comments with relevant observations and ideas that bring the discussion back on topic as needed."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7SL1d.",
    "Description": "Acknowledge new information expressed by others and, when warranted, modify personal views."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W1a.",
    "Description": "Introduce a precise claim, acknowledge and distinguish the claim from a counterclaim, and organize the reasons and evidence logically."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W1b.",
    "Description": "Support claim(s) with logical reasoning and relevant evidence, using credible sources while demonstrating an understanding of the topic or text."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W1c.",
    "Description": "Use precise language and content-specific vocabulary to argue a claim."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W1d.",
    "Description": "Use appropriate transitions to create cohesion and clarify the relationships among ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W1e.",
    "Description": "Provide a concluding statement or section that explains the significance of the argument presented."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W1f.",
    "Description": "Maintain a style and tone appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W2a.",
    "Description": "Introduce a topic clearly, previewing what is to follow; organize ideas, concepts, and information, using strategies such as definition, classification, comparison/contrast, and cause/effect."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W2b.",
    "Description": "Develop a topic with relevant facts, definitions, concrete details, quotations, or other information and examples; include formatting, graphics, and multimedia when useful to aid comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W2c.",
    "Description": "Use precise language and domain-specific vocabulary to explain a topic."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W2d.",
    "Description": "Use appropriate transitions to create cohesion and clarify the relationships among ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W2e.",
    "Description": "Provide a concluding statement or section that explains the significance of the information presented."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W2f.",
    "Description": "Establish and maintain a style appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W3a.",
    "Description": "Engage the reader by establishing a point of view and introducing a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W3b.",
    "Description": "Use narrative techniques, such as dialogue and description, to develop experiences, events, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W3c.",
    "Description": "Use a variety of transitional words, phrases, and clauses to convey sequence and signal shifts from one time frame or setting to another."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W3d.",
    "Description": "Use precise words and phrases, relevant descriptive details, and sensory language to capture the action and convey experiences and events."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W3e.",
    "Description": "Provide a conclusion that follows from and reflects on what is experienced, observed, or resolved over the course of the narrative."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 6→8:"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 6→8:"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L3.",
    "Description": "Use knowledge of language and its conventions when writing, speaking, reading, or listening."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L5.",
    "Description": "Demonstrate understanding of figurative language, word relationships, and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7L6.",
    "Description": "Acquire and accurately use general academic and content-specific words and phrases; apply vocabulary knowledge when considering a word or phrase important to comprehension or expression."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7R1.",
    "Description": "Cite textual evidence to support an analysis of what the text says explicitly/implicitly and make logical inferences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7R2.",
    "Description": "Determine a theme or central idea of a text and analyze its development over the course of the text; summarize a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7R3.",
    "Description": "In literary texts, analyze how elements of plot are related, affect one another, and contribute to meaning. (RL) In informational texts, analyze how individuals, events, and ideas are introduced, relate to each other, and are developed. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7R4.",
    "Description": "Determine the meaning of words and phrases as they are used in a text, including figurative and connotative meanings. Analyze the impact of specific word choices on meaning, tone, and mood, including words with multiple meanings. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7R5.",
    "Description": "In literary texts, analyze how structure, including genre-specific features, contributes to the development of themes and central ideas. (RL) In informational texts, analyze the structure an author uses to organize a text, including how the sections contribute to the whole and to the development of themes and central ideas. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7R6.",
    "Description": "In literary texts, analyze how an author develops and contrasts the point of view and the perspectives of different characters or narrators. (RL) In informational texts, analyze how the author distinguishes his or her position from that of others. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7R7.",
    "Description": "Compare and contrast a written text with audio, filmed, staged, or digital versions in order to analyze the effects of techniques unique to each media and each format’s portrayal of a subject. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7R8.",
    "Description": "Trace and evaluate the development of an argument and specific claims in a text, assessing whether the reasoning is valid and the evidence is relevant and sufficient and recognizing when irrelevant evidence is introduced. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7R9.",
    "Description": "Use established criteria in order to evaluate the quality of texts. Make connections to other texts, ideas, cultural perspectives, eras, and personal experiences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7SL1.",
    "Description": "Engage effectively in a range of collaborative discussions with diverse partners; express ideas clearly and persuasively, and build on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7SL2.",
    "Description": "Analyze the main ideas and supporting details presented in diverse formats (e.g., including visual, quantitative, and oral) and explain how the ideas clarify and/or contribute to a topic, text, or issue under study."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7SL3.",
    "Description": "Delineate a speaker’s argument and specific claims, evaluating for sound reasoning, and the relevance and sufficiency of the evidence."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7SL4.",
    "Description": "Present claims and findings, emphasizing salient points in a focused, coherent manner with relevant descriptions, facts, details, and examples; use appropriate eye contact, adequate volume, and clear enunciation."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7SL5.",
    "Description": "Include digital media and/or visual displays in presentations to clarify claims and findings and emphasize salient points."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7SL6.",
    "Description": "Adapt speech to a variety of contexts and tasks, demonstrating command of formal English when indicated or appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W1.",
    "Description": "Write arguments to support claims with clear reasons and relevant evidence."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W2.",
    "Description": "Write informative/explanatory texts to examine a topic and convey ideas, concepts, and information through the selection, organization, and analysis of relevant content."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective techniques, descriptive details and clear sequencing."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme, or personal experience."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W5.",
    "Description": "Draw evidence from literary or informational texts to support analysis, reflection, and research. Apply grade 7 Reading standards to both literary and informational text, where applicable."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W6.",
    "Description": "Conduct research to answer questions, including self-generated questions, drawing on multiple sources and refocusing the inquiry when appropriate. Generate additional related questions for further research and investigation."
  },
  {
    "Course": "ELA",
    "Grade": 7,
    "Standard": "7W7.",
    "Description": "Gather relevant information from multiple sources; assess the credibility and accuracy of each source; quote or paraphrase the data and conclusions of others; avoid plagiarism and follow a standard format for citation."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.1.",
    "Description": "Ensure that pronouns are in the proper case (subjective, objective, and possessive)."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.2.",
    "Description": "Recognize and correct inappropriate shifts in pronoun number and person."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.3.",
    "Description": "Recognize and correct pronouns that have unclear or ambiguous antecedents."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.4.",
    "Description": "Explain the function of phrases and clauses in general, as well as in specific sentences."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.5.",
    "Description": "Place phrases and clauses within a sentence, recognizing and correcting misplaced and dangling modifiers."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.6.",
    "Description": "Use simple, compound, complex, and compound-complex sentences to signal differing relationships among ideas."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.7.",
    "Description": "Explain the function of verbals (gerunds, participles, infinitives)."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.8.",
    "Description": "Form and use verbs in the active and passive voice."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.9.",
    "Description": "Recognize and correct inappropriate verb shifts."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L2.1.",
    "Description": "Use punctuation (commas, parentheses, dashes, hyphens) to clarify and enhance writing."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L2.2.",
    "Description": "Use punctuation (comma, ellipsis, dash) to indicate a pause or break."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L2.3.",
    "Description": "Use an ellipsis to indicate an omission."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L3a.",
    "Description": "Use verbs in the active and passive voice and in the conditional and subjunctive mood to achieve particular effects (e.g., emphasizing the actor or the action; expressing uncertainty or describing a state contrary to fact)."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L4a.",
    "Description": "Use context (e.g., the overall meaning of a sentence or paragraph; a word’s position or function in a sentence) as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L4b.",
    "Description": "Use common, grade-appropriate Greek or Latin affixes and roots as clues to the meaning of a word (e.g., precede, recede, secede)."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L4c.",
    "Description": "Consult general and specialized reference materials (e.g., dictionaries, glossaries, thesauruses) to find the pronunciation of a word or determine or clarify its precise meaning or its part of speech."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L4d.",
    "Description": "Verify the preliminary determination of the meaning of a word or phrase (e.g., by checking the inferred meaning in context or in a dictionary)."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L5a.",
    "Description": "Interpret figures of speech including irony and puns in context."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L5b.",
    "Description": "Use the relationship between particular words to better understand each of the words."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L5c.",
    "Description": "Distinguish among the connotations of words with similar denotations (e.g., bullheaded, willful, firm, persistent, resolute)."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8LS1a.",
    "Description": "Come to discussions prepared, having read or researched material under study; draw on that preparation by referring to evidence on the topic, text, or issue to probe and reflect on ideas under discussion."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8SL1b.",
    "Description": "Follow norms for collegial discussions and decision-making, track progress toward specific goals and deadlines, and define individual roles as needed."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8SL1c.",
    "Description": "Pose questions that connect the ideas of several speakers and respond to others’ questions and comments with relevant evidence, observations, and ideas."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8SL1d.",
    "Description": "Acknowledge new information expressed by others, and, when warranted, qualify or justify personal views in light of the evidence presented."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W1a.",
    "Description": "Introduce a precise claim, acknowledge and distinguish the claim(s) from a counterclaim, and organize the reasons and evidence logically."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W1b.",
    "Description": "Support claim(s) with logical reasoning and relevant evidence, using credible sources while demonstrating an understanding of the topic or text."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W1c.",
    "Description": "Use precise language and content-specific vocabulary to argue a claim."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W1d.",
    "Description": "Use appropriate and varied transitions to create cohesion and clarify the relationships among ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W1e.",
    "Description": "Provide a concluding statement or section that explains the significance of the argument presented."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W1f.",
    "Description": "Maintain a style and tone appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W2a.",
    "Description": "Introduce a topic clearly, previewing what is to follow; organize ideas, concepts, and information, using strategies such as definition, classification, comparison/contrast, and cause/effect."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W2b.",
    "Description": "Develop a topic with relevant facts, definitions, concrete details, quotations, or other information and examples; include formatting, graphics, and multimedia when useful to aid comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W2c.",
    "Description": "Use precise language and domain-specific vocabulary to explain a topic."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W2d.",
    "Description": "Use appropriate and varied transitions to create cohesion and clarify the relationships among ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W2e.",
    "Description": "Provide a concluding statement or section that explains the significance of the information presented."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W2f.",
    "Description": "Establish and maintain a style appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W3a.",
    "Description": "Engage the reader by establishing a point of view and introducing a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W3b.",
    "Description": "Use narrative techniques, such as dialogue, pacing, description, and reflection to develop experiences, events, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W3c.",
    "Description": "Use a variety of transitional words, phrases, and clauses to convey sequence, signal shifts from one time frame or setting to another, and show the relationships among experiences and events."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W3d.",
    "Description": "Use precise words and phrases, relevant descriptive details, and sensory language to capture the action and convey experiences and events."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W3e.",
    "Description": "Provide a conclusion that follows from and reflects on what is experienced, observed, or resolved over the course of the narrative."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 6→8:"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 6→8:"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L3.",
    "Description": "Use knowledge of language and its conventions when writing, speaking, reading, or listening."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L5.",
    "Description": "Demonstrate understanding of figurative language, word relationships, and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8L6.",
    "Description": "Acquire and accurately use general academic and content-specific words and phrases; apply vocabulary knowledge when considering a word or phrase important to comprehension or expression."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8R1.",
    "Description": "Cite textual evidence to strongly support an analysis of what the text says explicitly/implicitly and make logical inferences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8R2.",
    "Description": "Determine one or more themes or central ideas of a text and analyze their development over the course of the text; summarize a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8R3.",
    "Description": "In literary texts, analyze how particular lines of dialogue or events propel the action, reveal aspects of a character, or provoke a decision. (RL) In informational texts, analyze how individuals, events, and ideas are introduced, relate to each other, and are developed. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8R4.",
    "Description": "Determine the meaning of words and phrases as they are used in a text, including figurative and connotative meanings. Analyze the impact of specific word choices on meaning, tone, and mood, including words with multiple meanings. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8R5.",
    "Description": "In literary texts, and informational texts, compare and contrast the structures of two or more texts in order to analyze how the differing structure of each text contributes to overall meaning, style, theme or central idea. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8R6.",
    "Description": "In literary texts, analyze how the differences between the point of view, perspectives of the characters, the audience, or reader create effects such as mood and tone. (RL) In informational texts, analyze how the author addresses conflicting evidence or viewpoints. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8R7.",
    "Description": "Evaluate the advantages and disadvantages of using different media-- text, audio, video, stage, or digital-- to present a particular subject or idea and analyze the extent to which a production remains faithful to or departs from the written text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8R8.",
    "Description": "Trace and evaluate an argument and specific claims in a text, assessing whether the reasoning is valid and the evidence is relevant and sufficient and recognizing when irrelevant evidence is introduced. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8R9.",
    "Description": "Choose and develop criteria in order to evaluate the quality of texts. Make connections to other texts, ideas, cultural perspectives, eras, and personal experiences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8SL1.",
    "Description": "Engage effectively in a range of collaborative discussions with diverse partners; express ideas clearly and persuasively, and build on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8SL2.",
    "Description": "Analyze the purpose of information presented in diverse formats (e.g., including visual, quantitative, and oral) and evaluate the motives (e.g., social, commercial, political) behind its presentation."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8SL3.",
    "Description": "Delineate a speaker’s argument and specific claims, evaluating for sound reasoning, and the relevance and sufficiency of the evidence; identify when irrelevant evidence is introduced."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8SL4.",
    "Description": "Present claims and findings, emphasizing salient points in a focused, coherent manner with relevant evidence, valid reasoning, and well-chosen details; use appropriate eye contact, adequate volume, and clear enunciation."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8SL5.",
    "Description": "Integrate digital media and/or visual displays in presentations to clarify information, strengthen claims and evidence, and add elements of interest to engage the audience."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8SL6.",
    "Description": "Adapt speech to a variety of contexts and tasks, demonstrating command of formal English when indicated or appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W1.",
    "Description": "Write arguments to support claims with clear reasons and relevant evidence."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W2.",
    "Description": "Write informative/explanatory texts to examine a topic and convey ideas, concepts, and information through the selection, organization, and analysis of relevant content."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective techniques, relevant descriptive details and clear sequencing."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme or personal experience; explain divergences from the original text when appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W5.",
    "Description": "Draw evidence from literary or informational texts to support analysis, reflection, and research. Apply grade 8 Reading standards to both literary and informational text, where applicable"
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W6.",
    "Description": "Conduct research to answer questions, including self-generated questions, drawing on multiple sources, refocusing the inquiry when appropriate. Generate additional related questions that allow for multiple avenues of exploration."
  },
  {
    "Course": "ELA",
    "Grade": 8,
    "Standard": "8W7.",
    "Description": "Gather relevant information from multiple sources; assess the credibility and accuracy of each source; quote or paraphrase the data and conclusions of others; avoid plagiarism and follow a standard format for citation."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L1.1.",
    "Description": "Use parallel structure."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L1.2.",
    "Description": "Use various types of phrases and clauses to add variety and interest to writing or presentations."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L1.3.",
    "Description": "Understand that usage is a matter of convention that can change over time."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L1.4.",
    "Description": "Resolve issues of complex or contested usage, consulting references as needed."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L2.1.",
    "Description": "Use punctuation (commas, parentheses, dashes, hyphens) to clarify and enhance writing."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L2.2.",
    "Description": "Use a semicolon to link two or more closely related independent clauses."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L2.3.",
    "Description": "Use a colon to introduce a list or quotation."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L3a.",
    "Description": "Write and edit work so that it conforms to the guidelines in a professionally recognized style manual appropriate for the discipline and writing type."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L4a.",
    "Description": "Use context (e.g., the overall meaning of a sentence, paragraph, or text; a word’s position or function in a sentence) as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L4b.",
    "Description": "Identify and correctly use patterns of word changes that indicate different meanings or parts of speech (e.g., analyze, analysis, analytical; advocate, advocacy)."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L4c.",
    "Description": "Consult general and specialized reference materials (e.g., dictionaries, glossaries, thesauruses) to find the pronunciation of a word or determine or clarify its precise meaning, its part of speech, or its etymology."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L4d.",
    "Description": "Verify the preliminary determination of the meaning of a word or phrase (e.g., by checking the inferred meaning in context or in a dictionary)."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L5a.",
    "Description": "Interpret figures of speech, including euphemism and oxymoron, in context and analyze their role in the text."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L5b.",
    "Description": "Analyze nuances in the meaning of words with similar denotations."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10LS1a.",
    "Description": "Come to discussions prepared, having read and researched material under study; draw on that preparation by referring to evidence to stimulate a thoughtful, well-reasoned exchange of ideas."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10SL1b.",
    "Description": "Work with peers to set norms for collegial discussions and decision-making, establish clear goals, deadlines, and individual roles as needed."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10SL1c.",
    "Description": "Pose and respond to questions that relate the discussion to broader themes or ideas; actively incorporate others into the discussion; and clarify, verify, or challenge ideas and conclusions."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10SL1d.",
    "Description": "Respond thoughtfully to diverse perspectives, summarize points of agreement and disagreement, and, when warranted, qualify or justify personal views and understanding and make new connections in light of the evidence and reasoning presented."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W1a.",
    "Description": "Introduce precise claim(s), distinguish the claim(s) from counterclaims, establish and organize clear relationships among claim(s), counterclaim(s), reasons, and evidence."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W1b.",
    "Description": "Develop claim(s) and counterclaims in a balanced manner, supplying evidence for each while pointing out the strengths and limitations of both, anticipating the audience’s knowledge level and concerns."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W1c.",
    "Description": "Use precise language and content-specific vocabulary to express the appropriate complexity of the topic."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W1d.",
    "Description": "Use appropriate and varied transitions to make critical connections and distinctions, create cohesion, and clarify the relationships among complex ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W1e.",
    "Description": "Provide a concluding statement or section that explains the significance of the argument presented."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W1f.",
    "Description": "Maintain a style and tone appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W2a.",
    "Description": "Introduce and organize complex ideas, concepts, and information to make important connections and distinctions."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W2b.",
    "Description": "Develop a topic with well-chosen relevant and sufficient facts, definitions, concrete details, quotations and paraphrased information or other examples appropriate to the audience's knowledge of the topic. Include formatting, graphics, and multimedia when useful to aid comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W2c.",
    "Description": "Use precise language and domain-specific vocabulary to express the appropriate complexity of a topic."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W2d.",
    "Description": "Use appropriate and varied transitions to make critical connections and distinctions, create cohesion, and clarify relationships among complex ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W2e.",
    "Description": "Provide a concluding statement or section that explains the significance of the information presented."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W2f.",
    "Description": "Establish and maintain a style appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W3a.",
    "Description": "Engage the reader by presenting a problem, conflict, situation, or observation, establishing one or multiple point(s) of view, and introducing a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W3b.",
    "Description": "Use narrative techniques, such as dialogue, pacing, description, reflection, and plot line(s) to develop experiences, events, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W3c.",
    "Description": "Use a variety of techniques to sequence events to create cohesion and a smooth progression of experiences or events."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W3d.",
    "Description": "Use precise words and phrases, explicit details, and sensory language to convey a vivid picture of the experiences, events, setting, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W3e.",
    "Description": "Provide a conclusion that follows from and reflects on what is experienced, observed, or resolved over the course of the narrative."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 9→12:"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 9→12:"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L3.",
    "Description": "Apply knowledge of language to understand how language functions in different contexts, to make effective choices for meaning or style, and to comprehend more fully when reading or listening."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L5.",
    "Description": "Demonstrate understanding of figurative language, word relationships, and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10L6.",
    "Description": "Acquire and accurately use general academic and content-specific words and phrases, sufficient for reading, writing, speaking, and listening; demonstrate independence in applying vocabulary knowledge when considering a word or phrase important to comprehension or expression."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10R1.",
    "Description": "Cite strong and thorough textual evidence to support analysis of what the text says explicitly/implicitly and make logical inferences; develop questions for deeper understanding and for further exploration. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10R2.",
    "Description": "Determine one or more themes or central ideas in a text and analyze its development, including how it emerges and is shaped and refined by specific details; objectively and accurately summarize a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10R3.",
    "Description": "Analyze how and why individuals, events, and ideas develop and interact over the course of a text. In literary texts, analyze how complex and/or dynamic characters develop, interact with other characters, advance the plot, or develop a theme. (RL) In informational texts, analyze how the author unfolds an analysis or argument, including the sequence, the introduction and development of ideas, and the connections that exist. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10R4.",
    "Description": "Determine the meaning of words and phrases as they are used in a text, including figurative and connotative meanings. Analyze the impact of specific word choices on meaning, tone, and mood. Examine technical or key terms and how language differs across genres. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10R5.",
    "Description": "In literary texts, consider how varied aspects of structure create meaning and affect the reader. (RL) In informational texts, consider how author's intent influences particular sentences, paragraphs, or sections. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10R6.",
    "Description": "Analyze how authors employ point of view, perspective, and purpose to shape explicit and implicit messages (e.g., examine rhetorical strategies, literary elements and devices). (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10R7.",
    "Description": "Analyze how a subject / content is presented in two or more formats by determining which details are emphasized, altered, or absent in each account. (e.g., analyze the representation of a subject / content or key scene in two different formats, examine the differences between a historical novel and a documentary). (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10R8.",
    "Description": "Delineate and evaluate an argument and specific claims in a text, assessing the validity or fallacy of key statements by examining whether the supporting evidence is relevant and sufficient. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10R9.",
    "Description": "Choose and develop criteria in order to evaluate the quality of texts. Make connections to other texts, ideas, cultural perspectives, eras, and personal experiences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10SL1.",
    "Description": "Initiate and participate effectively in a range of collaborative discussions with diverse partners on complex topics, texts, and issues; express ideas clearly and persuasively, and build on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10SL2.",
    "Description": "Integrate multiple sources of information presented in diverse formats (e.g., including visual, quantitative, and oral), evaluating the credibility, accuracy, and relevance of each source."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10SL3.",
    "Description": "Evaluate a speaker’s point of view, reasoning, and use of evidence and rhetoric; identify any fallacious reasoning or exaggerated or distorted evidence."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10SL4.",
    "Description": "Present claims, findings, and supporting evidence clearly, concisely, and logically; organization, development, substance, and style are appropriate to task, purpose, and audience."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10SL5.",
    "Description": "Make strategic use of digital media and/or visual displays in presentations to enhance understanding of findings, reasoning, and evidence, and to add elements of interest to engage the audience."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10SL6.",
    "Description": "Adapt speech to a variety of contexts and tasks, demonstrating command of formal English when indicated or appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W1.",
    "Description": "Write arguments to support claims that analyze substantive topics or texts, using valid reasoning and relevant and sufficient evidence."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W2.",
    "Description": "Write informative/explanatory texts to examine and convey complex ideas, concepts, and information clearly and accurately through the effective selection, organization, and analysis of content."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective technique, well-chosen details, and well-structured event sequences."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme or personal experience; demonstrate knowledge and understanding of a variety of techniques and genres. Explain divergences from the original when appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W5.",
    "Description": "Draw evidence from literary or informational texts to support analysis, reflection, and research. Apply grade 45179 Reading standards to both literary and informational text, where applicable."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W6.",
    "Description": "Conduct research to answer questions, including self-generated questions, or solve a problem; narrow or broaden the inquiry when appropriate. Synthesize multiple sources, demonstrating understanding of the subject under investigation."
  },
  {
    "Course": "ELA",
    "Grade": 9,
    "Standard": "9-10W7.",
    "Description": "Gather relevant information from multiple sources, using advanced searches effectively; assess the usefulness of each source in answering the research question; integrate information into the text selectively to maintain the flow of ideas; avoid plagiarism and follow a standard format for citation."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L1.1.",
    "Description": "Use parallel structure."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L1.2.",
    "Description": "Use various types of phrases and clauses to add variety and interest to writing or presentations."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L1.3.",
    "Description": "Understand that usage is a matter of convention that can change over time."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L1.4.",
    "Description": "Resolve issues of complex or contested usage, consulting references as needed."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L2.1.",
    "Description": "Use punctuation (commas, parentheses, dashes, hyphens) to clarify and enhance writing."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L2.2.",
    "Description": "Use a semicolon to link two or more closely related independent clauses."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L2.3.",
    "Description": "Use a colon to introduce a list or quotation."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L3a.",
    "Description": "Write and edit work so that it conforms to the guidelines in a professionally recognized style manual appropriate for the discipline and writing type."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L4a.",
    "Description": "Use context (e.g., the overall meaning of a sentence, paragraph, or text; a word’s position or function in a sentence) as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L4b.",
    "Description": "Identify and correctly use patterns of word changes that indicate different meanings or parts of speech (e.g., analyze, analysis, analytical; advocate, advocacy)."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L4c.",
    "Description": "Consult general and specialized reference materials (e.g., dictionaries, glossaries, thesauruses) to find the pronunciation of a word or determine or clarify its precise meaning, its part of speech, or its etymology."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L4d.",
    "Description": "Verify the preliminary determination of the meaning of a word or phrase (e.g., by checking the inferred meaning in context or in a dictionary)."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L5a.",
    "Description": "Interpret figures of speech, including euphemism and oxymoron, in context and analyze their role in the text."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L5b.",
    "Description": "Analyze nuances in the meaning of words with similar denotations."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10LS1a.",
    "Description": "Come to discussions prepared, having read and researched material under study; draw on that preparation by referring to evidence to stimulate a thoughtful, well-reasoned exchange of ideas."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10SL1b.",
    "Description": "Work with peers to set norms for collegial discussions and decision-making, establish clear goals, deadlines, and individual roles as needed."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10SL1c.",
    "Description": "Pose and respond to questions that relate the discussion to broader themes or ideas; actively incorporate others into the discussion; and clarify, verify, or challenge ideas and conclusions."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10SL1d.",
    "Description": "Respond thoughtfully to diverse perspectives, summarize points of agreement and disagreement, and, when warranted, qualify or justify personal views and understanding and make new connections in light of the evidence and reasoning presented."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W1a.",
    "Description": "Introduce precise claim(s), distinguish the claim(s) from counterclaims, establish and organize clear relationships among claim(s), counterclaim(s), reasons, and evidence."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W1b.",
    "Description": "Develop claim(s) and counterclaims in a balanced manner, supplying evidence for each while pointing out the strengths and limitations of both, anticipating the audience’s knowledge level and concerns."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W1c.",
    "Description": "Use precise language and content-specific vocabulary to express the appropriate complexity of the topic."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W1d.",
    "Description": "Use appropriate and varied transitions to make critical connections and distinctions, create cohesion, and clarify the relationships among complex ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W1e.",
    "Description": "Provide a concluding statement or section that explains the significance of the argument presented."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W1f.",
    "Description": "Maintain a style and tone appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W2a.",
    "Description": "Introduce and organize complex ideas, concepts, and information to make important connections and distinctions."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W2b.",
    "Description": "Develop a topic with well-chosen relevant and sufficient facts, definitions, concrete details, quotations and paraphrased information or other examples appropriate to the audience's knowledge of the topic. Include formatting, graphics, and multimedia when useful to aid comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W2c.",
    "Description": "Use precise language and domain-specific vocabulary to express the appropriate complexity of a topic."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W2d.",
    "Description": "Use appropriate and varied transitions to make critical connections and distinctions, create cohesion, and clarify relationships among complex ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W2e.",
    "Description": "Provide a concluding statement or section that explains the significance of the information presented."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W2f.",
    "Description": "Establish and maintain a style appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W3a.",
    "Description": "Engage the reader by presenting a problem, conflict, situation, or observation, establishing one or multiple point(s) of view, and introducing a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W3b.",
    "Description": "Use narrative techniques, such as dialogue, pacing, description, reflection, and plot line(s) to develop experiences, events, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W3c.",
    "Description": "Use a variety of techniques to sequence events to create cohesion and a smooth progression of experiences or events."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W3d.",
    "Description": "Use precise words and phrases, explicit details, and sensory language to convey a vivid picture of the experiences, events, setting, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W3e.",
    "Description": "Provide a conclusion that follows from and reflects on what is experienced, observed, or resolved over the course of the narrative."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 9→12:"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 9→12:"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L3.",
    "Description": "Apply knowledge of language to understand how language functions in different contexts, to make effective choices for meaning or style, and to comprehend more fully when reading or listening."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L5.",
    "Description": "Demonstrate understanding of figurative language, word relationships, and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10L6.",
    "Description": "Acquire and accurately use general academic and content-specific words and phrases, sufficient for reading, writing, speaking, and listening; demonstrate independence in applying vocabulary knowledge when considering a word or phrase important to comprehension or expression."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10R1.",
    "Description": "Cite strong and thorough textual evidence to support analysis of what the text says explicitly/implicitly and make logical inferences; develop questions for deeper understanding and for further exploration. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10R2.",
    "Description": "Determine one or more themes or central ideas in a text and analyze its development, including how it emerges and is shaped and refined by specific details; objectively and accurately summarize a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10R3.",
    "Description": "Analyze how and why individuals, events, and ideas develop and interact over the course of a text. In literary texts, analyze how complex and/or dynamic characters develop, interact with other characters, advance the plot, or develop a theme. (RL) In informational texts, analyze how the author unfolds an analysis or argument, including the sequence, the introduction and development of ideas, and the connections that exist. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10R4.",
    "Description": "Determine the meaning of words and phrases as they are used in a text, including figurative and connotative meanings. Analyze the impact of specific word choices on meaning, tone, and mood. Examine technical or key terms and how language differs across genres. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10R5.",
    "Description": "In literary texts, consider how varied aspects of structure create meaning and affect the reader. (RL) In informational texts, consider how author's intent influences particular sentences, paragraphs, or sections. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10R6.",
    "Description": "Analyze how authors employ point of view, perspective, and purpose to shape explicit and implicit messages (e.g., examine rhetorical strategies, literary elements and devices). (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10R7.",
    "Description": "Analyze how a subject / content is presented in two or more formats by determining which details are emphasized, altered, or absent in each account. (e.g., analyze the representation of a subject / content or key scene in two different formats, examine the differences between a historical novel and a documentary). (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10R8.",
    "Description": "Delineate and evaluate an argument and specific claims in a text, assessing the validity or fallacy of key statements by examining whether the supporting evidence is relevant and sufficient. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10R9.",
    "Description": "Choose and develop criteria in order to evaluate the quality of texts. Make connections to other texts, ideas, cultural perspectives, eras, and personal experiences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10SL1.",
    "Description": "Initiate and participate effectively in a range of collaborative discussions with diverse partners on complex topics, texts, and issues; express ideas clearly and persuasively, and build on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10SL2.",
    "Description": "Integrate multiple sources of information presented in diverse formats (e.g., including visual, quantitative, and oral), evaluating the credibility, accuracy, and relevance of each source."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10SL3.",
    "Description": "Evaluate a speaker’s point of view, reasoning, and use of evidence and rhetoric; identify any fallacious reasoning or exaggerated or distorted evidence."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10SL4.",
    "Description": "Present claims, findings, and supporting evidence clearly, concisely, and logically; organization, development, substance, and style are appropriate to task, purpose, and audience."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10SL5.",
    "Description": "Make strategic use of digital media and/or visual displays in presentations to enhance understanding of findings, reasoning, and evidence, and to add elements of interest to engage the audience."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10SL6.",
    "Description": "Adapt speech to a variety of contexts and tasks, demonstrating command of formal English when indicated or appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W1.",
    "Description": "Write arguments to support claims that analyze substantive topics or texts, using valid reasoning and relevant and sufficient evidence."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W2.",
    "Description": "Write informative/explanatory texts to examine and convey complex ideas, concepts, and information clearly and accurately through the effective selection, organization, and analysis of content."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective technique, well-chosen details, and well-structured event sequences."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme or personal experience; demonstrate knowledge and understanding of a variety of techniques and genres. Explain divergences from the original when appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W5.",
    "Description": "Draw evidence from literary or informational texts to support analysis, reflection, and research. Apply grade 45179 Reading standards to both literary and informational text, where applicable."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W6.",
    "Description": "Conduct research to answer questions, including self-generated questions, or solve a problem; narrow or broaden the inquiry when appropriate. Synthesize multiple sources, demonstrating understanding of the subject under investigation."
  },
  {
    "Course": "ELA",
    "Grade": 10,
    "Standard": "9-10W7.",
    "Description": "Gather relevant information from multiple sources, using advanced searches effectively; assess the usefulness of each source in answering the research question; integrate information into the text selectively to maintain the flow of ideas; avoid plagiarism and follow a standard format for citation."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L1.1.",
    "Description": "Use parallel structure."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L1.2.",
    "Description": "Use various types of phrases and clauses to add variety and interest to writing or presentations."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L1.3.",
    "Description": "Understand that usage is a matter of convention that can change over time."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L1.4.",
    "Description": "Resolve issues of complex or contested usage, consulting references as needed."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L2.1.",
    "Description": "Use punctuation (commas, parentheses, dashes, hyphens) to clarify and enhance writing."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L2.2.",
    "Description": "Use a semicolon to link two or more closely related independent clauses."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L2.3.",
    "Description": "Use a colon to introduce a list or quotation."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L3a.",
    "Description": "Vary syntax for effect, consulting references for guidance as needed; apply an understanding of syntax to the study of complex texts when reading."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L4a.",
    "Description": "Use context (e.g., the overall meaning of a sentence, paragraph, or text; a word’s position or function in a sentence) as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L4b.",
    "Description": "Identify and correctly use patterns of word changes that indicate different meanings or parts of speech (e.g., conceive, conception, conceivable)."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L4c.",
    "Description": "Consult general and specialized reference materials (e.g., dictionaries, glossaries, thesauruses) to find the pronunciation of a word or determine or clarify its precise meaning, its part of speech, its etymology, or its standard usage."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L4d.",
    "Description": "Verify the preliminary determination of the meaning of a word or phrase (e.g., by checking the inferred meaning in context or in a dictionary)."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L5a.",
    "Description": "Interpret figures of speech, including hyperbole and paradox, in context and analyze their role in the text."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L5b.",
    "Description": "Analyze nuances in the meaning of words with similar denotations."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12LS1a.",
    "Description": "Come to discussions prepared, having read and researched material under study; draw on that preparation by referring to evidence to stimulate a thoughtful, well-reasoned exchange of ideas."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12SL1b.",
    "Description": "Work with peers to set norms for collegial discussions and decision-making, establish clear goals, deadlines, and individual roles as needed."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12SL1c.",
    "Description": "Pose and respond to questions that probe reasoning and evidence; address a full range of positions; clarify, verify, or challenge ideas and conclusions; and promote divergent and creative perspectives."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12SL1d.",
    "Description": "Respond thoughtfully to diverse perspectives; synthesize comments, claims, and evidence made on all sides of an issue; resolve contradictions when possible; and determine what additional information or research is required to deepen the investigation or complete the task."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W1a.",
    "Description": "Introduce precise claim(s), establish the significance of the claim(s), distinguish the claim(s) from counterclaim(s), and create an organization that logically sequences claims, counterclaims, reasons, and evidence."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W1b.",
    "Description": "Develop claim(s) and counterclaim(s) thoroughly and in a balanced manner, supplying the most relevant evidence for each while pointing out the strengths and limitations of both, anticipating the audience’s knowledge level, concerns, values, and possible biases."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W1c.",
    "Description": "Use precise language, content-specific vocabulary and literary techniques to express the appropriate complexity of the topic."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W1d.",
    "Description": "Use appropriate and varied transitions, as well as varied syntax, to make critical connections, create cohesion, and clarify the relationships among complex ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W1e.",
    "Description": "Provide a concluding statement or section that explains the significance of the argument presented."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W1f.",
    "Description": "Maintain a style and tone appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W2a.",
    "Description": "Introduce and organize complex ideas, concepts, and information so that each new element builds on that which precedes it to create a unified whole."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W2b.",
    "Description": "Develop a topic thoroughly by selecting the most significant and relevant facts, definitions, concrete details, direct quotations and paraphrased information or other examples, appropriate to the audience's knowledge of the topic. Include formatting, graphics, and multimedia when useful to aid comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W2c.",
    "Description": "Use precise language, domain-specific vocabulary and literary techniques to express the appropriate complexity of a topic."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W2d.",
    "Description": "Use appropriate and varied transitions and syntax to make insightful connections and distinctions, create cohesion, and clarify relationships among complex ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W2e.",
    "Description": "Provide a concluding statement or section that explains the significance of the information presented."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W2f.",
    "Description": "Establish and maintain a style appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W3a.",
    "Description": "Engage the reader by presenting a problem, conflict, situation, or observation and its significance, establishing one or multiple point(s) of view, and introducing a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W3b.",
    "Description": "Use narrative techniques, such as dialogue, pacing, description, reflection, and plot lines to develop experiences, events, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W3c.",
    "Description": "Use a variety of techniques to sequence events to create cohesion, a smooth progression of experiences or events, and build toward a particular tone and outcome (e.g., a sense of mystery, suspense, growth, or resolution)."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W3d.",
    "Description": "Use precise words and phrases, explicit details, and sensory language to convey a vivid picture of the experiences, events, setting, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W3e.",
    "Description": "Provide a conclusion that follows from and reflects on what is experienced, observed, or resolved over the course of the narrative."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 9→12:"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 9→12:"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L3.",
    "Description": "Apply knowledge of language to understand how language functions in different contexts, to make effective choices for meaning or style, and to comprehend more fully when reading or listening."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L5.",
    "Description": "Demonstrate understanding of figurative language, word relationships, and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12L6.",
    "Description": "Acquire and accurately use general academic and content-specific words and phrases, sufficient for reading, writing, speaking, and listening at the college and career readiness level; demonstrate independence in applying vocabulary knowledge when considering a word or phrase important to comprehension or expression."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12R1.",
    "Description": "Cite strong and thorough textual evidence to support analysis of what the text says explicitly/implicitly and make logical inferences, including determining where the text is ambiguous; develop questions for deeper understanding and for further exploration. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12R2.",
    "Description": "Determine two or more themes or central ideas in a text and analyze their development, including how they emerge and are shaped and refined by specific details; objectively and accurately summarize a complex text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12R3.",
    "Description": "In literary texts, analyze the impact of author's choices. (RL) In infomational texts, analyze a complex set of ideas or sequence of events and explain how specific individuals, ideas, or events interact and develop. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12R4.",
    "Description": "Determine the meaning of words and phrases as they are used in a text, including figurative and connotative meanings. Analyze the impact of specific word choices on meaning, tone, and mood, including words with multiple meanings. Analyze how an author uses and refines the meaning of technical or key term(s) over the course of a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12R5.",
    "Description": "In literary texts, analyze how varied aspects of structure create meaning and affect the reader. (RL) In informational texts, analyze the impact and evaluate the effect structure has on exposition or argument in terms of clarity, persuasive/rhetorical technique, and audience appeal. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12R6.",
    "Description": "Analyze how authors employ point of view, perspective, and purpose, to shape explicit and implicit messages (e.g., persuasiveness, aesthetic quality, satire, sarcasm, irony, or understatement). (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12R7.",
    "Description": "In literary texts, analyze multiple adaptations of a source text as presented in different formats (e.g., works of art, graphic novels, music, film, etc.), specifically evaluating how each version interprets the source. (RL) In informational texts, integrate and evaluate sources on the same topic or argument in order to address a question, or solve a problem. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12R8.",
    "Description": "Delineate and evaluate an argument in applicable texts, applying a lens (e.g. constitutional principles, logical fallacy, legal reasoning, belief systems, codes of ethics, philosophies, etc.) to assess the validity or fallacy of key arguments, determining whether the supporting evidence is relevant and sufficient. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12R9.",
    "Description": "Choose and develop criteria in order to evaluate the quality of texts. Make connections to other texts, ideas, cultural perspectives, eras, and personal experiences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12SL1.",
    "Description": "Initiate and participate effectively in a range of collaborative discussions with diverse partners on complex topics, texts, and issues; express ideas clearly and persuasively, and build on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12SL2.",
    "Description": "Integrate multiple sources of information presented in diverse formats (e.g., including visual, quantitative, and oral). Evaluate the credibility and accuracy of each source, and note any discrepancies among the data to make informed decisions and solve problems."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12SL3.",
    "Description": "Evaluate a speaker’s point of view, reasoning, and use of evidence and rhetoric; assess the premises and connections among ideas, diction, and tone."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12SL4.",
    "Description": "Present claims, findings, and supporting evidence, conveying a clear and distinct perspective; alternative or opposing perspectives are addressed; organization, development, substance, and style are appropriate to task, purpose, and audience."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12SL5.",
    "Description": "Make strategic use of digital media and/or visual displays in presentations to enhance understanding of findings, reasoning, and evidence, and to add elements of interest to engage the audience."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12SL6.",
    "Description": "Adapt speech to a variety of contexts and tasks, demonstrating a command of formal English when indicated or appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W1.",
    "Description": "Write arguments to support claims that analyze substantive topics or texts, using valid reasoning and relevant and sufficient evidence."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W2.",
    "Description": "Write informative/explanatory texts to examine and convey complex ideas, concepts, and information clearly and accurately through the effective selection, organization, and analysis of content."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective techniques, well-chosen details, and well-structured event sequences."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme or personal experience; demonstrate knowledge and understanding of a variety of techniques and genres. Explain connections between the original and the created work."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W5.",
    "Description": "Draw evidence from literary or informational texts to support analysis, reflection, and research. Apply grade 45242 Reading standards to both literary and informational text, where applicable."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W6.",
    "Description": "Conduct research through self-generated question, or solve a problem; narrow or broaden the inquiry when appropriate. Synthesize multiple sources, demonstrating understanding and analysis of the subject under investigation."
  },
  {
    "Course": "ELA",
    "Grade": 11,
    "Standard": "11-12W7.",
    "Description": "Gather relevant information from multiple sources, using advanced searches effectively; assess the strengths and limitations of each source in terms of the task, purpose, and audience; integrate information into the text selectively to maintain the flow of ideas; avoid plagiarism, overreliance on one source, and follow a standard format for citation."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R1.1",
    "Description": "Student comprehends the meaning of words and sentences."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R1.2",
    "Description": "Student comprehends elements of literary texts."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R1.3",
    "Description": "Student comprehends organizational patterns, textual features, graphical representations, and ideas in informational and literary texts."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R2.1",
    "Description": "Student uses prior knowledge to comprehend and elaborate the meaning of texts."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R2.2",
    "Description": "Student uses context to comprehend and elaborate the meaning of texts."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R2.3",
    "Description": "Student uses knowledge of the evolution, diversity, and effects of language to comprehend and elaborate the meaning of texts."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R3.1",
    "Description": "Student rhetorically analyzes author’s purpose, intended audience, and goals."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R3.2",
    "Description": "Student interprets, analyzes, and critiques author’s use of literary and rhetorical devices, language, and style."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R4.1",
    "Description": "Student uses strategies to prepare to read."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R4.2",
    "Description": "Student uses strategies to interpret the meaning of words, sentences, and ideas in texts."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R4.3",
    "Description": "Student uses strategies to go beyond the text."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R4.4",
    "Description": "Student uses strategies to organize, restructure, and synthesize text content."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "R4.5",
    "Description": "Student monitors comprehension and reading strategies throughout the reading process."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W1.1",
    "Description": "Student analyzes components of purpose, goals, audience, and genre"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W2.1",
    "Description": "Student takes inventory of what he or she knows and needs to know."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W2.2",
    "Description": "Student generates, selects, connects, and organizes information and ideas."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W3.1",
    "Description": "Student generates text to develop points within the preliminary organizational structure."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W3.2",
    "Description": "Student makes stylistic choices with language to achieve intended effects."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W4.1",
    "Description": "Student evaluates drafted text for development, organization, and focus."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W4.2",
    "Description": "Student evaluates drafted text to determine the effectiveness of stylistic choices."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W5.1",
    "Description": "Student edits for conventions of standard written English and usage."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W5.2",
    "Description": "Student employs proofreading strategies and consults resources to correct errors in spelling, capitalization, and punctuation."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W5.3",
    "Description": "Student edits for accuracy of citation and proper use of publishing guidelines."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "W5.4",
    "Description": "Student prepares text for presentation/publication."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "S1.1",
    "Description": "Student understands the transactional nature of the communication process."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "S2.1",
    "Description": "Student communicates in one-to-one contexts."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "S2.2",
    "Description": "Student plans for and participates in group discussion."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "S3.1",
    "Description": "Student analyzes purpose, audience, and context when planning a presentation or performance."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "S3.2",
    "Description": "Student gathers and organizes content to achieve purposes for a presentation or performance."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "S3.3",
    "Description": "Student rehearses and revises."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "S3.4",
    "Description": "Student presents, monitors audience engagement, and adapts delivery."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "L1.1",
    "Description": "Student understands the transactional nature of the communication process."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "L2.1",
    "Description": "Student manages barriers to listening."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "L3.1",
    "Description": "Student listens to comprehend."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "L3.2",
    "Description": "Student listens to evaluate."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "L3.3",
    "Description": "Student listens empathically."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "M1.1",
    "Description": "Student understands the nature of media communication."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "M2.1",
    "Description": "Student understands, interprets, analyzes, and evaluates media communication"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "M3.1",
    "Description": "Student analyzes purpose, audience, and media channel when planning for a media communication."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "M3.2",
    "Description": "Student develops and produces an informational or creative media communication."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "M3.3",
    "Description": "Student evaluates and revises a media communication."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L1.1.",
    "Description": "Use parallel structure."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L1.2.",
    "Description": "Use various types of phrases and clauses to add variety and interest to writing or presentations."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L1.3.",
    "Description": "Understand that usage is a matter of convention that can change over time."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L1.4.",
    "Description": "Resolve issues of complex or contested usage, consulting references as needed."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L2.1.",
    "Description": "Use punctuation (commas, parentheses, dashes, hyphens) to clarify and enhance writing."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L2.2.",
    "Description": "Use a semicolon to link two or more closely related independent clauses."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L2.3.",
    "Description": "Use a colon to introduce a list or quotation."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L3a.",
    "Description": "Vary syntax for effect, consulting references for guidance as needed; apply an understanding of syntax to the study of complex texts when reading."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L4a.",
    "Description": "Use context (e.g., the overall meaning of a sentence, paragraph, or text; a word’s position or function in a sentence) as a clue to the meaning of a word or phrase."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L4b.",
    "Description": "Identify and correctly use patterns of word changes that indicate different meanings or parts of speech (e.g., conceive, conception, conceivable)."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L4c.",
    "Description": "Consult general and specialized reference materials (e.g., dictionaries, glossaries, thesauruses) to find the pronunciation of a word or determine or clarify its precise meaning, its part of speech, its etymology, or its standard usage."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L4d.",
    "Description": "Verify the preliminary determination of the meaning of a word or phrase (e.g., by checking the inferred meaning in context or in a dictionary)."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L5a.",
    "Description": "Interpret figures of speech, including hyperbole and paradox, in context and analyze their role in the text."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L5b.",
    "Description": "Analyze nuances in the meaning of words with similar denotations."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12LS1a.",
    "Description": "Come to discussions prepared, having read and researched material under study; draw on that preparation by referring to evidence to stimulate a thoughtful, well-reasoned exchange of ideas."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12SL1b.",
    "Description": "Work with peers to set norms for collegial discussions and decision-making, establish clear goals, deadlines, and individual roles as needed."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12SL1c.",
    "Description": "Pose and respond to questions that probe reasoning and evidence; address a full range of positions; clarify, verify, or challenge ideas and conclusions; and promote divergent and creative perspectives."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12SL1d.",
    "Description": "Respond thoughtfully to diverse perspectives; synthesize comments, claims, and evidence made on all sides of an issue; resolve contradictions when possible; and determine what additional information or research is required to deepen the investigation or complete the task."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W1a.",
    "Description": "Introduce precise claim(s), establish the significance of the claim(s), distinguish the claim(s) from counterclaim(s), and create an organization that logically sequences claims, counterclaims, reasons, and evidence."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W1b.",
    "Description": "Develop claim(s) and counterclaim(s) thoroughly and in a balanced manner, supplying the most relevant evidence for each while pointing out the strengths and limitations of both, anticipating the audience’s knowledge level, concerns, values, and possible biases."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W1c.",
    "Description": "Use precise language, content-specific vocabulary and literary techniques to express the appropriate complexity of the topic."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W1d.",
    "Description": "Use appropriate and varied transitions, as well as varied syntax, to make critical connections, create cohesion, and clarify the relationships among complex ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W1e.",
    "Description": "Provide a concluding statement or section that explains the significance of the argument presented."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W1f.",
    "Description": "Maintain a style and tone appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W2a.",
    "Description": "Introduce and organize complex ideas, concepts, and information so that each new element builds on that which precedes it to create a unified whole."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W2b.",
    "Description": "Develop a topic thoroughly by selecting the most significant and relevant facts, definitions, concrete details, direct quotations and paraphrased information or other examples, appropriate to the audience's knowledge of the topic. Include formatting, graphics, and multimedia when useful to aid comprehension."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W2c.",
    "Description": "Use precise language, domain-specific vocabulary and literary techniques to express the appropriate complexity of a topic."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W2d.",
    "Description": "Use appropriate and varied transitions and syntax to make insightful connections and distinctions, create cohesion, and clarify relationships among complex ideas and concepts."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W2e.",
    "Description": "Provide a concluding statement or section that explains the significance of the information presented."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W2f.",
    "Description": "Establish and maintain a style appropriate to the writing task."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W3a.",
    "Description": "Engage the reader by presenting a problem, conflict, situation, or observation and its significance, establishing one or multiple point(s) of view, and introducing a narrator and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W3b.",
    "Description": "Use narrative techniques, such as dialogue, pacing, description, reflection, and plot lines to develop experiences, events, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W3c.",
    "Description": "Use a variety of techniques to sequence events to create cohesion, a smooth progression of experiences or events, and build toward a particular tone and outcome (e.g., a sense of mystery, suspense, growth, or resolution)."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W3d.",
    "Description": "Use precise words and phrases, explicit details, and sensory language to convey a vivid picture of the experiences, events, setting, and/or characters."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W3e.",
    "Description": "Provide a conclusion that follows from and reflects on what is experienced, observed, or resolved over the course of the narrative."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L1.",
    "Description": "Demonstrate command of the conventions of Standard English grammar and usage when writing or speaking. Core Conventions Skills for Grades 9→12:"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L2.",
    "Description": "Demonstrate command of the conventions of Standard English capitalization, punctuation, and spelling when writing. Core Punctuation and Spelling Skills for Grades 9→12:"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L3.",
    "Description": "Apply knowledge of language to understand how language functions in different contexts, to make effective choices for meaning or style, and to comprehend more fully when reading or listening."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L4.",
    "Description": "Determine or clarify the meaning of unknown and multiple-meaning words and phrases, choosing flexibly from a range of strategies."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L5.",
    "Description": "Demonstrate understanding of figurative language, word relationships, and nuances in word meanings."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12L6.",
    "Description": "Acquire and accurately use general academic and content-specific words and phrases, sufficient for reading, writing, speaking, and listening at the college and career readiness level; demonstrate independence in applying vocabulary knowledge when considering a word or phrase important to comprehension or expression."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12R1.",
    "Description": "Cite strong and thorough textual evidence to support analysis of what the text says explicitly/implicitly and make logical inferences, including determining where the text is ambiguous; develop questions for deeper understanding and for further exploration. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12R2.",
    "Description": "Determine two or more themes or central ideas in a text and analyze their development, including how they emerge and are shaped and refined by specific details; objectively and accurately summarize a complex text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12R3.",
    "Description": "In literary texts, analyze the impact of author's choices. (RL) In infomational texts, analyze a complex set of ideas or sequence of events and explain how specific individuals, ideas, or events interact and develop. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12R4.",
    "Description": "Determine the meaning of words and phrases as they are used in a text, including figurative and connotative meanings. Analyze the impact of specific word choices on meaning, tone, and mood, including words with multiple meanings. Analyze how an author uses and refines the meaning of technical or key term(s) over the course of a text. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12R5.",
    "Description": "In literary texts, analyze how varied aspects of structure create meaning and affect the reader. (RL) In informational texts, analyze the impact and evaluate the effect structure has on exposition or argument in terms of clarity, persuasive/rhetorical technique, and audience appeal. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12R6.",
    "Description": "Analyze how authors employ point of view, perspective, and purpose, to shape explicit and implicit messages (e.g., persuasiveness, aesthetic quality, satire, sarcasm, irony, or understatement). (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12R7.",
    "Description": "In literary texts, analyze multiple adaptations of a source text as presented in different formats (e.g., works of art, graphic novels, music, film, etc.), specifically evaluating how each version interprets the source. (RL) In informational texts, integrate and evaluate sources on the same topic or argument in order to address a question, or solve a problem. (RI)"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12R8.",
    "Description": "Delineate and evaluate an argument in applicable texts, applying a lens (e.g. constitutional principles, logical fallacy, legal reasoning, belief systems, codes of ethics, philosophies, etc.) to assess the validity or fallacy of key arguments, determining whether the supporting evidence is relevant and sufficient. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12R9.",
    "Description": "Choose and develop criteria in order to evaluate the quality of texts. Make connections to other texts, ideas, cultural perspectives, eras, and personal experiences. (RI&RL)"
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12SL1.",
    "Description": "Initiate and participate effectively in a range of collaborative discussions with diverse partners on complex topics, texts, and issues; express ideas clearly and persuasively, and build on those of others."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12SL2.",
    "Description": "Integrate multiple sources of information presented in diverse formats (e.g., including visual, quantitative, and oral). Evaluate the credibility and accuracy of each source, and note any discrepancies among the data to make informed decisions and solve problems."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12SL3.",
    "Description": "Evaluate a speaker’s point of view, reasoning, and use of evidence and rhetoric; assess the premises and connections among ideas, diction, and tone."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12SL4.",
    "Description": "Present claims, findings, and supporting evidence, conveying a clear and distinct perspective; alternative or opposing perspectives are addressed; organization, development, substance, and style are appropriate to task, purpose, and audience."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12SL5.",
    "Description": "Make strategic use of digital media and/or visual displays in presentations to enhance understanding of findings, reasoning, and evidence, and to add elements of interest to engage the audience."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12SL6.",
    "Description": "Adapt speech to a variety of contexts and tasks, demonstrating a command of formal English when indicated or appropriate."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W1.",
    "Description": "Write arguments to support claims that analyze substantive topics or texts, using valid reasoning and relevant and sufficient evidence."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W2.",
    "Description": "Write informative/explanatory texts to examine and convey complex ideas, concepts, and information clearly and accurately through the effective selection, organization, and analysis of content."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W3.",
    "Description": "Write narratives to develop real or imagined experiences or events using effective techniques, well-chosen details, and well-structured event sequences."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W4.",
    "Description": "Create a poem, story, play, art work, or other response to a text, author, theme or personal experience; demonstrate knowledge and understanding of a variety of techniques and genres. Explain connections between the original and the created work."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W5.",
    "Description": "Draw evidence from literary or informational texts to support analysis, reflection, and research. Apply grade 45242 Reading standards to both literary and informational text, where applicable."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W6.",
    "Description": "Conduct research through self-generated question, or solve a problem; narrow or broaden the inquiry when appropriate. Synthesize multiple sources, demonstrating understanding and analysis of the subject under investigation."
  },
  {
    "Course": "ELA",
    "Grade": 12,
    "Standard": "11-12W7.",
    "Description": "Gather relevant information from multiple sources, using advanced searches effectively; assess the strengths and limitations of each source in terms of the task, purpose, and audience; integrate information into the text selectively to maintain the flow of ideas; avoid plagiarism, overreliance on one source, and follow a standard format for citation."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.A.1.",
    "Description": "Count to 100 by ones and by tens."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.A.2.",
    "Description": "Count to 100 by ones beginning from any given number (instead of beginning at 1)."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.A.3.",
    "Description": "Write numbers from 0 to 20 Represent a number of objects with a written numeral 0-20 (with 0 representing a count of no objects)."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.B.4.",
    "Description": "Understand the relationship between numbers and quantities up to and including 20; connect counting to cardinality."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.B.4a.",
    "Description": "When counting objects, say the number names in the standard order, pairing each object with one and only one number name and each number name with one and only one object. (1:1 correspondence)"
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.B.4b.",
    "Description": "Understand that the last number name said tells the number of objects counted, (cardinality). The number of objects is the same regardless of their arrangement or the order in which they were counted."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.B.4c.",
    "Description": "Understand the concept that each successive number name refers to a quantity that is one larger."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.B.4d.",
    "Description": "Understand the concept of ordinal numbers (first through tenth) to describe the relative position and magnitude of whole numbers."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.B.5a.",
    "Description": "Answer counting questions using as many as 20 objects arranged in a line, a rectangular array, and a circle. Answer counting questions using as many as 10 objects in a scattered configuration."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.B.5b.",
    "Description": "Given a number from 1–20, count out that many objects."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.C.6.",
    "Description": "Identify whether the number of objects in one group is greater than (more than), less than (fewer than), or equal to (the same as) the number of objects in another group. Note: Include groups with up to ten objects."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.CC.C.7.",
    "Description": "Compare two numbers between 1 and 10 presented as written numerals."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.G.A.1.",
    "Description": "Describe objects in the environment using names of shapes, and describe the relative positions of these objects using terms such as above, below, beside, in front of, behind, and next to."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.G.A.2.",
    "Description": "Name shapes regardless of their orientation or overall size."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.G.A.3.",
    "Description": "Understand the difference between two-dimensional (lying in a plane, “flat”) and three-dimensional (“solid”) shapes."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.G.B.4.",
    "Description": "Analyze and compare two- and three-dimensional shapes, in different sizes and orientations, using informal language to describe their similarities, differences, parts, and other attributes."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.G.B.5.",
    "Description": "Model objects in their environment by building and/or drawing shapes."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.G.B.6.",
    "Description": "Compose larger shapes from simple shapes."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.MD.A.1.",
    "Description": "Describe measurable attributes of an object(s), such as length or weight, using appropriate vocabulary."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.MD.A.2.",
    "Description": "Directly compare two objects with a common measurable attribute and describe the difference."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.MD.B.3.",
    "Description": "Classify objects into given categories; count the objects in each category and sort the categories by count. Note: Limit category counts to be less than or equal to 10"
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.MD.B.4.",
    "Description": "Explore coins (pennies, nickels, dimes, and quarters) and begin identifying pennies and dimes."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.NBT.A.1.",
    "Description": "Compose and decompose the numbers from 11 to 19 into ten ones and one, two, three, four, five, six, seven, eight, or nine ones."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.OA.A.1.",
    "Description": "Represent addition and subtraction using objects, fingers, pennies, drawings, sounds, acting out situations, verbal explanations, expressions, equations or other strategies."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.OA.A.2a.",
    "Description": "Add and subtract within 10"
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.OA.A.2b.",
    "Description": "Solve addition and subtraction word problems within 10"
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.OA.A.3.",
    "Description": "Decompose numbers less than or equal to 10 into pairs in more than one way. Record each decomposition by a drawing or equation."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.OA.A.4.",
    "Description": "Find the number that makes 10 when given a number from 1 to 9 Record the answer with a drawing or equation."
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.OA.A.5.",
    "Description": "Fluently add and subtract within 5"
  },
  {
    "Course": "Math",
    "Grade": "K",
    "Standard": "K.OA.B.6.",
    "Description": "Duplicate, extend, and create simple patterns using concrete objects."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.G.A.1.",
    "Description": "Distinguish between defining attributes versus non-defining attributes for a wide variety of shapes. Build and/or draw shapes to possess defining attributes."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.G.A.2.",
    "Description": "Compose two-dimensional shapes (rectangles, squares, trapezoids, triangles, half-circles, and quarter-circles) or three-dimensional shapes (cubes, right rectangular prisms, right circular cones, and right circular cylinders) to create a composite shape, and compose new shapes from the composite shape."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.G.A.3.",
    "Description": "Partition circles and rectangles into two and four equal shares, describe the shares using the words halves, fourths, and quarters, and use the phrases half of, fourth of, and quarter of. Describe the whole as two of, or four of the shares. Understand for these examples that decomposing into more equal shares creates smaller shares."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.MD.A.1.",
    "Description": "Order three objects by length; compare the lengths of two objects indirectly by using a third object."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.MD.A.2.",
    "Description": "Measure the length of an object using same-size “length units” placed end to end with no gaps or overlaps. Express the length of an object as a whole number of “length units.”"
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.MD.B.3a.",
    "Description": "Tell and write time in hours and half-hours using analog and digital clocks. Develop an understanding of common terms, such as, but not limited to, o’clock and half past."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.MD.B.3b.",
    "Description": "Recognize and identify coins (penny, nickel, dime, and quarter) and their value and use the cent symbol (¢) appropriately."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.MD.B.3c.",
    "Description": "Count a mixed collection of dimes and pennies and determine the cent value (total not to exceed 100 cents)."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.MD.C.4.",
    "Description": "Organize, represent, and interpret data with up to three categories; ask and answer questions about the total number of data points, how many in each category, and how many more or less are in one category than in another."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.A.1.",
    "Description": "Count to 120, starting at any number less than 120 In this range, read and write numerals and represent a number of objects with a written numeral."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.B.2.",
    "Description": "Understand that the two digits of a two-digit number represent amounts of tens and ones."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.B.2a.",
    "Description": "Understand 10 can be thought of as a bundle of ten ones, called a ten."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.B.2b.",
    "Description": "Understand that the numbers from 11 to 19 are composed of a ten and one, two, three, four, five, six, seven, eight, or nine ones."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.B.2c.",
    "Description": "Understand that the numbers 10, 20, 30, 40, 50, 60, 70, 80, 90 refer to one, two, three, four, five, six, seven, eight or nine tens (and 0 ones)."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.B.3.",
    "Description": "Compare two two-digit numbers based on meanings of the tens and ones digits, recording the results of comparisons with the symbols &gt;, =, and &lt;."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.C.4.",
    "Description": "Add within 100, including: a two-digit number and a one-digit number; a two-digit number and a multiple of 10"
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.C.4a.",
    "Description": "Use concrete models or drawings and strategies based on place value, properties of operations, and/or the relationship between addition and subtraction."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.C.4b.",
    "Description": "Understand that in adding two-digit numbers, one adds tens and tens, ones and ones, and sometimes it is necessary to compose a ten."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.C.4c.",
    "Description": "Relate the strategy to a written representation and explain the reasoning used."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.C.5.",
    "Description": "Given a two-digit number, mentally find 10 more or 10 less than the number, without having to count; explain the reasoning used."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.C.6.",
    "Description": "Subtract multiples of 10 from multiples of 10 in the range 33147 using concrete models or drawings, and strategies based on place value, properties of operations, and/or the relationship between addition and subtraction. Relate the strategy used to a written representation and explain the reasoning."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.A.1.",
    "Description": "Use addition and subtraction within 20 to solve one step word problems involving situations of adding to, taking from, putting together, taking apart, and/or comparing, with unknowns in all positions. Note: Problems should be represented using objects, drawings, and equations with a symbol for the unknown number. Problems should be solved using objects or drawings, and equations."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.A.2.",
    "Description": "Solve word problems that call for addition of three whole numbers whose sum is less than or equal to 20"
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.B.3.",
    "Description": "Apply properties of operations as strategies to add and subtract."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.B.4.",
    "Description": "Understand subtraction as an unknown-addend problem within 20"
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.C.5.",
    "Description": "Relate counting to addition and subtraction."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.C.6a.",
    "Description": "Add and subtract within 20 Use strategies such as: counting on; making ten; decomposing a number leading to a ten; using the relationship between addition and subtraction; and creating equivalent but easier or known sums."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.C.6b.",
    "Description": "Fluently add and subtract within 10"
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.D.7.",
    "Description": "Understand the meaning of the equal sign, and determine if equations involving addition and subtraction are TRUE or false."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.D.8.",
    "Description": "Determine the unknown whole number in an addition or subtraction equation with the unknown in all positions."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.MD.A.",
    "Description": "Measure lengths indirectly and by iterating length units."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.MD.B.",
    "Description": "Tell and write time and money."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.MD.C.",
    "Description": "Represent and interpret data."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.A.",
    "Description": "Extend the counting sequence."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.B.",
    "Description": "Understand place value."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.NBT.C.",
    "Description": "Use place value understanding and properties of operations to add and subtract."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.A.",
    "Description": "Represent and solve problems involving addition and subtraction."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.B.",
    "Description": "Understand and apply properties of operations and the relationship between addition and subtraction."
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.C.",
    "Description": "Add and subtract within 20"
  },
  {
    "Course": "Math",
    "Grade": 1,
    "Standard": "1.OA.D.",
    "Description": "Work with addition and subtraction equations."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.G.A.1.",
    "Description": "Classify two-dimensional figures as polygons or non-polygons."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.G.A.2.",
    "Description": "Partition a rectangle into rows and columns of same-size squares and count to find the total number of them."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.G.A.3.",
    "Description": "Partition circles and rectangles into two, three, or four equal shares. Describe the shares using the words halves, thirds, half of, a third of, etc. Describe the whole as two halves, three thirds, four fourths. Recognize that equal shares of identical wholes need not have the same shape."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.A.1.",
    "Description": "Measure the length of an object to the nearest whole by selecting and using appropriate tools such as rulers, yardsticks, meter sticks, and measuring tapes."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.A.2.",
    "Description": "Measure the length of an object twice, using different “length units” for the two measurements; describe how the two measurements relate to the size of the unit chosen."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.A.3.",
    "Description": "Estimate lengths using units of inches, feet, centimeters, and meters."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.A.4.",
    "Description": "Measure to determine how much longer one object is than another, expressing the length difference in terms of a standard “length unit.”"
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.B.5.",
    "Description": "Use addition and subtraction within 100 to solve word problems involving lengths that are given in the same units."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.B.6.",
    "Description": "Represent whole numbers as lengths from 0 on a number line with equally spaced points corresponding to the numbers 0, 1, 2, …, and represent whole-number sums and differences within 100 on a number line."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.C.7.",
    "Description": "Tell and write time from analog and digital clocks in five minute increments, using a.m. and p.m. Develop an understanding of common terms, such as, but not limited to, quarter past, half past, and quarter to."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.C.8a.",
    "Description": "Count a mixed collection of coins whose sum is less than or equal to one dollar."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.C.8b.",
    "Description": "Solve real world and mathematical problems within one dollar involving quarters, dimes, nickels, and pennies, using the ¢ (cent) symbols appropriately."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.D.10.",
    "Description": "Draw a picture graph and a bar graph (with single-unit scale) to represent a data set with up to four categories. Solve simple put-together, take-apart, and compare problems using information presented in a picture graph or a bar graph."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.D.9.",
    "Description": "Generate measurement data by measuring lengths of several objects to the nearest whole unit, or by making repeated measurements of the same object. Present the measurement data in a line plot, where the horizontal scale is marked off in whole-number units."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.A.1.",
    "Description": "Understand that the three digits of a three-digit number represent amounts of hundreds, tens, and ones."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.A.1a.",
    "Description": "Understand 100 can be thought of as a bundle of ten tens, called a hundred."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.A.1b.",
    "Description": "Understand the numbers 100, 200, 300, 400, 500, 600, 700, 800, 900 refer to one, two, three, four, five, six, seven, eight, or nine hundreds (and 0 tens and 0 ones)."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.A.2.",
    "Description": "Count within 1000; skip-count by 5s, 10s, and 100s."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.A.3.",
    "Description": "Read and write numbers to 1000 using base-ten numerals, number names, and expanded form."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.A.4.",
    "Description": "Compare two three-digit numbers based on meanings of the hundreds, tens, and ones digits, using &gt;, =, and &lt; symbols to record the results of comparisons."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.B.5.",
    "Description": "Fluently add and subtract within 100 using strategies based on place value, properties of operations, and/or the relationship between addition and subtraction."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.B.6.",
    "Description": "Add up to four two-digit numbers using strategies based on place value and properties of operations."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.B.7a.",
    "Description": "Add and subtract within 1000, using concrete models or drawings and strategies based on place value, properties of operations, and/or the relationship between addition and subtraction; relate the strategy to a written representation."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.B.7b.",
    "Description": "Understand that in adding or subtracting up to three-digit numbers, one adds or subtracts hundreds and hundreds, tens and tens, ones and ones, and sometimes it is necessary to compose or decompose tens or hundreds."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.B.8.",
    "Description": "Mentally add 10 or 100 to a given number 100-900, and mentally subtract 10 or 100 from a given number 100-900."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.B.9.",
    "Description": "Explain why addition and subtraction strategies work, using place value and the properties of operations."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.A.1a.",
    "Description": "Use addition and subtraction within 100 to solve one-step word problems involving situations of adding to, taking from, putting together, taking apart, and comparing, with unknowns in all positions."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.A.1b.",
    "Description": "Use addition and subtraction within 100 to develop an understanding of solving two-step problems involving situations of adding to, taking from, putting together, taking apart, and comparing, with unknowns in all positions."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.B.2a.",
    "Description": "Fluently add and subtract within 20 using mental strategies. Strategies could include: making ten; decomposing a number leading to a ten; using the relationship between addition and subtraction; creating equivalent but easier or known sums; and counting on."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.B.2b.",
    "Description": "Know from memory all sums within 20 of two one-digit numbers."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.C.3a.",
    "Description": "Determine whether a group of objects (up to 20) has an odd or even number of members."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.C.3b.",
    "Description": "Write an equation to express an even number as a sum of two equal addends."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.C.4.",
    "Description": "Use addition to find the total number of objects arranged in rectangular arrays with up to 5 rows and up to 5 columns. Write an equation to express the total as a sum of equal addends."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.G.A.",
    "Description": "Reason with shapes and their attributes."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.A.",
    "Description": "Measure and estimate lengths in standard units."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.B.",
    "Description": "Relate addition and subtraction to length."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.C.",
    "Description": "Work with time and money."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.MD.D.",
    "Description": "Represent and interpret data."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.A.",
    "Description": "Understand place value."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.NBT.B.",
    "Description": "Use place value understanding and properties of operations to add and subtract."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.A.",
    "Description": "Represent and solve problems involving addition and subtraction."
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.B.",
    "Description": "Add and Subtract within 20"
  },
  {
    "Course": "Math",
    "Grade": 2,
    "Standard": "2.OA.C.",
    "Description": "Work with equal groups of objects to gain foundations for multiplication."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.G.A.1.",
    "Description": "Recognize and classify polygons based on the number of sides and vertices (triangles, quadrilaterals, pentagons, and hexagons). Identify shapes that do not belong to one of the given subcategories."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.G.A.2.",
    "Description": "Partition shapes into parts with equal areas. Express the area of each part as a unit fraction of the whole."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.A.1.",
    "Description": "Tell and write time to the nearest minute and measure time intervals in minutes. Solve one-step word problems involving addition and subtraction of time intervals in minutes."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.A.2a.",
    "Description": "Measure and estimate liquid volumes and masses of objects using grams (g), kilograms (kg), and liters (l)."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.A.2b.",
    "Description": "Add, subtract, multiply, or divide to solve one-step word problems involving masses or liquid volumes that are given in the same units."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.B.3.",
    "Description": "Draw a scaled picture graph and a scaled bar graph to represent a data set with several categories. Solve one- and two-step “how many more” and “how many less” problems using information presented in a scaled picture graph or a scaled bar graph."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.B.4.",
    "Description": "Generate measurement data by measuring lengths using rulers marked with halves and fourths of an inch. Show the data by making a line plot where the horizontal scale is marked off in appropriate units—whole numbers, halves, or quarters."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.5.",
    "Description": "Recognize area as an attribute of plane figures and understand concepts of area measurement."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.5a.",
    "Description": "Recognize a square with side length 1 unit, called “a unit square,” is said to have “one square unit” of area, and can be used to measure area."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.5b.",
    "Description": "Recognize a plane figure which can be covered without gaps or overlaps by n unit squares is said to have an area of n square units."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.6.",
    "Description": "Measure areas by counting unit squares."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.7.",
    "Description": "Relate area to the operations of multiplication and addition."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.7a.",
    "Description": "Find the area of a rectangle with whole-number side lengths by tiling it, and show that the area is the same as would be found by multiplying the side lengths."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.7b.",
    "Description": "Multiply side lengths to find areas of rectangles with whole-number side lengths in the context of solving real world and mathematical problems, and represent whole-number products as rectangular areas in mathematical reasoning."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.7c.",
    "Description": "Use tiling to show in a concrete case that the area of a rectangle with whole-number side length a and side length b + c is the sum of a × b and a × c. Use area models to represent the distributive property in mathematical reasoning."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.7d.",
    "Description": "Recognize area as additive. Find areas of figures composed of non-overlapping rectangles, and apply this technique to solve real world problems."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.D.8a.",
    "Description": "Solve real world and mathematical problems involving perimeters of polygons, including finding the perimeter given the side lengths or finding one unknown side length given the perimeter and other side lengths."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.D.8b.",
    "Description": "Identify rectangles with the same perimeter and different areas or with the same area and different perimeters."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NBT.A.1.",
    "Description": "Use place value understanding to round whole numbers to the nearest 10 or 100"
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NBT.A.2.",
    "Description": "Fluently add and subtract within 1000 using strategies and algorithms based on place value, properties of operations, and/or the relationship between addition and subtraction."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NBT.A.3.",
    "Description": "Multiply one-digit whole numbers by multiples of 10 in the range 33147 using strategies based on place value and properties of operations."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NBT.A.4a.",
    "Description": "Understand that the four digits of a four-digit number represent amounts of thousands, hundreds, tens and ones."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NBT.A.4b.",
    "Description": "Read and write four digit numbers using base-ten numerals, number names and expanded form."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.1.",
    "Description": "Understand a unit fraction, 1/b, is the quantity formed by 1 part when a whole is partitioned into b equal parts. Understand a fraction a/b is the quantity formed by a parts of size 1/b."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.2.",
    "Description": "Understand a fraction as a number on the number line; represent fractions on a number line."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.2a.",
    "Description": "Represent a fraction 1/b on a number line by defining the interval from 0 to 1 as the whole and partitioning it into b equal parts. Recognize that each part has size 1/b and that the endpoint of the part starting at 0 locates the number 1/b on the number line."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.2b.",
    "Description": "Represent a fraction a/b on a number line by marking off a lengths 1/b from 0 Recognize that the resulting interval has size a/b and that its endpoint locates the number a/b on the number line."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.3.",
    "Description": "Explain equivalence of fractions and compare fractions by reasoning about their size."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.3a.",
    "Description": "Understand two fractions as equivalent (equal) if they are the same size, or the same point on a number line."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.3b.",
    "Description": "Recognize and generate equivalent fractions. Explain why the fractions are equivalent."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.3c.",
    "Description": "Express whole numbers as fractions, and recognize fractions that are equivalent to whole numbers."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.3d.",
    "Description": "Compare two fractions with the same numerator or the same denominator by reasoning about their size. Recognize that comparisons rely on the two fractions referring to the same whole. Record the results of comparisons with the symbols &gt;, =, or &lt;, and justify the conclusions."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.A.1.",
    "Description": "Interpret products of whole numbers."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.A.2.",
    "Description": "Interpret whole-number quotients of whole numbers."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.A.3.",
    "Description": "Use multiplication and division within 100 to solve word problems in situations involving equal groups, arrays, and measurement quantities."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.A.4.",
    "Description": "Determine the unknown whole number in a multiplication or division equation relating three whole numbers."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.B.5.",
    "Description": "Apply properties of operations as strategies to multiply and divide."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.B.6.",
    "Description": "Understand division as an unknown-factor problem."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.C.7a.",
    "Description": "Fluently solve single-digit multiplication and related divisions, using strategies such as the relationship between multiplication and division or properties of operations."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.C.7b.",
    "Description": "Know from memory all products of two one-digit numbers."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.D.8.",
    "Description": "Solve two-step word problems posed with whole numbers and having whole-number answers using the four operations."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.D.8a.",
    "Description": "Represent these problems using equations or expressions with a letter standing for the unknown quantity."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.D.8b.",
    "Description": "Assess the reasonableness of answers using mental computation and estimation strategies including rounding."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.D.9.",
    "Description": "Identify and extend arithmetic patterns (including patterns in the addition table or multiplication table)."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.G.A.",
    "Description": "Reason with shapes and their attributes."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.A.",
    "Description": "Solve problems involving measurement and estimation of intervals of time, liquid volumes, and masses of objects."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.B.",
    "Description": "Represent and interpret data."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.C.",
    "Description": "Geometric measurement: understand concepts of area and relate area to multiplication and to addition."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.MD.D.",
    "Description": "Geometric measurement: recognize perimeter as an attribute of plane figures and distinguish between linear and area measures."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NBT.A.",
    "Description": "Use place value understanding and properties of operations to perform multi-digit arithmetic."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.NF.A.",
    "Description": "Develop understanding of fractions as numbers."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.A.",
    "Description": "Represent and solve problems involving multiplication and division."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.B.",
    "Description": "Understand properties of multiplication and the relationship between multiplication and division."
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.C.",
    "Description": "Multiply and divide within 100"
  },
  {
    "Course": "Math",
    "Grade": 3,
    "Standard": "3.OA.D.",
    "Description": "Solve problems involving the four operations, and identify and extend patterns in arithmetic."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.G.A.1.",
    "Description": "Draw points, lines, line segments, rays, angles (right, acute, obtuse), and perpendicular and parallel lines. Identify these in two-dimensional figures."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.G.A.2a.",
    "Description": "Identify and name triangles based on angle size (right, obtuse, acute)."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.G.A.2b.",
    "Description": "Identify and name all quadrilaterals with four right angles as rectangles."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.G.A.2c.",
    "Description": "Identify and name all quadrilaterals with 2 pairs of parallel sides as parallelograms."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.G.A.3.",
    "Description": "Recognize a line of symmetry for a two-dimensional figure as a line across the figure such that the figure can be folded along the line into matching parts. Identify line-symmetric figures and draw lines of symmetry."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.A.1.",
    "Description": "Within a single system of measurement, express measurements in a larger unit in terms of a smaller unit."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.A.1a.",
    "Description": "Know relative sizes of units: ft., in.; km, m, cm; hr., min., sec."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.A.1b.",
    "Description": "Convert units within one system of units when the conversion factor is given."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.A.1c.",
    "Description": "Record measurement equivalents in a two-column table."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.A.2.",
    "Description": "Use the four operations to solve word problems involving distances, intervals of time, liquid volumes, masses of objects, and money."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.A.2a.",
    "Description": "Solve problems involving fractions or decimals, and problems that require expressing measurements given in a larger unit in terms of a smaller unit."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.A.2b.",
    "Description": "Represent measurement quantities using diagrams that feature a measurement scale, such as number lines."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.A.3.",
    "Description": "Apply the area and perimeter formulas for rectangles in real world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.B.4.",
    "Description": "Make a line plot to display a data set of measurements in fractions of a unit (1/2,1/4,1/8). Solve problems involving addition and subtraction of fractions by using information presented in line plots."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.C.5.",
    "Description": "Recognize angles as geometric shapes that are formed wherever two rays share a common endpoint, and understand concepts of angle measurement."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.C.5a.",
    "Description": "Recognize an angle is measured with reference to a circle with its center at the common endpoint of the rays, by considering the fraction of the circular arc between the points where the two rays intersect the circle. An angle that turns through 1/360 of a circle is called a “one-degree angle,” and can be used to measure angles."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.C.5b.",
    "Description": "Recognize an angle that turns through n one-degree angles is said to have an angle measure of n degrees."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.C.6.",
    "Description": "Measure angles in whole-number degrees using a protractor. Sketch angles of specified measure."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.C.7.",
    "Description": "Recognize angle measure as additive. When an angle is decomposed into non-overlapping parts, the angle measure of the whole is the sum of the angle measures of the parts. Solve addition and subtraction problems to find unknown angles on a diagram in real world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NBT.A.1.",
    "Description": "Recognize that in a multi-digit whole number, a digit in one place represents ten times what it represents in the place to its right."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NBT.A.2a.",
    "Description": "Read and write multi-digit whole numbers using base-ten numerals, number names, and expanded form."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NBT.A.2b.",
    "Description": "Compare two multi-digit numbers based on meanings of the digits in each place, using &gt;, =, and &lt; symbols to record the results of comparisons."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NBT.A.3.",
    "Description": "Use place value understanding to round multi-digit whole numbers to any place."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NBT.B.4.",
    "Description": "Fluently add and subtract multi-digit whole numbers using a standard algorithm."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NBT.B.5.",
    "Description": "Multiply a whole number of up to four digits by a one-digit whole number, and multiply two two-digit numbers, using strategies based on place value and the properties of operations. Illustrate and explain the calculation by using equations, rectangular arrays, and/or area models."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NBT.B.6.",
    "Description": "Find whole-number quotients and remainders with up to four-digit dividends and one-digit divisors, using strategies based on place value, the properties of operations, and/or the relationship between multiplication and division. Illustrate and explain the calculation by using equations, rectangular arrays, and/or area models."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.A.1.",
    "Description": "Explain why a fraction a/b is equivalent to a fraction (ݑ ΃ װݑ)/(۰ݑ σ װݑ) by using visual fraction models, with attention to how the number and size of the parts differ even though the two fractions themselves are the same size. Use this principle to recognize and generate equivalent fractions."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.A.2.",
    "Description": "Compare two fractions with different numerators and different denominators. Recognize that comparisons are valid only when the two fractions refer to the same whole. Record the results of comparisons with symbols &gt;, =, or &lt;, and justify the conclusions."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.3.",
    "Description": "Understand a fraction a/b with a &gt; 1 as a sum of fractions 1/b."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.3a.",
    "Description": "Understand addition and subtraction of fractions as joining and separating parts referring to the same whole."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.3b.",
    "Description": "Decompose a fraction into a sum of fractions with the same denominator in more than one way, recording each decomposition by an equation. Justify decompositions."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.3c.",
    "Description": "Add and subtract mixed numbers with like denominators."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.3d.",
    "Description": "Solve word problems involving addition and subtraction of fractions referring to the same whole and having like denominators."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.4.",
    "Description": "Apply and extend previous understandings of multiplication to multiply a whole number by a fraction."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.4a.",
    "Description": "Understand a fraction ݑ/ΰݑ as a multiple of 1/ϰݑ."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.4b.",
    "Description": "Understand a multiple of a/b as a multiple of a/b', and use this understanding to multiply a whole number by a fraction."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.4c.",
    "Description": "Solve word problems involving multiplication of a whole number by a fraction."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.C.5.",
    "Description": "Express a fraction with denominator 10 as an equivalent fraction with denominator 100, and use this technique to add two fractions with respective denominators 10 and 100"
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.C.6.",
    "Description": "Use decimal notation for fractions with denominators 10 or 100"
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.C.7.",
    "Description": "Compare two decimals to hundredths by reasoning about their size. Recognize that comparisons are valid only when two decimals refer to the same whole. Record the results of comparisons with the symbols &gt;, =, or &lt;, and justify the conclusions."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.A.1.",
    "Description": "Interpret a multiplication equation as a comparison. Represent verbal statements of multiplicative comparisons as multiplication equations."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.A.2.",
    "Description": "Multiply or divide to solve word problems involving multiplicative comparison. Use drawings and equations with a symbol for the unknown number to represent the problem; distinguish multiplicative comparison from additive comparison."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.A.3.",
    "Description": "Solve multistep word problems posed with whole numbers and having whole-number answers using the four operations, including problems in which remainders must be interpreted."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.A.3a.",
    "Description": "Represent these problems using equations or expressions with a letter standing for the unknown quantity."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.A.3b.",
    "Description": "Assess the reasonableness of answers using mental computation and estimation strategies including rounding."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.B.4.",
    "Description": "Find all factor pairs for a whole number in the range 1-100. Recognize that a whole number is a multiple of each of its factors. Determine whether a given whole number in the range 1-100 is a multiple of a given one-digit number. Determine whether a given whole number in the range 1-100 is prime or composite."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.C.5.",
    "Description": "Generate a number or shape pattern that follows a given rule. Identify and informally explain apparent features of the pattern that were not explicit in the rule itself."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.G.A.",
    "Description": "Draw and identify lines and angles, and classify shapes by properties of their lines and angles."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.A.",
    "Description": "Solve problems involving measurement and conversion of measurements from a larger unit to a smaller unit."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.B.",
    "Description": "Represent and interpret data."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.MD.C.",
    "Description": "Geometric measurement: understand concepts of angle and measure angles."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NBT.A.",
    "Description": "Generalize place value understanding for multi-digit whole numbers."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NBT.B.",
    "Description": "Use place value understanding and properties of operations to perform multi-digit arithmetic."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.A.",
    "Description": "Extend understanding of fraction equivalence and ordering."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.B.",
    "Description": "Build fractions from unit fractions by applying and extending previous understandings of operations on whole numbers."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.NF.C.",
    "Description": "Understand decimal notation for fractions, and compare decimal fractions."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.A.",
    "Description": "Use the four operations with whole numbers to solve problems."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.B.",
    "Description": "Gain familiarity with factors and multiples."
  },
  {
    "Course": "Math",
    "Grade": 4,
    "Standard": "4.OA.C.",
    "Description": "Generate and analyze patterns."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.G.A.1.",
    "Description": "Use a pair of perpendicular number lines, called axes, to define a coordinate system, with the intersection of the lines (the origin) arranged to coincide with the 0 on each line and a given point in the plane located by using an ordered pair of numbers, called its coordinates. Understand that the first number indicates how far to travel from the origin in the direction of one axis, and the second number indicates how far to travel in the direction of the second axis, with the convention that the names of the two axes and the coordinates correspond."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.G.A.2.",
    "Description": "Represent real world and mathematical problems by graphing points in the first quadrant of the coordinate plane, and interpret coordinate values of points in the context of the situation."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.G.A.3.",
    "Description": "Understand that attributes belonging to a category of two-dimensional figures also belong to all subcategories of that category."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.G.A.4.",
    "Description": "Classify two-dimensional figures in a hierarchy based on properties."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.A.1.",
    "Description": "Convert among different-sized standard measurement units within a given measurement system when the conversion factor is given. Use these conversions in solving multi-step, real world problems."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.B.2.",
    "Description": "Make a line plot to display a data set of measurements in fractions of a unit (1/2,1/4,1/8). Use operations on fractions for this grade to solve problems involving information presented in line plots."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.C.3.",
    "Description": "Recognize volume as an attribute of solid figures and understand concepts of volume measurement."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.C.3a.",
    "Description": "Recognize that a cube with side length 1 unit, called a “unit cube,” is said to have “one cubic unit” of volume, and can be used to measure volume."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.C.3b.",
    "Description": "Recognize that a solid figure which can be packed without gaps or overlaps using n unit cubes is said to have a volume of n cubic units."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.C.4.",
    "Description": "Measure volumes by counting unit cubes, using cubic cm, cubic in., cubic ft., and improvised units."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.C.5.",
    "Description": "Relate volume to the operations of multiplication and addition and solve real world and mathematical problems involving volume."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.C.5a.",
    "Description": "Find the volume of a right rectangular prism with whole-number side lengths by packing it with unit cubes, and show that the volume is the same as would be found by multiplying the edge lengths, equivalently by multiplying the height by the area of the base."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.C.5b.",
    "Description": "Apply the formulas V = (l)(w)(h) and V = (B)(h) for rectangular prisms to find volumes of right rectangular prisms with whole-number edge lengths in the context of solving real world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.C.5c.",
    "Description": "Recognize volume as additive. Find volumes of solid figures composed of two non-overlapping right rectangular prisms by adding the volumes of the non-overlapping parts, applying this technique to solve real world problems."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.A.1.",
    "Description": "Recognize that in a multi-digit number, a digit in one place represents 10 times as much as it represents in the place to its right and 44936 of what it represents in the place to its left."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.A.2.",
    "Description": "Use whole-number exponents to denote powers of 10 Explain patterns in the number of zeros of the product when multiplying a number by powers of 10, and explain patterns in the placement of the decimal point when a decimal is multiplied or divided by a power of 10"
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.A.3.",
    "Description": "Read, write, and compare decimals to thousandths."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.A.3a.",
    "Description": "Read and write decimals to thousandths using base-ten numerals, number names, and expanded form."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.A.3b.",
    "Description": "Compare two decimals to thousandths based on meanings of the digits in each place, using &gt;, =, and &lt; symbols to record the results of comparisons."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.A.4.",
    "Description": "Use place value understanding to round decimals to any place."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.B.5.",
    "Description": "Fluently multiply multi-digit whole numbers using a standard algorithm."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.B.6.",
    "Description": "Find whole-number quotients of whole numbers with up to four-digit dividends and two-digit divisors, using strategies based on place value, the properties of operations, and/or the relationship between multiplication and division. Illustrate and explain the calculation by using equations, rectangular arrays, and/or area models."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.B.7.",
    "Description": "Using concrete models or drawings and strategies based on place value, properties of operations, and/or the relationship between operations: add and subtract decimals to hundredths; multiply and divide decimals to hundredths. Relate the strategy to a written method and explain the reasoning used."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.A.1.",
    "Description": "Add and subtract fractions with unlike denominators (including mixed numbers) by replacing given fractions with equivalent fractions in such a way as to produce an equivalent sum or difference of fractions with like denominators."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.A.2.",
    "Description": "Solve word problems involving addition and subtraction of fractions referring to the same whole, including cases of unlike denominators. Use benchmark fractions and number sense of fractions to estimate mentally and assess the reasonableness of answers."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.3.",
    "Description": "Interpret a fraction as division of the numerator by the denominator (ݑ/ΰݑ = a σ b). Solve word problems involving division of whole numbers leading to answers in the form of fractions or mixed numbers."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.4.",
    "Description": "Apply and extend previous understandings of multiplication to multiply a fraction or whole number by a fraction."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.4a.",
    "Description": "Interpret the product (ݑ/ΰݑ) σ q as a parts of a partition of q into b equal parts; equivalently, as the result of a sequence of operations a ׃ q ׃ b."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.4b.",
    "Description": "Find the area of a rectangle with fractional side lengths by tiling it with rectangles of the appropriate unit fraction side lengths, and show that the area is the same as would be found by multiplying the side lengths. Multiply fractional side lengths to find areas of rectangles, and represent fraction products as rectangular areas."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.5.",
    "Description": "Interpret multiplication as scaling (resizing)."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.5a.",
    "Description": "Compare the size of a product to the size of one factor on the basis of the size of the other factor, without performing the indicated multiplication."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.5b.",
    "Description": "Explain why multiplying a given number by a fraction greater than 1 results in a product greater than the given number (recognizing multiplication by whole numbers greater than 1 as a familiar case); explain why multiplying a given number by a fraction less than 1 results in a product smaller than the given number; and relate the principle of fraction equivalence ݑ/ΰݑ = (ϰݑ ΃ װݑ)/(۰ݑ σ װݑ) to the effect of multiplying ۰ݑ/ΰݑ by 1"
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.6.",
    "Description": "Solve real world problems involving multiplication of fractions and mixed numbers."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.7.",
    "Description": "Apply and extend previous understandings of division to divide unit fractions by whole numbers and whole numbers by unit fractions."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.7a.",
    "Description": "Interpret division of a unit fraction by a non-zero whole number, and compute such quotients."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.7b.",
    "Description": "Interpret division of a whole number by a unit fraction, and compute such quotients"
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.7c.",
    "Description": "Solve real-world problems involving division of unit fractions by non-zero whole numbers and division of whole numbers by unit fractions."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.OA.A.1.",
    "Description": "Apply the order of operations to evaluate numerical expressions."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.OA.A.2.",
    "Description": "Write simple expressions that record calculations with numbers, and interpret numerical expressions without evaluating them."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.OA.B.3.",
    "Description": "Generate two numerical patterns using two given rules. Identify apparent relationships between corresponding terms. Form ordered pairs consisting of corresponding terms from the two patterns, and graph the ordered pairs on a coordinate plane."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.G.A.",
    "Description": "Graph points on the coordinate plane to solve real-world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.G.B.",
    "Description": "Classify two-dimensional figures into categories based on their properties."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.A.",
    "Description": "Convert like measurement units within a given measurement system."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.B.",
    "Description": "Represent and interpret data."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.MD.C.",
    "Description": "Geometric measurement: understand concepts of volume and relate volume to multiplication and to addition."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.A.",
    "Description": "Understand the place value system."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NBT.B.",
    "Description": "Perform operations with multi-digit whole numbers and with decimals to hundredths."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.A.",
    "Description": "Use equivalent fractions as a strategy to add and subtract fractions."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.NF.B.",
    "Description": "Apply and extend previous understandings of multiplication and division to multiply and divide fractions."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.OA.A.",
    "Description": "Write and interpret numerical expressions."
  },
  {
    "Course": "Math",
    "Grade": 5,
    "Standard": "5.OA.B.",
    "Description": "Analyze patterns and relationships."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.A.1.",
    "Description": "Write and evaluate numerical expressions involving whole-number exponents."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.A.2.",
    "Description": "Write, read, and evaluate expressions in which letters stand for numbers."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.A.2a.",
    "Description": "Write expressions that record operations with numbers and with letters standing for numbers."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.A.2b.",
    "Description": "Identify parts of an expression using mathematical terms (term, coefficient, sum, difference, product, factor and quotient); view one or more parts of an expression as a single entity."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.A.2c.",
    "Description": "Evaluate expressions given specific values for their variables. Include expressions that arise from formulas in real-world problems. Perform arithmetic operations, including those involving whole-number exponents, in the conventional order. (Order of Operations)."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.A.3.",
    "Description": "Apply the properties of operations to generate equivalent expressions."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.A.4.",
    "Description": "Identify when two expressions are equivalent."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.B.5.",
    "Description": "Understand solving an equation or inequality as a process of answering a question: which values from a specified set, if any, make the equation or inequality true? Use substitution to determine whether a given number in a specified set makes an equation or inequality true."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.B.6.",
    "Description": "Use variables to represent numbers and write expressions when solving a real-world or mathematical problem. Understand that a variable can represent an unknown number, or, depending on the purpose at hand, any number in a specified set."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.B.7.",
    "Description": "Solve real-world and mathematical problems by writing and solving equations of the form x + p = q; x – p = q; px = q; and ݑ/Űݑ= q for cases in which p, q and x are all nonnegative rational numbers, pݢ? 0 and where x represents the unknown quantity."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.B.8.",
    "Description": "Write an inequality of the form x &gt; c, x ≥ c, x ≤ c or x &lt; c to represent a constraint or condition in a real-world or mathematical problem. Recognize that inequalities of these forms have infinitely many solutions; represent solutions of such inequalities on a number line."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.C.9.",
    "Description": "Use variables to represent two quantities in a real-world problem that change in relationship to one another. Given a verbal context and an equation, identify the dependent variable, in terms of the other quantity, thought of as the independent variable. Analyze the relationship between the dependent and independent variables using graphs and tables, and relate these to the equation."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.G.A.1.",
    "Description": "Find area of triangles, trapezoids, parallelograms, and other polygons by composing into rectangles or decomposing into triangles and quadrilaterals; apply these techniques in the context of solving real-world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.G.A.2.",
    "Description": "Find volumes of right rectangular prisms with fractional edge lengths in the context of solving real-world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.G.A.3.",
    "Description": "Draw polygons in the coordinate plane given coordinates for the vertices. Use coordinates to find the length of a side joining points with the same first coordinate or the same second coordinate. Apply these techniques in the context of solving real-world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.G.A.4.",
    "Description": "Represent three-dimensional figures using nets made up of rectangles and triangles, and use the nets to find the surface area of these figures. Apply these techniques in the context of solving real-world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.G.A.5.",
    "Description": "Use area and volume models to explain perfect squares and perfect cubes."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.A.1.",
    "Description": "Interpret and compute quotients of fractions, and solve word problems involving division of fractions by fractions."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.B.2.",
    "Description": "Fluently divide multi-digit numbers using a standard algorithm."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.B.3.",
    "Description": "Fluently add, subtract, multiply, and divide multi-digit decimals using a standard algorithm for each operation."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.B.4.",
    "Description": "Find the greatest common factor of two whole numbers less than or equal to 100 Use the distributive property to express a sum of two whole numbers 1–100 with a common factor as a multiple of a sum of two whole numbers with no common factor other than 1 Find the least common multiple of two whole numbers less than or equal to 12"
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.5.",
    "Description": "Understand that positive and negative numbers are used together to describe quantities having opposite directions or values. Use positive and negative numbers to represent quantities in real-world contexts, explaining the meaning of 0 in each situation."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.6.",
    "Description": "Understand a rational number as a point on the number line. Use number lines and coordinate axes to represent points on a number line and in the coordinate plane with negative number coordinates."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.6a.",
    "Description": "Recognize opposite signs of numbers as indicating locations on opposite sides of 0 on the number line. Recognize that the opposite of the opposite of a number is the number itself."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.6b.",
    "Description": "Understand signs of numbers in ordered pairs as indicating locations in quadrants of the coordinate plane. Recognize that when two ordered pairs differ only by signs, the locations of the points are related by reflections across one or both axes."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.6c.",
    "Description": "Find and position integers and other rational numbers on a horizontal or vertical number line. Find and position pairs of integers and other rational numbers on a coordinate plane."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.7.",
    "Description": "Understand ordering and absolute value of rational numbers."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.7a.",
    "Description": "Interpret statements of inequality as statements about the relative position of two numbers on a number line diagram."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.7b.",
    "Description": "Write, interpret, and explain statements of order for rational numbers in real-world contexts."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.7c.",
    "Description": "Understand the absolute value of a rational number as its distance from 0 on the number line. Interpret absolute value as magnitude for a positive or negative quantity in a real-world situation."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.7d.",
    "Description": "Distinguish comparisons of absolute value from statements about order."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.8.",
    "Description": "Solve real-world and mathematical problems by graphing points in all four quadrants of the coordinate plane. Include use of coordinates and absolute value to find distances between points with the same first coordinate or the same second coordinate."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.RP.A.1.",
    "Description": "Understand the concept of a ratio and use ratio language to describe a ratio relationship between two quantities."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.RP.A.2.",
    "Description": "Understand the concept of a unit rate a/b associated with a ratio a:b with b ? 0 (b not equal to zero), and use rate language in the context of a ratio relationship."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.RP.A.3.",
    "Description": "Use ratio and rate reasoning to solve real-world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.RP.A.3a.",
    "Description": "Make tables of equivalent ratios relating quantities with whole-number measurements, find missing values in the tables, and plot the pairs of values on the coordinate plane. Use tables to compare ratios."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.RP.A.3b.",
    "Description": "Solve unit rate problems."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.RP.A.3c.",
    "Description": "Find a percent of a quantity as a rate per 100 Solve problems that involve finding the whole given a part and the percent, and finding a part of a whole given the percent."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.RP.A.3d.",
    "Description": "Use ratio reasoning to convert measurement units; manipulate and transform units appropriately when multiplying or dividing quantities."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.A.1a.",
    "Description": "Recognizing that a statistical question is one that anticipates variability in the data related to the question and accounts for it in the answers."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.A.1b.",
    "Description": "Understand that statistics can be used to gain information about a population by examining a sample of the population; generalizations about a population from a sample are valid only if the sample is representative of that population."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.A.1c.",
    "Description": "Develop the concept of sampling when collecting data (qualitative or quantitative) from a population and decide the best method to collect data for a particular question."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.A.2.",
    "Description": "Understand that a set of quantitative data collected to answer a statistical question has a distribution which can be described by its center, spread, and overall shape."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.A.3.",
    "Description": "Recognize that a measure of center for a quantitative data set summarizes all of its values with a single number while a measure of variation describes how its values vary with a single number."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.B.4.",
    "Description": "Display quantitative data in plots on a number line, including dot plots, and histograms."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.B.5.",
    "Description": "Summarize numerical data sets in relation to their context, such as by:"
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.B.5a.",
    "Description": "Reporting the number of observations."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.B.5b.",
    "Description": "Describing the nature of the attribute under investigation, including how it was measured and its units of measurement."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.B.5c.",
    "Description": "Calculate range and measures of center, as well as describe any overall pattern and any striking deviations from the overall pattern with reference to the context in which the data was gathered."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.B.5d.",
    "Description": "Relate the range and the choice of measures of center to the shape of the data distribution and the context in which the data was gathered."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.C.6.",
    "Description": "Understand that the probability of a chance event is a number between 0 and 1 inclusive, that expresses the likelihood of the event occurring. Larger numbers indicate greater likelihood. A probability near 0 indicates an unlikely event, a probability around 44928 indicates an event that is neither unlikely nor likely, and a probability near 1 indicates a likely event."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.C.7.",
    "Description": "Approximate the probability of a chance simple event by collecting data on the chance process that produces it and observing its long-run relative frequency, and predict the approximate relative frequency given the probability."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.C.8.",
    "Description": "Develop a probability model and use it to find probabilities of simple events. Compare probabilities from a model to observed frequencies; if the agreement is not good, explain possible sources of the discrepancy."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.C.8a.",
    "Description": "Develop a uniform probability model by assigning equal probability to all outcomes, and use the model to determine probabilities of simple events."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.C.8b.",
    "Description": "Develop a probability model (which may not be uniform) by observing frequencies in data generated from a chance process."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.A.",
    "Description": "Apply and extend previous understandings of arithmetic to algebraic expressions."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.B.",
    "Description": "Reason about and solve one-variable equations and inequalities."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.EE.C.",
    "Description": "Represent and analyze quantitative relationships between dependent and independent variables."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.G.A.",
    "Description": "Solve real-world and mathematical problems involving area, surface area, and volume."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.A.",
    "Description": "Apply and extend previous understandings of multiplication and division to divide fractions by fractions."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.B.",
    "Description": "Compute fluently with multi-digit numbers and find common factors and multiples."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.NS.C.",
    "Description": "Apply and extend previous understandings of numbers to the system of rational numbers."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.RP.A.",
    "Description": "Understand ratio concepts and use ratio reasoning to solve problems."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.A.",
    "Description": "Develop understanding of statistical variability."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.B.",
    "Description": "Summarize and descried distributions."
  },
  {
    "Course": "Math",
    "Grade": 6,
    "Standard": "6.SP.C.",
    "Description": "Investigate chance processes and develop, use and evaluate probability models."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.SP.C.8a.",
    "Description": "Understand that, just as with simple events, the probability of a compound event is the fraction of outcomes in the sample space for which the compound event occurs."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.SP.C.8b.",
    "Description": "Represent sample spaces for compound events using methods such as organized lists, sample space tables and tree diagrams. For an event described in everyday language, identify the outcomes in the sample space which compose the event."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.SP.C.8c.",
    "Description": "Design and use a simulation to generate frequencies for compound events."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.EE.A.1.",
    "Description": "Add, subtract, factor, and expand linear expressions with rational coefficients by applying the properties of operations."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.EE.A.2.",
    "Description": "Understand that rewriting an expression in different forms in real-world and mathematical problems can reveal and explain how the quantities are related."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.EE.B.3.",
    "Description": "Solve multi-step real-world and mathematical problems posed with positive and negative rational numbers in any form (whole numbers, fractions, and decimals), using tools strategically. Apply properties of operations to calculate with numbers in any form; convert between forms as appropriate. Assess the reasonableness of answers using mental computation and estimation strategies."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.EE.B.4.",
    "Description": "Use variables to represent quantities in a real-world or mathematical problem, and construct simple equations and inequalities to solve problems by reasoning about the quantities."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.EE.B.4a.",
    "Description": "Solve word problems leading to equations of the form px + q = r and p(x + q) = r, where p, q, and r are rational numbers and x represents the unknown quantity. Solve equations of these forms fluently. Compare an algebraic solution to an arithmetic solution, identifying the sequence of the operations used in each approach."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.EE.B.4b.",
    "Description": "Solve word problems leading to inequalities of the form px + q &gt; r, px + q ≥ r, px + q ≤ r, or px + q &lt; r, where p, q, and r are rational numbers and x represents the unknown quantity. Graph the solution set of the inequality on the number line and interpret it in the context of the problem."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.G.A.1.",
    "Description": "Solve problems involving scale drawings of geometric figures, including computing actual lengths and areas from a scale drawing and reproducing a scale drawing at a different scale."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.G.A.2.",
    "Description": "Draw triangles when given measures of angles and/or sides, noticing when the conditions determine a unique triangle, more than one triangle, or no triangle."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.G.A.3.",
    "Description": "Describe the two-dimensional shapes that result from slicing three-dimensional solids parallel or perpendicular to the base."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.G.B.4.",
    "Description": "Apply the formulas for the area and circumference of a circle to solve problems."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.G.B.5.",
    "Description": "Use facts about supplementary, complementary, vertical, and adjacent angles in a multi-step problem to write and solve simple equations for an unknown angle in a figure."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.G.B.6.",
    "Description": "Solve real-world and mathematical problems involving area, volume and surface area of two- and three-dimensional objects composed of triangles, trapezoids, parallelograms, cubes, and right rectangular prisms."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.1.",
    "Description": "Apply and extend previous understandings of addition and subtraction to add and subtract rational numbers. Represent addition and subtraction on a horizontal or vertical number line."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.1a.",
    "Description": "Describe situations in which opposite quantities combine to make 0"
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.1b.",
    "Description": "Understand addition of rational numbers; p + q is the number located a distance |q| from p, in the positive or negative direction depending on whether q is positive or negative. Show that a number and its opposite have a sum of 0 (are additive inverses). Interpret sums of rational numbers by describing real-world contexts."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.1c.",
    "Description": "Understand subtraction of rational numbers as adding the additive inverse, p – q = p + (–q). Show that the distance between two rational numbers on the number line is the absolute value of their difference, and apply this principle in real-world contexts."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.1d.",
    "Description": "Apply properties of operations as strategies to add and subtract rational numbers."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.2.",
    "Description": "Apply and extend previous understandings of multiplication and division and of fractions to multiply and divide rational numbers."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.2a.",
    "Description": "Understand that multiplication is extended from fractions to rational numbers by requiring that operations continue to satisfy the properties of operations, particularly the distributive property, leading to products such as (–1)(–1) = 1 and the rules for multiplying signed numbers. Interpret products of rational numbers by describing real-world contexts."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.2b.",
    "Description": "Understand that integers can be divided, provided that the divisor is not zero, and every quotient of integers (with non-zero divisor) is a rational number. If p and q are integers, then -(p/q)=(-p)/q=p/(-q). Interpret quotients of rational numbers by describing real-world contexts."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.2c.",
    "Description": "Apply properties of operations as strategies to multiply and divide rational numbers."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.2d.",
    "Description": "Convert a fraction to a decimal using long division; know that the decimal form of a rational number terminates in 0s or eventually repeats."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.3.",
    "Description": "Solve real-world and mathematical problems involving the four operations with rational numbers."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.RP.A.1.",
    "Description": "Compute unit rates associated with ratios of fractions."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.RP.A.2.",
    "Description": "Recognize and represent proportional relationships between quantities."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.RP.A.2a.",
    "Description": "Decide whether two quantities are in a proportional relationship."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.RP.A.2b.",
    "Description": "Identify the constant of proportionality (unit rate) in tables, graphs, equations, diagrams, and verbal descriptions of proportional relationships."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.RP.A.2c.",
    "Description": "Represent a proportional relationship using an equation."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.RP.A.2d.",
    "Description": "Explain what a point (x, y) on the graph of a proportional relationship means in terms of the situation, with special attention to the points (0, 0) and (1, r) where r is the unit rate."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.RP.A.3.",
    "Description": "Use proportional relationships to solve multistep ratio and percent problems."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.SP.B.1.",
    "Description": "Construct and interpret box-plots, find the interquartile range and determine if a data point is an outlier."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.SP.B.3.",
    "Description": "Informally assess the degree of visual overlap of two quantitative data distributions."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.SP.B.4.",
    "Description": "Use measures of center and measures of variability for quantitative data from random samples or populations to draw informal comparative inferences about the populations."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.SP.C.8.",
    "Description": "Find probabilities of compound events using organized lists, sample space tables, tree diagrams, and simulation."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.EE.A.",
    "Description": "Use properties of operations to generate equivalent expressions."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.EE.B.",
    "Description": "Solve real-life and mathematical problems using numerical and algebraic expressions, equations and inequalities."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.G.A.",
    "Description": "Draw, construct and describe geometrical figures and describe the relationships between them."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.G.B.",
    "Description": "Solve real-life and mathematical problems involving angle measure, area, surface area and volume."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.NS.A.",
    "Description": "Apply and extend previous understandings of operations with fractions to add, subtract, multiply, and divide rational numbers."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.RP.A.",
    "Description": "Analyze proportional relationships and use them to solve real-world and mathematical problems."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.SP.B.",
    "Description": "Draw informal comparative inferences about two populations."
  },
  {
    "Course": "Math",
    "Grade": 7,
    "Standard": "7.SP.C.",
    "Description": "Investigate chance processes and develop, use and evaluate probability models."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.A.1.",
    "Description": "Know and apply the properties of integer exponents to generate equivalent numerical expressions. For example, 3^2 × 3^(–5) = 3^(–3) = 1/(3^3) = 1/27."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.A.2.",
    "Description": "Use square root and cube root symbols to represent solutions to equations of the form x^2 = p and x^3 = p, where p is a positive rational number. Know square roots of perfect squares up to 225 and cube roots of perfect cubes up to 125 Know that the square root of a non-perfect square is irrational."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.A.3.",
    "Description": "Use numbers expressed in the form of a single digit times an integer power of 10 to estimate very large or very small quantities, and to express how many times as much one is than the other."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.A.4.",
    "Description": "Perform multiplication and division with numbers expressed in scientific notation, including problems where both standard decimal form and scientific notation are used. Use scientific notation and choose units of appropriate size for measurements of very large or very small quantities. Interpret scientific notation that has been generated by technology."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.B.5.",
    "Description": "Graph proportional relationships, interpreting the unit rate as the slope of the graph. Compare two different proportional relationships represented in different ways."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.B.6.",
    "Description": "Use similar triangles to explain why the slope m is the same between any two distinct points on a non-vertical line in the coordinate plane; derive the equation y=mx for a line through the origin and the equations y=mx+b for a line intercepting the vertical axis at b."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.C.7.",
    "Description": "Solve linear equations in one variable."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.C.7a.",
    "Description": "Recognize when linear equations in one variable have one solution, infinitely many solutions, or no solutions. Give examples and show which of these possibilities the case is by successively transforming the given equation into simpler forms."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.C.7b.",
    "Description": "Solve linear equations with rational number coefficients, including equations whose solutions require expanding expressions using the distributive property and combining like terms."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.C.8.",
    "Description": "Analyze and solve pairs of simultaneous linear equations."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.C.8a.",
    "Description": "Understand that solutions to a system of two linear equations in two variables correspond to points of intersection of their graphs, because points of intersection satisfy both equations simultaneously. Recognize when the system has one solution, no solution, or infinitely many solutions."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.C.8b.",
    "Description": "Solve systems of two linear equations in two variables with integer coefficients: graphically, numerically using a table, and algebraically. Solve simple cases by inspection."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.C.8c.",
    "Description": "Solve real-world and mathematical problems involving systems of two linear equations in two variables with integer coefficients."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.F.A.1.",
    "Description": "Understand that a function is a rule that assigns to each input exactly one output. The graph of a function is the set of ordered pairs consisting of an input and the corresponding output."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.F.A.2.",
    "Description": "Compare properties of two functions each represented in a different way (algebraically, graphically, numerically in tables, or by verbal descriptions)."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.F.A.3.",
    "Description": "Interpret the equation y = mx + b as defining a linear function, whose graph is a straight line. Recognize examples of functions that are linear and non-linear."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.F.B.4.",
    "Description": "Construct a function to model a linear relationship between two quantities. Determine the rate of change and initial value of the function from a description of a relationship or from two (x, y) values, including reading these from a table or from a graph. Interpret the rate of change and initial value of a linear function in terms of the situation it models, and in terms of its graph or a table of values."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.F.B.5.",
    "Description": "Describe qualitatively the functional relationship between two quantities by analyzing a graph. Sketch a graph that exhibits the qualitative features of a function that has been described in a real-world context."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.A.1.",
    "Description": "Verify experimentally the properties of rotations, reflections, and translations."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.A.1a.",
    "Description": "Verify experimentally lines are mapped to lines, and line segments to line segments of the same length."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.A.1b.",
    "Description": "Verify experimentally angles are mapped to angles of the same measure."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.A.1c.",
    "Description": "Verify experimentally parallel lines are mapped to parallel lines."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.A.2.",
    "Description": "Know that a two-dimensional figure is congruent to another if the corresponding angles are congruent and the corresponding sides are congruent. Equivalently, two two-dimensional figures are congruent if one is the image of the other after a sequence of rotations, reflections, and translations. Given two congruent figures, describe a sequence that maps the congruence between them on the coordinate plane."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.A.3.",
    "Description": "Describe the effect of dilations, translations, rotations and reflections on two-dimensional figures using coordinates."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.A.4.",
    "Description": "Know that a two-dimensional figure is similar to another if the corresponding angles are congruent and the corresponding sides are in proportion. Equivalently, two two-dimensional figures are similar if one is the image of the other after a sequence of rotations, reflections, translations, and dilations. Given two similar two-dimensional figures, describe a sequence that maps the similarity between them on the coordinate plane."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.A.5.",
    "Description": "Use informal arguments to establish facts about the angle sum and exterior angle of triangles, about the angles created when parallel lines are cut by a transversal, and the angle-angle criterion for similarity of triangles."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.B.6.",
    "Description": "Understand a proof of the Pythagorean Theorem and its converse."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.B.7.",
    "Description": "Apply the Pythagorean Theorem to determine unknown side lengths in right triangles in real-world and mathematical problems in two and three dimensions."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.B.8.",
    "Description": "Apply the Pythagorean Theorem to find the distance between two points in a coordinate system."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.C.9.",
    "Description": "Solve problems, mathematical and real world, which use the formulas for the volume of cones, cylinders, and spheres."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.NS.A.1.",
    "Description": "Understand informally that every number has a decimal expansion; the rational numbers are those with decimal expansions that terminate in 0s or eventually repeat. Know that other numbers that are not rational are called irrational."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.NS.A.2.",
    "Description": "Use rational approximations of irrational numbers to compare the size of irrational numbers, locate them approximately on a number line, and estimate the value of expressions."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.SP.A.1.",
    "Description": "Construct and interpret scatter plots for bivariate measurement data to investigate patterns of association between two quantities. Describe patterns such as clustering, outliers, positive or negative association, linear association, and nonlinear association."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.SP.A.2.",
    "Description": "Understand that straight lines are widely used to model relationships between two quantitative variables. For scatter plots that suggest a linear association, informally fit a straight line, and informally assess the model fit by judging the closeness of the data points to the line."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.SP.A.3.",
    "Description": "Use the equation of a linear model to solve problems in the context of bivariate measurement data, interpreting the slope and intercept."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.A.",
    "Description": "Work with radicals and integer exponents."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.B.",
    "Description": "Understand the connections between proportional relationships, lines, and linear equations."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.EE.C.",
    "Description": "Analyze and solve linear equations and pairs of simultaneous linear equations."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.F.A.",
    "Description": "Define, evaluate, and compare functions."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.F.B.",
    "Description": "Use functions to model relationships between quantities."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.A.",
    "Description": "Understand congruence and similarity using physical models, transparencies, or geometry software."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.B.",
    "Description": "Understand and apply the Pythagorean Theorem."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.G.C.",
    "Description": "Solve real-world and mathematical problems involving volume of cylinders, cones, and spheres."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.NS.A.",
    "Description": "Know that there are numbers that are not rational and approximate them by rational numbers."
  },
  {
    "Course": "Math",
    "Grade": 8,
    "Standard": "8.SP.A.",
    "Description": "Investigate patterns of association in bivariate data."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-APR.A.",
    "Description": "Perform arithmetic operations on polynomials."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-APR.A.1.",
    "Description": "Add, subtract, and multiply polynomials and recognize that the result of the operation is also a polynomial. This forms a system analogous to the integers."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-APR.B.",
    "Description": "Understand the relationship between zeros and factors of polynomials."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-APR.B.3.",
    "Description": "Identify zeros of polynomials when suitable factorizations are available. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-CED.A.",
    "Description": "Create equations that describe numbers or relationships."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-CED.A.1.",
    "Description": "Create equations and inequalities in one variable to represent a real-world context. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-CED.A.2.",
    "Description": "Create equations and linear inequalities in two variables to represent a real-world context."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-CED.A.3.",
    "Description": "Represent constraints by equations or inequalities, and by systems of equations and/or inequalities, and interpret solutions as viable or non-viable options in a modeling context."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-CED.A.4.",
    "Description": "Rewrite formulas to highlight a quantity of interest, using the same reasoning as in solving equations."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.A.",
    "Description": "Understand solving equations as a process of reasoning and explain the reasoning."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.A.1a.",
    "Description": "Explain each step when solving a linear or quadratic equation as following from the equality of numbers asserted at the previous step, starting from the assumption that the original equation has a solution. Construct a viable argument to justify a solution method. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.B.",
    "Description": "Solve equations and inequalities in one variable."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.B.3.",
    "Description": "Solve linear equations and inequalities in one variable, including equations with coefficients represented by letters."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.B.4.",
    "Description": "Solve quadratic equations in one variable."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.B.4a.",
    "Description": "Use the method of completing the square to transform any quadratic equation in x into an equation of the form (x-p)2 = q that has the same solutions. Understand that the quadratic formula is a derivative of this process."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.B.4b.",
    "Description": "Solve quadratic equations by: i) inspection; ii) taking square roots; iii) factoring; iv) completing the square; v) the quadratic formula; vi) graphing and recognizing when the process yields no real solutions. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.C.",
    "Description": "Solve systems of equations."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.C.6a.",
    "Description": "Solve systems of linear equations in two variables both algebraically and graphically. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A.REI.C.7a.",
    "Description": "Solve a system, with rational solutions, consisting of a linear equation and a quadratic equation (parabolas only) in two variables both algebraically and graphically."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.D.",
    "Description": "Represent and solve equations and inequalities graphically."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.D.10.",
    "Description": "Understand that the graph of an equation in two variables is the set of all its solutions plotted in the coordinate plane."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.D.11.",
    "Description": "Given the equations y=f(x) and y=g(x): i) recognize that each x-coordinate of the intersection(s) is the solution to the equation f(x)=g(x); and ii) find the solutions approximately using technology to graph the functions or make tables of values; and iii) interpret the solution in context. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-REI.D.12.",
    "Description": "Graph the solutions to a linear inequality in two variables as a half-plane (excluding the boundary in the case of a strict inequality), and graph the solution set to a system of linear inequalities in two variables as the intersection of the corresponding half-planes."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-SSE.A.",
    "Description": "Interpret the structure of expressions."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-SSE.A.1.",
    "Description": "Interpret expressions that represent a quantity in terms of its context."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-SSE.A.1a.",
    "Description": "Write the standard form of a given polynomial and identify the terms, coefficients, degree, leading coefficient, and constant term."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-SSE.A.1b.",
    "Description": "Interpret expressions by viewing one or more of their parts as a single entity."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-SSE.A.2.",
    "Description": "Recognize and use the structure of an expression to identify ways to rewrite it. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-SSE.B.",
    "Description": "Write expressions in equivalent forms to reveal their characteristics."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-SSE.B.3.",
    "Description": "Choose and produce an equivalent form of an expression to reveal and explain properties of the quantity represented by the expression."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "A-SSE.B.3c.",
    "Description": "Use the properties of exponents to rewrite exponential expressions. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-BF.A.",
    "Description": "Build a function that models a relationship between two quantities."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-BF.A.1.",
    "Description": "Write a function that describes a relationship between two quantities."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-BF.A.1a.",
    "Description": "Determine a function from context. Define a sequence explicitly or steps for calculation from a context. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-BF.B.",
    "Description": "Build new functions from existing functions."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-BF.B.3a.",
    "Description": "Using f(x) + k, k f(x), and f(x + k): i) Identify the effect on the graph when replacing f(x) by f(x) + k, k f(x), and f(x + k) for specific values of k (both positive and negative); ii) Find the value of k given the graphs; iii) Write a new function using the value of k; and iv) Use technology to experiment with cases and explore the effects on the graph. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.A.",
    "Description": "Understand the concept of a function and use function notation."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.A.1.",
    "Description": "Understand that a function from one set (called the domain) to another set (called the range) assigns to each element of the domain exactly one element of the range. If f is a function and x is an element of its domain, then f(x) denotes the output of f corresponding to the input x. The graph of f is the graph of the equation y = f(x)."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.A.2.",
    "Description": "Use function notation, evaluate functions for inputs in their domains, and interpret statements that use function notation in terms of a context."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.A.3.",
    "Description": "Recognize that a sequence is a function whose domain is a subset of the integers. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.B.",
    "Description": "Interpret functions that arise in applications in terms of the context."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.B.4a.",
    "Description": "For a function that models a relationship between two quantities: i) interpret key features of graphs and tables in terms of the quantities; and ii) sketch graphs showing key features given a verbal description of the relationship. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.B.5.",
    "Description": "Determine the domain of a function from its graph and, where applicable, identify the appropriate domain for a function in context."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.B.6.",
    "Description": "Calculate and interpret the average rate of change of a function presented over a specified interval. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.C.",
    "Description": "Analyze functions using different representations."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.C.7.",
    "Description": "Graph functions and show key features of the graph by hand and by using technology where appropriate."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.C.7a.",
    "Description": "Graph linear, quadratic and exponential functions and show key features."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.C.7b.",
    "Description": "Graph square root, and piecewise-defined functions, including step functions and absolute value functions and show key features."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.C.8.",
    "Description": "Write a function in different but equivalent forms to reveal and explain different properties of the function."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.C.8a.",
    "Description": "For a quadratic function, use an algebraic process to find zeros, maxima, minima, and symmetry of the graph, and interpret these in terms of context."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-IF.C.9.",
    "Description": "Compare properties of two functions each represented in a different way (algebraically, graphically, numerically in tables, or by verbal descriptions). (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-LE.A.",
    "Description": "Construct and compare linear, quadratic, and exponential models and solve problems."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-LE.A.1.",
    "Description": "Distinguish between situations that can be modeled with linear functions and with exponential functions."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-LE.A.1a.",
    "Description": "Justify that a function is linear because it grows by equal differences over equal intervals, and that a function is exponential because it grows by equal factors over equal intervals."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-LE.A.1b.",
    "Description": "Recognize situations in which one quantity changes at a constant rate per unit interval relative to another, and therefore can be modeled linearly."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-LE.A.1c.",
    "Description": "Recognize situations in which a quantity grows or decays by a constant percent rate per unit interval relative to another, and therefore can be modeled exponentially."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-LE.A.2.",
    "Description": "Construct a linear or exponential function symbolically given: i) a graph; ii) a description of the relationship; iii) two input-output pairs (include reading these from a table). (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-LE.A.3.",
    "Description": "Observe using graphs and tables that a quantity increasing exponentially eventually exceeds a quantity increasing linearly, quadratically, or (more generally) as a polynomial function."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-LE.B.",
    "Description": "Interpret expressions for functions in terms of the situation they model."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "F-LE.B.5.",
    "Description": "Interpret the parameters in a linear or exponential function in terms of a context. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "N-Q.A.",
    "Description": "Reason quantitatively and use units to solve problems."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "N-Q.A.1.",
    "Description": "Select quantities and use units as a way to: i) interpret and guide the solution of multi-step problems; ii) choose and interpret units consistently in formulas; and iii) choose and interpret the scale and the origin in graphs and data displays."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "N-Q.A.3.",
    "Description": "Choose a level of accuracy appropriate to limitations on measurement and context when reporting quantities."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "N-RN.B.",
    "Description": "Use properties of rational and irrational numbers."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "N-RN.B.3.",
    "Description": "Use properties and operations to understand the different forms of rational and irrational numbers."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "N-RN.B.3a.",
    "Description": "Perform all four arithmetic operations and apply properties to generate equivalent forms of rational numbers and square roots."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "N-RN.B.3b.",
    "Description": "Categorize the sum or product of rational or irrational numbers: The sum and product of two rational numbers is rational; The sum of a rational number and an irrational number is irrational; The product of a nonzero rational number and an irrational number is irrational; The sum and product of two irrational numbers could be either rational or irrational."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.A.",
    "Description": "Summarize, represent, and interpret data on a single count or measurement variable."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.A.1.",
    "Description": "Represent data with plots on the real number line (dot plots, histograms, and box plots)."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.A.2.",
    "Description": "Use statistics appropriate to the shape of the data distribution to compare center (median, mean) and spread (inter-quartile range, sample standard deviation) of two or more different data sets."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.A.3.",
    "Description": "Interpret differences in shape, center, and spread in the context of the data sets, accounting for possible effects of extreme data points (outliers)."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.B.",
    "Description": "Summarize, represent, and interpret data on two categorical and quantitative variables."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.B.5.",
    "Description": "Summarize categorical data for two categories in two-way frequency tables. Interpret relative frequencies in the context of the data (including joint, marginal, and conditional relative frequencies). Recognize possible associations and trends in the data."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.B.6.",
    "Description": "Represent bivariate data on a scatter plot, and describe how the variables’ values are related."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.B.6a.",
    "Description": "Fit a function to real-world data; use functions fitted to data to solve problems in the context of the data. (Shared standard with Algebra II)"
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.C.",
    "Description": "Interpret linear models."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.C.7.",
    "Description": "Interpret the slope (rate of change) and the intercept (constant term) of a linear model in the context of the data."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.C.8.",
    "Description": "Calculate (using technology) and interpret the correlation coefficient of a linear fit."
  },
  {
    "Course": "Math",
    "Grade": 9,
    "Standard": "S-ID.C.9.",
    "Description": "Distinguish between correlation and causation."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-C.A.",
    "Description": "Understand and apply theorems about circles."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-C.A.1.",
    "Description": "Prove that all circles are similar."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-C.A.2a.",
    "Description": "Identify, describe and apply relationships between the angles and their intercepted arcs of a circle."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-C.A.2b.",
    "Description": "Identify, describe and apply relationships among radii, chords, tangents, and secants of a circle."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.A.",
    "Description": "Experiment with transformations in the plane."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.A.1.",
    "Description": "Know precise definitions of angle, circle, perpendicular lines, parallel lines, and line segment, based on the undefined notions of point, line, distance along a line, and distance around a circular arc as these exist within a plane."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.A.2.",
    "Description": "Represent transformations as geometric functions that take points in the plane as inputs and give points as outputs. Compare transformations that preserve distance and angle measure to those that do not."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.A.3.",
    "Description": "Given a regular or irregular polygon, describe the rotations and reflections (symmetries) that map the polygon onto itself."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.A.4.",
    "Description": "Develop definitions of rotations, reflections, and translations in terms of points, angles, circles, perpendicular lines, parallel lines, and line segments."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.A.5.",
    "Description": "Given a geometric figure and a rotation, reflection, or translation, draw the transformed figure. Specify a sequence of transformations that will carry a given figure onto another."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.B.",
    "Description": "Understand congruence in terms of rigid motions."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.B.6.",
    "Description": "Use geometric descriptions of rigid motions to transform figures and to predict the effect of a given rigid motion on a given figure. Given two figures, use the definition of congruence in terms of rigid motions to decide if they are congruent."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.B.7.",
    "Description": "Use the definition of congruence in terms of rigid motions to show that two triangles are congruent if and only if corresponding pairs of sides and corresponding pairs of angles are congruent."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.B.8.",
    "Description": "Explain how the criteria for triangle congruence (ASA, SAS, SSS, AAS and HL (Hypotenuse Leg)) follow from the definition of congruence in terms of rigid motions."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.C.",
    "Description": "Prove geometric theorems."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.C.10.",
    "Description": "Prove and apply theorems about the properties triangles."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.C.11.",
    "Description": "Prove and apply theorems about parallelograms."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.C.9.",
    "Description": "Prove and apply theorems about lines and angles."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.D.",
    "Description": "Make geometric constructions."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.D.12.",
    "Description": "Make, justify and apply formal geometric constructions."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-CO.D.13.",
    "Description": "Make and justify the constructions for inscribing an equilateral triangle, a square and a regular hexagon in a circle."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GPE.A.",
    "Description": "Translate between the geometric description and the equation of a conic section."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GPE.A.1a.",
    "Description": "Derive the equation of a circle of given center and radius using the Pythagorean Theorem. Find the center and radius of a circle, given the equation of the circle."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GPE.A.1b.",
    "Description": "Graph circles given their equation."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GPE.B.",
    "Description": "Use coordinates to prove simple geometric theorems algebraically."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GPE.B.4.",
    "Description": "On the coordinate plane, algebraically prove geometric theorems and properties."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GPE.B.5.",
    "Description": "On the coordinate plane: Explore the proof for the relationship between slopes of parallel and perpendicular lines; Determine if lines are parallel, perpendicular, or neither, based on their slopes; and Apply properties of parallel and perpendicular lines to solve geometric problems."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GPE.B.6.",
    "Description": "Find the point on a directed line segment between two given points that partitions the segment in a given ratio."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GPE.B.7.",
    "Description": "Use coordinates to compute perimeters of polygons and areas of triangles and rectangles."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GMD.A.",
    "Description": "Explain volume formulas and use them to solve problems."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GMD.A.1.",
    "Description": "Provide informal arguments for the formulas for the circumference of a circle, area of a circle, volume of a cylinder, pyramid, and cone."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GMD.A.3.",
    "Description": "Use volume formulas for cylinders, pyramids, cones, and spheres to solve problems."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GMD.B.",
    "Description": "Visualize relationships between two-dimensional and three-dimensional objects."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-GMD.B.4.",
    "Description": "Identify the shapes of plane sections of three-dimensional objects, and identify three-dimensional objects generated by rotations of two-dimensional objects."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-MG.A.",
    "Description": "Apply geometric concepts in modeling situations."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-MG.A.1.",
    "Description": "Use geometric shapes, their measures, and their properties to describe objects."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-MG.A.2.",
    "Description": "Apply concepts of density based on area and volume of geometric figures in modeling situations."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-MG.A.3.",
    "Description": "Apply geometric methods to solve design problems."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.A.",
    "Description": "Understand similarity in terms of similarity transformations."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.A.1.",
    "Description": "Verify experimentally the properties of dilations given by a center and a scale factor."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.A.1a.",
    "Description": "Verify experimentally that dilation takes a line not passing through the center of the dilation to a parallel line, and leaves a line passing through the center unchanged."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.A.1b.",
    "Description": "Verify experimentally that the dilation of a line segment is longer or shorter in the ratio given by the scale factor."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.A.2.",
    "Description": "Given two figures, use the definition of similarity in terms of similarity transformations to decide if they are similar. Explain using similarity transformations that similar triangles have equality of all corresponding pairs of angles and the proportionality of all corresponding pairs of sides."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.A.3.",
    "Description": "Use the properties of similarity transformations to establish the AA~, SSS~, and SAS~ criterion for two triangles to be similar."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.B.",
    "Description": "Prove theorems involving similarity."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.B.4.",
    "Description": "Prove and apply similarity theorems about triangles."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.B.5.",
    "Description": "Use congruence and similarity criteria for triangles to: Solve problems algebraically and geometrically. Prove relationships in geometric figures."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.C.",
    "Description": "Define trigonometric ratios and solve problems involving right triangles."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.C.6.",
    "Description": "Understand that by similarity, side ratios in right triangles are properties of the angles in the triangle, leading to definitions of sine, cosine and tangent ratios for acute angles."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.C.7.",
    "Description": "Explain and use the relationship between the sine and cosine of complementary angles."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.C.8.",
    "Description": "Use sine, cosine, tangent, the Pythagorean Theorem and properties of special right triangles to solve right triangles in applied problems."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.D.",
    "Description": "Apply Trigonometry to general triangles."
  },
  {
    "Course": "Math",
    "Grade": 10,
    "Standard": "G-SRT.D.9.",
    "Description": "Justify and apply the formula A=(1/2)ab sin (C) to find the area of any triangle by drawing an auxiliary line from a vertex perpendicular to the opposite side."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-APR.B.",
    "Description": "Understand the relationship between zeros and factors of polynomials."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-APR.B.2.",
    "Description": "Apply the Remainder Theorem: For a polynomial p(x) and a number a, the remainder on division by x – a is p(a), so p(a) = 0 if and only if (x – a) is a factor of p(x)."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-APR.B.3.",
    "Description": "Identify zeros of polynomials when suitable factorizations are available. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-APR.C.",
    "Description": "Rewrite rational expressions."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-APR.D.6.",
    "Description": "Rewrite rational expressions in different forms: Write a(x)/b(x) in the form q(x) + r(x)/b(x), where a(x), b(x), q(x), and r(x) are polynomials with the degree of r(x) less than the degree of b(x)."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-CED.A.",
    "Description": "Create equations that describe numbers or relationships."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-CED.A.1.",
    "Description": "Create equations and inequalities in one variable to represent a real-world context. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.A.",
    "Description": "Understand solving equations as a process of reasoning and explain the reasoning."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.A.1b.",
    "Description": "Explain each step when solving rational or radical equations as following from the equality of numbers asserted at the previous step, starting from the assumption that the original equation has a solution. Construct a viable argument to justify a solution method. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.A.2.",
    "Description": "Solve rational and radical equations in one variable, identify extraneous solutions, and explain how they arise."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.B.",
    "Description": "Solve equations and inequalities in one variable."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.B.4.",
    "Description": "Solve quadratic equations in one variable. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.B.4b.",
    "Description": "Solve quadratic equations by: i) inspection; ii) taking square roots; iii) factoring; iv) completing the square; v) the quadratic formula and vi) graphing. Write complex solutions in a+bi form. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.C.",
    "Description": "Solve systems of equations."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.C.7b.",
    "Description": "Solve a system consisting of a linear equation and a quadratic equation in two variables algebraically and graphically. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.D.",
    "Description": "Represent and solve equations and inequalities graphically."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-REI.D.11.",
    "Description": "Given the equations y=f(x) and y=g(x): i) recognize that each x-coordinate of the intersection(s) is the solution to the equation f(x)=g(x); and ii) find the solutions approximately using technology to graph the functions or make tables of values; and iii) interpret the solution in context. iv.) find the solution of f(x)&lt;g(x) or f(x)≤g(x) graphically (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-SSE.A.",
    "Description": "Interpret the structure of expressions."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-SSE.A.2.",
    "Description": "Recognize and use the structure of an expression to identify ways to rewrite it. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-SSE.B.",
    "Description": "Write expressions in equivalent forms to reveal their characteristics."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-SSE.B.3.",
    "Description": "Choose and produce an equivalent form of an expression to reveal and explain properties of the quantity represented by the expression. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-SSE.B.3a.",
    "Description": "Factor quadratic expressions including leading coefficients other than 1 to reveal the zeros of the function it defines."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "A-SSE.B.3c.",
    "Description": "Use the properties of exponents to rewrite exponential expressions. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.A.",
    "Description": "Build a function that models a relationship between two quantities."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.A.1.",
    "Description": "Write a function that describes a relationship between two quantities. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.A.1a.",
    "Description": "Determine a function from context. Determine an explicit expression, a recursive process, or steps for calculation from a context. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.A.1b.",
    "Description": "Combine standard function types using arithmetic operations."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.A.2.",
    "Description": "Write arithmetic and geometric sequences both recursively and with an explicit formula, use them to model situations, and translate between the two forms."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.B.",
    "Description": "Build new functions from existing functions."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.B.3b.",
    "Description": "Using f(x) + k, k f(x), f(kx) and f(x + k): i) Identify the effect on the graph when replacing f(x) by f(x) + k, k f(x), f(kx) and f(x + k) for specific values of k (both positive and negative); ii) Find the value of k given the graphs; iii) Write a new function using the value of k; and iv) Use technology to experiment with cases and explore the effects on the graph. Include recognizing even and odd functions from their graphs. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.B.4.",
    "Description": "Find inverse functions."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.B.4a.",
    "Description": "Find the inverse of a one-to-one function both algebraically and graphically."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.B.6.",
    "Description": "Represent and evaluate the sum of a finite arithmetic or finite geometric series, using summation (sigma) notation."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-BF.B.7.",
    "Description": "Explore the derivation of the formulas for finite arithmetic and finite geometric series. Use the formulas to solve problems."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F.BF.B.5a.",
    "Description": "Understand inverse relationships between exponents and logarithms algebraically and graphically."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.A.",
    "Description": "Understand the concept of a function and use function notation."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.A.3.",
    "Description": "Recognize that a sequence is a function whose domain is a subset of the integers. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.B.",
    "Description": "Interpret functions that arise in applications in terms of the context."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.B.4b.",
    "Description": "For a function that models a relationship between two quantities: i) interpret key features of graphs and tables in terms of the quantities; and ii) sketch graphs showing key features given a verbal description of the relationship. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.B.6b.",
    "Description": "Calculate and interpret the average rate of change of a function over a specified interval. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.C.",
    "Description": "Analyze functions using different representations."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.C.7.",
    "Description": "Graph functions and show key features of the graph by hand and using technology when appropriate."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.C.7c.",
    "Description": "Graph polynomial functions, identifying zeros when suitable factorizations are available, and showing end behavior."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.C.7e.",
    "Description": "Graph cube root, exponential and logarithmic functions, showing intercepts and end behavior; and trigonometric functions, showing period, midline, and amplitude."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.C.8.",
    "Description": "Write a function in different but equivalent forms to reveal and explain different properties of the function. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.C.8b.",
    "Description": "Use the properties of exponents to interpret exponential functions, and classify them as representing exponential growth or decay."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-IF.C.9.",
    "Description": "Compare properties of two functions each represented in a different way (algebraically, graphically, numerically in tables, or by verbal descriptions). (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-LE.A.",
    "Description": "Construct and compare linear, quadratic, and exponential models and solve problems."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-LE.A.2.",
    "Description": "Construct a linear or exponential function symbolically given: i) a graph; ii) a description of the relationship; iii) two input-output pairs (include reading these from a table). (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-LE.A.4.",
    "Description": "Use logarithms to solve exponential equations, such as abct = d (where a, b, c, and d are real numbers and b &gt; 0) and evaluate the logarithm using technology."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-LE.B.",
    "Description": "Interpret expressions for functions in terms of the situation they model."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-LE.B.5.",
    "Description": "Interpret the parameters in a linear or exponential function in terms of a context. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-TF.A.",
    "Description": "Extend the domain of trigonometric functions using the unit circle."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-TF.A.1a.",
    "Description": "Understand radian measure of an angle as the length of the arc on the unit circle subtended by the angle."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-TF.A.1b.",
    "Description": "Using proportionality, find one of the following given two others; the central angle, arc length, radius or area of sector."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-TF.A.2.",
    "Description": "Apply concepts of the unit circle in the coordinate plane to calculate the values of the six trigonometric functions given angles in radian measure."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-TF.A.4.",
    "Description": "Use the unit circle to explain symmetry (odd and even) and periodicity of trigonometric functions."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-TF.B.",
    "Description": "Model periodic phenomena with trigonometric functions."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-TF.B.5.",
    "Description": "Choose trigonometric functions to model periodic phenomena with specified amplitude, frequency, phase shift, and midline."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-TF.C.",
    "Description": "Prove and apply trigonometric identities."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "F-TF.C.8.",
    "Description": "Prove the Pythagorean identity sin^2(θ) + cos^2(θ) = 1 Find the value of any of the six trigonometric functions given any other trigonometric function value and when necessary find the quadrant of the angle."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "N-CN.A.",
    "Description": "Perform arithmetic operations with complex numbers."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "N-CN.A.1.",
    "Description": "Know there is a complex number i such that i^2 = –1, and every complex number has the form a + bi with a and b real."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "N-CN.A.2.",
    "Description": "Use the relation i^2 = –1 and the commutative, associative, and distributive properties to add, subtract, and multiply complex numbers."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "N-RN.A.",
    "Description": "Extend the properties of exponents to rational exponents."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "N-RN.A.1.",
    "Description": "Explore how the meaning of rational exponents follows from extending the properties of integer exponents."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "N-RN.A.2.",
    "Description": "Convert between radical expressions and expressions with rational exponents using the properties of exponents."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-CP.A.",
    "Description": "Understand independence and conditional probability and use them to interpret data."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-CP.A.1.",
    "Description": "Describe events as subsets of a sample space (the set of outcomes) using characteristics (or categories) of the outcomes, or as unions, intersections, or complements of other events (“or,” “and,” “not”)."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-CP.A.4.",
    "Description": "Interpret two-way frequency tables of data when two categories are associated with each object being classified. Use the two-way table as a sample space to decide if events are independent and calculate conditional probabilities."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-CP.B.",
    "Description": "Use the rules of probability to compute probabilities of compound events in a uniform probability model."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-CP.B.7.",
    "Description": "Apply the Addition Rule, P (A or B) = P(A) + P(B) – P (A and B), and interpret the answer in terms of the model."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-ID.A.",
    "Description": "Summarize, represent, and interpret data on a single count or measurement variable."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-ID.A.4a.",
    "Description": "Recognize whether or not a normal curve is appropriate for a given data set."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-ID.A.4b.",
    "Description": "If appropriate, determine population percentages using a graphing calculator for an appropriate normal curve."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-ID.B.",
    "Description": "Summarize, represent, and interpret data on two categorical and quantitative variables."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-ID.B.6.",
    "Description": "Represent bivariate data on a scatter plot, and describe how the variables’ values are related."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-ID.B.6a.",
    "Description": "Fit a function to real-world data; use functions fitted to data to solve problems in the context of the data. (Shared standard with Algebra I)"
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-IC.A.",
    "Description": "Understand and evaluate random processes underlying statistical experiments."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-IC.A.2.",
    "Description": "Determine if a value for a sample proportion or sample mean is likely to occur based on a given simulation."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-IC.B.",
    "Description": "Make inferences and justify conclusions from sample surveys, experiments, and observational studies."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-IC.B.3.",
    "Description": "Recognize the purposes of and differences among surveys, experiments, and observational studies. Explain how randomization relates to each."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-IC.B.4.",
    "Description": "Given a simulation model based on a sample proportion or mean, construct the 0.95 interval centered on the statistic (+/- two standard deviations) and determine if a suggested parameter is plausible."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-IC.B.6a.",
    "Description": "Use the tools of statistics to draw conclusions from numerical summaries."
  },
  {
    "Course": "Math",
    "Grade": 11,
    "Standard": "S-IC.B.6b.",
    "Description": "Use the language of statistics to critique claims from informational texts. For example, causation vs correlation, bias, measures of center and spread."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1A1: Given a function f, the limit of f(x) as x approaches c is a real number R if f(x) can be made arbitrarily close to R by taking x sufficiently close c to (but not equal to c). If the limit exists and is a real number, then the common notation is lim(x→c) f(x) = R."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1A2: The concept of a limit can be extended to include one-sided limits, limits at infinity, and infinite limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1A3: A limit might not exist for some functions at particular values of x. Some ways that the limit might not exist are if the function is unbounded, if the function is oscillating near this value, or if the limit from the left does not equal the limit from the right."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1B1: Numerical and graphical information can be used to estimate limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1C1: Limits of sums, differences, products, quotients, and composite functions can be found using the basic theorems of limits and algebraic rules."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1C2: The limit of a function may be found by using algebraic manipulation, alternate forms of trigonometric functions, or the squeeze theorem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1C3: Limits of the indeterminate forms 0/0 and ∞/∞ may be evaluated using L’Hospital’s Rule."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1D1: Asymptotic and unbounded behavior of functions can be explained and described using limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1D2: Relative magnitudes of functions and their rates of change can be compared using limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.2A1: A function f is continuous at x = c provided that f(c) exists, lim(x→c) f(x) exists, and lim(x→c) f(x) = f(c)."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.2A2: Polynomial, rational, power, exponential, logarithmic, and trigonometric functions are continuous at all points in their domains."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.2A3: Types of discontinuities include removable discontinuities, jump discontinuities, and discontinuities due to vertical asymptotes."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.2B1: Continuity is an essential condition for theorems such as the Intermediate Value Theorem, the Extreme Value Theorem, and the Mean Value Theorem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C1: Direct application of the definition of the derivative can be used to find the derivative for selected functions, including polynomial, power, sine, cosine, exponential, and logarithmic functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C2: Specific rules can be used to calculate derivatives for classes of functions, including polynomial, rational, power, exponential, logarithmic, trigonometric, and inverse trigonometric."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C3: Sums, differences, products, and quotients of functions can be differentiated using derivative rules."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C4: The chain rule provides a way to differentiate composite functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C5: The chain rule is the basis for implicit differentiation."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C6: The chain rule can be used to find the derivative of an inverse function, provided the derivative of that function exists."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1D1: Differentiating f’ produces the second derivative f’’, provided the derivative of f’ exists; repeating this process produces higher order derivatives of f."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1D2: Higher order derivatives are represented with a variety of notations. For y=f(x), notations for the second derivative include d^2y/dx^2, f’’(x) and y’’. Higher order derivatives can be denoted (d^n)y/dx^n or (f^n)x."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2A1: First and second derivatives of a function can provide information about the function and its graph including intervals of increase or decrease, local (relative) and global (absolute) extrema, intervals of upward or downward concavity, and points of inflection."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2A2: Key features of functions and their derivatives can be identified and related to their graphical, numerical, and analytical representations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2A3: Key features of the graphs of f, f’, and f’’ are related to one another."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2B1: A continuous function may fail to be differentiable at a point in its domain."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2B2: If a function is differentiable at a point, then it is continuous at that point."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3A1: The unit for f’(x) is the unit for f divided by the unit for x."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3A2: The derivative of a function can be interpreted as the instantaneous rate of change with respect to its independent variable."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3B1: The derivative at a point is the slope of the line tangent to a graph at that point on the graph."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3B2: The tangent line is the graph of a locally linear approximation of the function near the point of tangency."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3C1: The derivative can be used to solve rectilinear motion problems involving position, speed, velocity, and acceleration."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3C2: The derivative can be used to solve related rates problems, that is, finding a rate at which one quantity is changing by relating it to other quantities whose rates of change are known."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3C3: The derivative can be used to solve optimization problems, that is, finding a maximum or minimum value of a function over a given interval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3D1: The derivative can be used to express information about rates of change in applied contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3E1: Solutions to differential equations are functions or families of functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3E2: Derivatives can be used to verify that a function is a solution to a given differential equation."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3F1: Slope fields provide visual clues to the behavior of solutions to first order differential equations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.4A1: If a function f is continuous over the interval [a,b] and differentiable over the interval (a,b), the Mean Value Theorem guarantees a point within that open interval where the instantaneous rate of change equals the average rate of change over the interval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.1A1: An antiderivative of a function f is a function g whose derivative is f."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.1A2: Differentiation rules provide the foundation for finding antiderivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2A1: A Riemann sum, which requires a partition of an interval I, is the sum of products, each of which is the value of the function at a point in a subinterval multiplied by the length of that subinterval of the partition."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2A2: The definite integral of a continuous function f over the interval [a,b], denoted by int{a to b} f(x)dx, is the limit of Riemann sums as the widths of the subintervals approach 0 That is, int{a to b} f(x)dx= lim[maxΔxi→0] (n)Σ(i=1) f(xi*)Δxi where xi* is a value in the ith subinterval, Δxi is the width of the ith subinterval, n is the number of subintervals, and is the width of the largest subinterval. Another form of the definition is int{a to b} f(x)dx=lim[n→∞] (n)Σ(i=1) f(xi*)Δxi, where Δxi = (b-a)/n and xi* is a value in the ith subinterval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2A3: The information in a definite integral can be translated into the limit of a related Riemann sum, and the limit of a Riemann sum can be written as a definite integral."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2B1: Definite integrals can be approximated for functions that are represented graphically, numerically, algebraically, and verbally."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2B2: Definite integrals can be approximated using a left Riemann sum, a right Riemann sum, a midpoint Riemann sum, or a trapezoidal sum; approximations can be computed using either uniform or nonuniform partitions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2C1: In some cases, a definite integral can be evaluated by using geometry and the connection between the definite integral and area."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2C2: Properties of definite integrals include the integral of a constant times a function, the integral of the sum of two functions, reversal of limits of integration, and the integral of a function over adjacent intervals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2C3: The definition of the definite integral may be extended to functions with removable or jump discontinuities."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3A1: The definite integral can be used to define new functions; for example, f(x) = int{0 to x}(e^-t^2)dt."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3A2: If f is a continuous function on the interval [a,b], then (d/dx)(int{a to x}f(t)dt=f(x), where x is between a and b."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3A3: Graphical, numerical, analytical, and verbal representations of a function f provide information about the function defined as g(x)=int{a to x}f(t)dt."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B1: The function defined by F(x)=int{a to x}f(t)dt is an antiderivative of f."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B2: If f is continuous on the interval [a,b] and F is an antiderivative of f, then int{a to b} f(x)dx=F(b)-F(a)."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B3: The notation ∫f(x)dx=F(x)+C means that F’(x)=f(x), and ∫f(x)dx is called an indefinite integral of the function f."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B4: Many functions do not have closed form antiderivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B5: Techniques for finding antiderivatives include algebraic manipulation such as long division and completing the square, substitution of variables."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4A1: A function defined as an integral represents an accumulation of a rate of change."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4A2: The definite integral of the rate of change of a quantity over an interval gives the net change of that quantity over that interval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4A3: The limit of an approximating Riemann sum can be interpreted as a definite integral."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4B1: The average value of a function f over an interval [a,b] is (1/b-a)int{a to b}f(x)dx."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4C1: For a particle in rectilinear motion over an interval of time, the definite integral of velocity represents the particle’s displacement over the interval of time, and the definite integral of speed represents the particle’s total distance traveled over the interval of time."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4D1: Areas of certain regions in the plane can be calculated with definite integrals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4D2: Volumes of solids with known cross sections, including discs and washers, can be calculated with definite integrals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4E1: The definite integral can be used to express information about accumulation and net change in many applied contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5A1: Antidifferentiation can be used to find specific solutions to differential equations with given initial conditions, including applications to motion along a line, exponential growth and decay."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5A2: Some differential equations can be solved by separation of variables."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5A3: Solutions to differential equations may be subject to domain restrictions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5A4: The function F defined by F(x)=c+int{a to x}f(t)dt is a general solution to the differential equation dy/dx=f(x), and F(x) = y_0+int{a to x}f(t)dt is a particular solution to the differential equation dy/dx=f(x) satisfying F(a)=y_0."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5B1: The model for exponential growth and decay that arises from the statement “The rate of change of a quantity is proportional to the size of the quantity” is dy/dt=ky."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1A(a): Express limits symbolically using correct notation."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1A(b): Interpret limits expressed symbolically."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1B: Estimate limits of functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1C: Determine limits of functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1D: Deduce and interpret behavior of functions using limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.2A: Analyze functions for intervals of continuity or points of discontinuity."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.2B: Determine the applicability of important calculus theorems using continuity."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.1A: Identify the derivative of a function as the limit of a difference quotient."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.1B: Estimate derivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.1C: Calculate derivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.1D: Determine higher order derivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.2A: Use derivatives to analyze properties of a function."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.2B: Recognize the connection between differentiability and continuity."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3A: Interpret the meaning of a derivative within a problem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3B: Solve problems involving slope of a tangent line."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3C: Solve problems involving related rates, optimization, rectilinear motion."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3D: Solve problems involving rates of change in applied contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3E: Verify solutions to differential equations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3F: Estimate solutions to differential equations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.4A: Apply the Mean Value Theorem to describe the behavior of a function over an interval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.1A: Recognize antiderivatives of basic functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.2A(a): Interpret the definite integral as the limit of a Riemann sum."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.2A(b): Express the limit of a Riemann sum in integral notation."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.2B: Approximate a definite integral."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.2C: Calculate a definite integral using areas and properties of definite integrals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.3A: Analyze functions defined by an integral."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.3B(a): Calculate antiderivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.3B(b): Evaluate definite integrals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4A: Interpret the meaning of a definite integral within a problem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4B: Apply definite integrals to problems involving the average value of a function."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4C: Apply definite integrals to problems involving motion."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4D: Apply definite integrals to problems involving area, volume."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4E: Use the definite integral to solve problems in various contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.5A: Analyze differential equations to obtain general and specific solutions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.5B: Interpret, create and solve differential equations from problems in context."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.1: Use definitions and theorems to build arguments, to justify conclusions or answers, and to prove results;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.2: Confirm that hypotheses have been satisfied in order to apply the conclusion of a theorem;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.3: Apply definitions and theorems in the process of solving a problem;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.4: Interpret quantifiers in definitions and theorems (e.g., “for all,” “there exists”);"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.5: Develop conjectures based on exploration with technology; and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.6: Produce examples and counterexamples to clarify understanding of definitions, to investigate whether converses of theorems are TRUE or false, or to test conjectures."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "2.1: Relate the concept of a limit to all aspects of calculus;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "2.2: Use the connection between concepts (e.g., rate of change and accumulation) or processes (e.g., differentiation and its inverse process, antidifferentiation) to solve problems;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "2.3: Connect concepts to their visual representations with and without technology; and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "2.4: Identify a common underlying structure in problems involving different contextual situations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.1: Select appropriate mathematical strategies;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.2: Sequence algebraic/computational procedures logically;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.3: Complete algebraic/computational processes correctly;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.4: Apply technology strategically to solve problems;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.5: Attend to precision graphically, numerically, analytically, and verbally and specify units of measure; and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.6: Connect the results of algebraic/computational processes to the question asked."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.1: Associate tables, graphs, and symbolic representations of functions;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.2: Develop concepts using graphical, symbolical, or numerical representations with and without technology;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.3: Identify how mathematical characteristics of functions are related in different representations;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.4: Extract and interpret mathematical content from any presentation of a function (e.g., utilize information from a table of values);"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.5: Construct one representational form from another (e.g., a table from a graph or a graph from given information); and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.6: Consider multiple representations of a function to select or construct a useful representation for solving a problem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "5.1: Know and use a variety of notations (e.g., f’(x), y’, (dy/dx));"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "5.2: Connect notation to definitions (e.g., relating the notation for the definite integral to that of the limit of a Riemann sum);"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "5.3: Connect notation to different representations (graphical, numerical, analytical, and verbal); and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "5.4: Assign meaning to notation, accurately interpreting the notation in a given problem and across different contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.1: Clearly present methods, reasoning, justifications, and conclusions;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.2: Use accurate and precise language and notation;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.3: Explain the meaning of expressions, notation, and results in terms of a context (including units);"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.4: Explain the connections among concepts;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.5: Critically interpret and accurately report information provided by technology; and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.6: Analyze, evaluate, and compare the reasoning of others."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1A1: Given a function f, the limit of f(x) as x approaches c is a real number R if f(x) can be made arbitrarily close to R by taking x sufficiently close c to (but not equal to c). If the limit exists and is a real number, then the common notation is lim(x→c) f(x) = R."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1A2: The concept of a limit can be extended to include one-sided limits, limits at infinity, and infinite limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1A3: A limit might not exist for some functions at particular values of x. Some ways that the limit might not exist are if the function is unbounded, if the function is oscillating near this value, or if the limit from the left does not equal the limit from the right."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1B1: Numerical and graphical information can be used to estimate limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1C1: Limits of sums, differences, products, quotients, and composite functions can be found using the basic theorems of limits and algebraic rules."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1C2: The limit of a function may be found by using algebraic manipulation, alternate forms of trigonometric functions, or the squeeze theorem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1C3: Limits of the indeterminate forms 0/0 and ∞/∞ may be evaluated using L’Hospital’s Rule."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1D1: Asymptotic and unbounded behavior of functions can be explained and described using limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.1D2: Relative magnitudes of functions and their rates of change can be compared using limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.2A1: A function f is continuous at x = c provided that f(c) exists, lim(x→c) f(x) exists, and lim(x→c) f(x) = f(c)."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.2A2: Polynomial, rational, power, exponential, logarithmic, and trigonometric functions are continuous at all points in their domains."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.2A3: Types of discontinuities include removable discontinuities, jump discontinuities, and discontinuities due to vertical asymptotes."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "1.2B1: Continuity is an essential condition for theorems such as the Intermediate Value Theorem, the Extreme Value Theorem, and the Mean Value Theorem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C1: Direct application of the definition of the derivative can be used to find the derivative for selected functions, including polynomial, power, sine, cosine, exponential, and logarithmic functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C2: Specific rules can be used to calculate derivatives for classes of functions, including polynomial, rational, power, exponential, logarithmic, trigonometric, and inverse trigonometric."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C3: Sums, differences, products, and quotients of functions can be differentiated using derivative rules."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C4: The chain rule provides a way to differentiate composite functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C5: The chain rule is the basis for implicit differentiation."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1C6: The chain rule can be used to find the derivative of an inverse function, provided the derivative of that function exists."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1D1: Differentiating f’ produces the second derivative f’’, provided the derivative of f’ exists; repeating this process produces higher order derivatives of f."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.1D2: Higher order derivatives are represented with a variety of notations. For y=f(x), notations for the second derivative include d^2y/dx^2, f’’(x) and y’’. Higher order derivatives can be denoted (d^n)y/dx^n or (f^n)x."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2A1: First and second derivatives of a function can provide information about the function and its graph including intervals of increase or decrease, local (relative) and global (absolute) extrema, intervals of upward or downward concavity, and points of inflection."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2A2: Key features of functions and their derivatives can be identified and related to their graphical, numerical, and analytical representations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2A3: Key features of the graphs of f, f’, and f’’ are related to one another."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2B1: A continuous function may fail to be differentiable at a point in its domain."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.2B2: If a function is differentiable at a point, then it is continuous at that point."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3A1: The unit for f’(x) is the unit for f divided by the unit for x."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3A2: The derivative of a function can be interpreted as the instantaneous rate of change with respect to its independent variable."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3B1: The derivative at a point is the slope of the line tangent to a graph at that point on the graph."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3B2: The tangent line is the graph of a locally linear approximation of the function near the point of tangency."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3C1: The derivative can be used to solve rectilinear motion problems involving position, speed, velocity, and acceleration."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3C2: The derivative can be used to solve related rates problems, that is, finding a rate at which one quantity is changing by relating it to other quantities whose rates of change are known."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3C3: The derivative can be used to solve optimization problems, that is, finding a maximum or minimum value of a function over a given interval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3D1: The derivative can be used to express information about rates of change in applied contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3E1: Solutions to differential equations are functions or families of functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3E2: Derivatives can be used to verify that a function is a solution to a given differential equation."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.3F1: Slope fields provide visual clues to the behavior of solutions to first order differential equations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "2.4A1: If a function f is continuous over the interval [a,b] and differentiable over the interval (a,b), the Mean Value Theorem guarantees a point within that open interval where the instantaneous rate of change equals the average rate of change over the interval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.1A1: An antiderivative of a function f is a function g whose derivative is f."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.1A2: Differentiation rules provide the foundation for finding antiderivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2A1: A Riemann sum, which requires a partition of an interval I, is the sum of products, each of which is the value of the function at a point in a subinterval multiplied by the length of that subinterval of the partition."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2A2: The definite integral of a continuous function f over the interval [a,b], denoted by int{a to b} f(x)dx, is the limit of Riemann sums as the widths of the subintervals approach 0 That is, int{a to b} f(x)dx= lim[maxΔxi→0] (n)Σ(i=1) f(xi*)Δxi where xi* is a value in the ith subinterval, Δxi is the width of the ith subinterval, n is the number of subintervals, and is the width of the largest subinterval. Another form of the definition is int{a to b} f(x)dx=lim[n→∞] (n)Σ(i=1) f(xi*)Δxi, where Δxi = (b-a)/n and xi* is a value in the ith subinterval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2A3: The information in a definite integral can be translated into the limit of a related Riemann sum, and the limit of a Riemann sum can be written as a definite integral."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2B1: Definite integrals can be approximated for functions that are represented graphically, numerically, algebraically, and verbally."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2B2: Definite integrals can be approximated using a left Riemann sum, a right Riemann sum, a midpoint Riemann sum, or a trapezoidal sum; approximations can be computed using either uniform or nonuniform partitions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2C1: In some cases, a definite integral can be evaluated by using geometry and the connection between the definite integral and area."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2C2: Properties of definite integrals include the integral of a constant times a function, the integral of the sum of two functions, reversal of limits of integration, and the integral of a function over adjacent intervals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.2C3: The definition of the definite integral may be extended to functions with removable or jump discontinuities."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3A1: The definite integral can be used to define new functions; for example, f(x) = int{0 to x}(e^-t^2)dt."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3A2: If f is a continuous function on the interval [a,b], then (d/dx)(int{a to x}f(t)dt=f(x), where x is between a and b."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3A3: Graphical, numerical, analytical, and verbal representations of a function f provide information about the function defined as g(x)=int{a to x}f(t)dt."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B1: The function defined by F(x)=int{a to x}f(t)dt is an antiderivative of f."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B2: If f is continuous on the interval [a,b] and F is an antiderivative of f, then int{a to b} f(x)dx=F(b)-F(a)."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B3: The notation ∫f(x)dx=F(x)+C means that F’(x)=f(x), and ∫f(x)dx is called an indefinite integral of the function f."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B4: Many functions do not have closed form antiderivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.3B5: Techniques for finding antiderivatives include algebraic manipulation such as long division and completing the square, substitution of variables."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4A1: A function defined as an integral represents an accumulation of a rate of change."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4A2: The definite integral of the rate of change of a quantity over an interval gives the net change of that quantity over that interval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4A3: The limit of an approximating Riemann sum can be interpreted as a definite integral."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4B1: The average value of a function f over an interval [a,b] is (1/b-a)int{a to b}f(x)dx."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4C1: For a particle in rectilinear motion over an interval of time, the definite integral of velocity represents the particle’s displacement over the interval of time, and the definite integral of speed represents the particle’s total distance traveled over the interval of time."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4D1: Areas of certain regions in the plane can be calculated with definite integrals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4D2: Volumes of solids with known cross sections, including discs and washers, can be calculated with definite integrals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.4E1: The definite integral can be used to express information about accumulation and net change in many applied contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5A1: Antidifferentiation can be used to find specific solutions to differential equations with given initial conditions, including applications to motion along a line, exponential growth and decay."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5A2: Some differential equations can be solved by separation of variables."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5A3: Solutions to differential equations may be subject to domain restrictions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5A4: The function F defined by F(x)=c+int{a to x}f(t)dt is a general solution to the differential equation dy/dx=f(x), and F(x) = y_0+int{a to x}f(t)dt is a particular solution to the differential equation dy/dx=f(x) satisfying F(a)=y_0."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.EK",
    "Description": "3.5B1: The model for exponential growth and decay that arises from the statement “The rate of change of a quantity is proportional to the size of the quantity” is dy/dt=ky."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1A(a): Express limits symbolically using correct notation."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1A(b): Interpret limits expressed symbolically."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1B: Estimate limits of functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1C: Determine limits of functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.1D: Deduce and interpret behavior of functions using limits."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.2A: Analyze functions for intervals of continuity or points of discontinuity."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "1.2B: Determine the applicability of important calculus theorems using continuity."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.1A: Identify the derivative of a function as the limit of a difference quotient."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.1B: Estimate derivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.1C: Calculate derivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.1D: Determine higher order derivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.2A: Use derivatives to analyze properties of a function."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.2B: Recognize the connection between differentiability and continuity."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3A: Interpret the meaning of a derivative within a problem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3B: Solve problems involving slope of a tangent line."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3C: Solve problems involving related rates, optimization, rectilinear motion."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3D: Solve problems involving rates of change in applied contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3E: Verify solutions to differential equations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.3F: Estimate solutions to differential equations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "2.4A: Apply the Mean Value Theorem to describe the behavior of a function over an interval."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.1A: Recognize antiderivatives of basic functions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.2A(a): Interpret the definite integral as the limit of a Riemann sum."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.2A(b): Express the limit of a Riemann sum in integral notation."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.2B: Approximate a definite integral."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.2C: Calculate a definite integral using areas and properties of definite integrals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.3A: Analyze functions defined by an integral."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.3B(a): Calculate antiderivatives."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.3B(b): Evaluate definite integrals."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4A: Interpret the meaning of a definite integral within a problem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4B: Apply definite integrals to problems involving the average value of a function."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4C: Apply definite integrals to problems involving motion."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4D: Apply definite integrals to problems involving area, volume."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.4E: Use the definite integral to solve problems in various contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.5A: Analyze differential equations to obtain general and specific solutions."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.LO",
    "Description": "3.5B: Interpret, create and solve differential equations from problems in context."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.1: Use definitions and theorems to build arguments, to justify conclusions or answers, and to prove results;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.2: Confirm that hypotheses have been satisfied in order to apply the conclusion of a theorem;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.3: Apply definitions and theorems in the process of solving a problem;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.4: Interpret quantifiers in definitions and theorems (e.g., “for all,” “there exists”);"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.5: Develop conjectures based on exploration with technology; and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "1.6: Produce examples and counterexamples to clarify understanding of definitions, to investigate whether converses of theorems are TRUE or false, or to test conjectures."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "2.1: Relate the concept of a limit to all aspects of calculus;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "2.2: Use the connection between concepts (e.g., rate of change and accumulation) or processes (e.g., differentiation and its inverse process, antidifferentiation) to solve problems;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "2.3: Connect concepts to their visual representations with and without technology; and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "2.4: Identify a common underlying structure in problems involving different contextual situations."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.1: Select appropriate mathematical strategies;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.2: Sequence algebraic/computational procedures logically;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.3: Complete algebraic/computational processes correctly;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.4: Apply technology strategically to solve problems;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.5: Attend to precision graphically, numerically, analytically, and verbally and specify units of measure; and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "3.6: Connect the results of algebraic/computational processes to the question asked."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.1: Associate tables, graphs, and symbolic representations of functions;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.2: Develop concepts using graphical, symbolical, or numerical representations with and without technology;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.3: Identify how mathematical characteristics of functions are related in different representations;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.4: Extract and interpret mathematical content from any presentation of a function (e.g., utilize information from a table of values);"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.5: Construct one representational form from another (e.g., a table from a graph or a graph from given information); and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "4.6: Consider multiple representations of a function to select or construct a useful representation for solving a problem."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "5.1: Know and use a variety of notations (e.g., f’(x), y’, (dy/dx));"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "5.2: Connect notation to definitions (e.g., relating the notation for the definite integral to that of the limit of a Riemann sum);"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "5.3: Connect notation to different representations (graphical, numerical, analytical, and verbal); and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "5.4: Assign meaning to notation, accurately interpreting the notation in a given problem and across different contexts."
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.1: Clearly present methods, reasoning, justifications, and conclusions;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.2: Use accurate and precise language and notation;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.3: Explain the meaning of expressions, notation, and results in terms of a context (including units);"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.4: Explain the connections among concepts;"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.5: Critically interpret and accurately report information provided by technology; and"
  },
  {
    "Course": "Math",
    "Grade": 12,
    "Standard": "AB.MPAC",
    "Description": "6.6: Analyze, evaluate, and compare the reasoning of others."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.1.1.",
    "Description": "Compare causes and/or effects, including between short- and long-term effects."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.1.2.",
    "Description": "Analyze and evaluate the interaction of multiple causes and/or effects."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.1.3.",
    "Description": "Assess historical contingency by distinguishing among coincidence, causation, and correlation, as well as critiquing existing interpretations of cause and effect."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.2.1.",
    "Description": "Analyze and evaluate historical patterns of continuity and change over time."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.2.2.",
    "Description": "Connect patterns of continuity and change over time to larger historical processes or themes."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.3.1.",
    "Description": "Explain ways that historical events and processes can be organized within blocks of time."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.3.2.",
    "Description": "Analyze and evaluate competing models of periodization of U.S. history."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.II.4.1.",
    "Description": "Compare related historical developments and processes across place, time, and/or different societies or within one society."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.II.4.2.",
    "Description": "Explain and evaluate multiple and differing perspectives on a given historical phenomenon."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.II.5.1.",
    "Description": "Explain and evaluate ways in which specific historical phenomena, events, or processes connect to broader regional, national, or global processes occurring at the same time."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.II.5.2.",
    "Description": "Explain and evaluate ways in which a phenomenon, event, or process connects to other, similar historical phenomena across time and place."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.III.6.1.",
    "Description": "Analyze commonly accepted historical arguments and explain how an argument has been constructed from historical evidence."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.III.6.2.",
    "Description": "Construct convincing interpretations through analysis of disparate, relevant historical evidence."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.III.6.3.",
    "Description": "Evaluate and synthesize conflicting historical evidence to construct persuasive historical arguments."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.III.7.1.",
    "Description": "Analyze features of historical evidence such as audience, purpose, point of view, format, argument, limitations, and context germane to the evidence considered."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.III.7.2.",
    "Description": "Based on analysis and evaluation of historical evidence, make supportable inferences and draw appropriate conclusions."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.IV.8.1.",
    "Description": "Analyze diverse historical interpretations."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.IV.8.2.",
    "Description": "Evaluate how historians perspectives influence their interpretations and how models of historical interpretation change over time."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.IV.9.1.",
    "Description": "Combine disparate, sometimes contradictory evidence from primary sources and secondary works in order to create a persuasive understanding of the past."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.IV.9.2.",
    "Description": "Apply insights about the past to other historical contexts or circumstances, including the present."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.CUL-1.",
    "Description": "Compare the cultural values and attitudes of different European, African American, and native peoples in the colonial period and explain how contact affected intergroup relationships and conflicts."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.CUL-2.",
    "Description": "Analyze how emerging conceptions of national identity and democratic ideals shaped value systems, gender roles, and cultural movements in the late 18th century and the 19th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.CUL-3.",
    "Description": "Explain how cultural values and artistic expression changed in response to the Civil War and the postwar industrialization of the United States."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.CUL-4.",
    "Description": "Analyze how changing religious ideals, Enlightenment beliefs, and republican thought shaped the politics, culture, and society of the colonial era through the early Republic."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.CUL-5.",
    "Description": "Analyze ways that philosophical, moral, and scientific ideas were used to defend and challenge the dominant economic and social order in the 19th and 20th centuries."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.CUL-6.",
    "Description": "Analyze the role of culture and the arts in 19th- and 20th-century movements for social and political change."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.CUL-7.",
    "Description": "Explain how and why modern cultural values and popular culture have grown since the early 20th century and how they have affected American politics and society."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ENV-1.",
    "Description": "Explain how the introduction of new plants, animals, and technologies altered the natural environment of North America and affected interactions among various groups in the colonial period."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ENV-2.",
    "Description": "Explain how the natural environment contributed to the development of distinct regional group identities, institutions, and conflicts in the precontact period through the independence period."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ENV-3.",
    "Description": "Analyze the role of environmental factors in contributing to regional economic and political identities in the 19th century and how they affected conflicts such as the American Revolution and the Civil War."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ENV-4.",
    "Description": "Analyze how the search for economic resources affected social and political developments from the colonial period through Reconstruction."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ENV-5.",
    "Description": "Explain how and why debates about and policies concerning the use of natural resources and the environment more generally have changed since the late 19th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ID-1.",
    "Description": "Analyze how competing conceptions of national identity were expressed in the development of political institutions and cultural values from the late colonial through the antebellum periods."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ID-2.",
    "Description": "Assess the impact of Manifest Destiny, territorial expansion, the Civil War, and industrialization on popular beliefs about progress and the national destiny of the United States in the 19th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ID-3.",
    "Description": "Analyze how U.S. involvement in international crises such as the Spanish-American War, World Wars I and II, the Great Depression, and the Cold War influenced public debates about American national identity in the 20th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ID-4.",
    "Description": "Explain how conceptions of group identity and autonomy emerged out of cultural interactions between colonizing groups, Africans, and American Indians in the colonial era."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ID-5.",
    "Description": "Analyze the role of economic, political, social, and ethnic factors on the formation of regional identities in what would become the United States from the colonial period through the 19th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ID-6.",
    "Description": "Analyze how migration patterns to, and migration within, the United States have influenced the growth of racial and ethnic identities and conflicts over ethnic assimilation and distinctiveness."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ID-7.",
    "Description": "Analyze how changes in class identity and gender roles have related to economic, social, and cultural transformations since the late 19th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.ID-8.",
    "Description": "Explain how civil rights activism in the 20th century affected the growth of African American and other identity-based political and social movements."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.PEO-1.",
    "Description": "Explain how and why people moved within the Americas (before contact) and to and within the Americas (after contact and colonization)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.PEO-2.",
    "Description": "Explain how changes in the numbers and sources of international migrants in the 19th and 20th centuries altered the ethnic and social makeup of the U.S. population."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.PEO-3.",
    "Description": "Analyze the causes and effects of major internal migration patterns such as urbanization, suburbanization, westward movement, and the Great Migration in the 19th and 20th centuries."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.PEO-4.",
    "Description": "Analyze the effects that migration, disease, and warfare had on the American Indian population after contact with Europeans."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.PEO-5.",
    "Description": "Explain how free and forced migration to and within different parts of North America caused regional development, cultural diversity and blending, and political and social conflicts through the 19th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.PEO-6.",
    "Description": "Analyze the role of both internal and international migration on changes to urban life, cultural developments, labor issues, and reform movements from the mid-19th century through the mid-20th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.PEO-7.",
    "Description": "Explain how and why debates over immigration to the United States have changed since the turn of the 20th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.POL-1.",
    "Description": "Analyze the factors behind competition, cooperation, and conflict among different societies and social groups in North America during the colonial period."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.POL-2.",
    "Description": "Explain how and why major party systems and political alignments arose and have changed from the early Republic through the end of the 20th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.POL-3.",
    "Description": "Explain how activist groups and reform movements, such as antebellum reformers, civil rights activists, and social conservatives, have caused changes to state institutions and U.S. society."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.POL-4.",
    "Description": "Analyze how and why the New Deal, the Great Society, and the modern conservative movement all sought to change the federal government's role in U.S. political, social, and economic life."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.POL-5.",
    "Description": "Analyze how arguments over the meaning and interpretation of the Constitution have affected U.S. politics since 1787"
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.POL-6.",
    "Description": "Analyze how debates over political values (such as democracy, freedom, and citizenship) and the extension of American ideals abroad contributed to the ideological clashes and military conflicts of the 19th century and the early 20th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.POL-7.",
    "Description": "Analyze how debates over civil rights and civil liberties have influenced political life from the early 20th century through the early 21st century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WOR-1.",
    "Description": "Explain how imperial competition and the exchange of commodities across both sides of the Atlantic Ocean influenced the origins and patterns of development of North American societies in the colonial period."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WOR-2.",
    "Description": "Explain how the exchange of ideas among different parts of the Atlantic World shaped belief systems and independence movements into the early 19th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WOR-3.",
    "Description": "Explain how the growing interconnection of the United States with worldwide economic, labor, and migration systems affected U.S. society since the late 19th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WOR-4.",
    "Description": "Explain how the U.S. involvement in global conflicts in the 20th century set the stage for domestic social changes."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WOR-5.",
    "Description": "Analyze the motives behind, and results of, economic, military, and diplomatic initiatives aimed at expanding U.S. power and territory in the Western Hemisphere in the years between independence and the Civil War."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WOR-6.",
    "Description": "Analyze the major aspects of domestic debates over U.S. expansionism in the 19th century and the early 20th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WOR-7.",
    "Description": "Analyze the goals of U.S. policymakers in major international conflicts, such as the Spanish-American War, World Wars I and II, and the Cold War, and explain how U.S. involvement in these conflicts has altered the U.S. role in world affairs."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WOR-8.",
    "Description": "Explain how U.S. military and economic involvement in the developing world and issues such as terrorism and economic globalization have changed U.S. foreign policy goals since the middle of the 20th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WXT-1.",
    "Description": "Explain how patterns of exchanging commodities, peoples, diseases, and ideas around the Atlantic World developed after European contact and shaped North American colonial-era societies."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WXT-2.",
    "Description": "Analyze how innovations in markets, transportation, and technology affected the economy and the different regions of North America from the colonial period through the end of the Civil War."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WXT-3.",
    "Description": "Explain how changes in transportation, technology, and the integration of the U.S. economy into world markets have influenced U.S. society since the Gilded Age."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WXT-4.",
    "Description": "Explain the development of labor systems such as slavery, indentured servitude, and free labor from the colonial period through the end of the 18th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WXT-5.",
    "Description": "Explain how and why different labor systems have developed, persisted, and changed since 1800 and how events such as the Civil War and industrialization shaped U.S. society and workers lives."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WXT-6.",
    "Description": "Explain how arguments about market capitalism, the growth of corporate power, and government policies influenced economic policies from the late 18th century through the early 20th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WXT-7.",
    "Description": "Compare the beliefs and strategies of movements advocating changes to the U.S. economic system since industrialization, particularly the organized labor, Populist, and Progressive movements."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "II.WXT-8.",
    "Description": "Explain how and why the role of the federal government in regulating economic life and the environment has changed since the end of the 19th century."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.1.",
    "Description": "Skill 0.0416666666666667 Historical Causation - Historical thinking involves the ability to identify, analyze, and evaluate the relationships among multiple historical causes and effects, distinguishing between those that are long-term and proximate, and among coincidence, causation, and correlation."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.2.",
    "Description": "Skill 0.0833333333333333 Patterns of Continuity and Change over Time - Historical thinking involves the ability to recognize, analyze, and evaluate the dynamics of historical continuity and change over periods of time of varying lengths, as well as the ability to relate these patterns to larger historical processes or themes."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.I.3.",
    "Description": "Skill 0.125 Periodization - Historical thinking involves the ability to describe, analyze, evaluate, and construct models that historians use to organize history into discrete periods. To accomplish this periodization of history, historians identify turning points and recognize that the choice of specific dates gives a higher value to one narrative, region, or group than to other narratives, regions, or groups. How a historian defines historical periods depends on what the historian considers most significant political, economic, social, cultural, or environmental factors. Changing periodization can change a historical narrative. Moreover, historical thinking involves being aware of how the circumstances and contexts of a historians work might shape his or her choices about periodization."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.II.4.",
    "Description": "Skill 0.166666666666667 Comparison - Historical thinking involves the ability to describe, compare, and evaluate multiple historical developments within one society, one or more developments across or between different societies, and in various chronological and geographical contexts. It also involves the ability to identify, compare, and evaluate multiple perspectives on a given historical experience."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.II.5.",
    "Description": "Skill 0.208333333333333 Contextualization - Historical thinking involves the ability to connect historical events and processes to specific circumstances of time and place and to broader regional, national, or global processes."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.III.6.",
    "Description": "Skill 0.25 Historical Argumentation - Historical thinking involves the ability to define and frame a question about the past and to address that question through the construction of an argument. A plausible and persuasive argument requires a clear, comprehensive, and analytical thesis, supported by relevant historical evidence not simply evidence that supports a preferred or preconceived position. In addition, argumentation involves the capacity to describe, analyze, and evaluate the arguments of others in light of available evidence."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.III.7.",
    "Description": "Skill 0.291666666666667 Appropriate Use of Relevant Historical Evidence - Historical thinking involves the ability to describe and evaluate evidence about the past from diverse sources (including written documents, works of art, archaeological artifacts, oral traditions, and other primary sources) and requires students to pay attention to the content, authorship, purpose, format, and audience of such sources. It involves the capacity to extract useful information, make supportable inferences, and draw appropriate conclusions from historical evidence while also noting the context in which the evidence was produced and used, recognizing its limitations, and assessing the points of view it reflects."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.IV.8.",
    "Description": "Skill 0.333333333333333 Interpretation - Historical thinking involves the ability to describe, analyze, evaluate, and construct diverse interpretations of the past, and being aware of how particular circumstances and contexts in which individual historians work and write also shape their interpretation of past events. Historical interpretation requires analyzing evidence, reasoning, determining the context, and evaluating points of view found in both primary and secondary sources."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "I.IV.9.",
    "Description": "Skill 0.375 Synthesis - Historical thinking involves the ability to develop meaningful and persuasive new understandings of the past by applying all of the other historical thinking skills, by drawing appropriately on ideas and methods from different fields of inquiry or disciplines, and by creatively fusing disparate, relevant, and sometimes contradictory evidence from primary sources and secondary works. Additionally, synthesis may involve applying insights about the past to other historical contexts or circumstances, including the present."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.1a",
    "Description": "The Paleolithic Era was characterized by non-sedentary hunting and gathering lifestyles, whereas the Neolithic Era was characterized by a turn to agriculture, herding, and semisedentary lifestyles. Students will analyze the political, social, and economic differences in human lives before and after the Neolithic Revolution, including the shift in roles of men and women."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.1b",
    "Description": "Complex societies and civilizations adapted to and modified their environment to meet the needs of their population. Students will explore how the Mesopotamian, Shang, and Indus River valley civilizations adapted to and modified their environments to meet their need for food, clothing, and shelter."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.1c",
    "Description": "Complex societies and civilizations shared common characteristics of religion, job specialization, cities, government, language/writing systems, technology, and social hierarchy, and they made unique contributions. Students will explore the Mesopotamian, Shang, and Indus River valley civilizations by examining archaeological and historical evidence to compare and contrast characteristics and note their unique contributions."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.2a",
    "Description": "Belief systems developed beliefs and practices to address questions of origin, the requirements to live a good life, and the nature of the afterlife. Students will identify the place of origin, compare and contrast the core beliefs and practices, and explore the sacred texts and ethical codes for Hinduism, Buddhism, Judaism, Christianity, Islam, Confucianism, and Daoism."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.2b",
    "Description": "Belief systems were often used to unify groups of people, and affected social order and gender roles. Students will examine similarities and differences between Hinduism, Buddhism, Judaism, Christianity, Islam, and Confucianism regarding their effects on social order and gender roles."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.3a",
    "Description": "Geographic factors encouraged and hindered a state’s/empire’s expansion and interactions. Students will examine the locations and relative sizes of classical political entities (Greece, Gupta, Han, Maurya, Maya, Qin, Rome) noting the location and size of each in relation to the amount of power each held within a region. Students will investigate how geographic factors encouraged or hindered expansion and interactions within the Greek, Roman, and Mayan civilizations."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.3b",
    "Description": "Empires used belief systems, systems of law, forms of government, military forces, and social hierarchies to consolidate and expand power. Students will compare and contrast how the Mauryan, Qin, and Roman civilizations consolidated and increased power."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.3c",
    "Description": "A period of peace, prosperity, and cultural achievements can be designated as a Golden Age. Students will examine the achievements of Greece, Gupta, Han Dynasty, Maya, and Rome to determine if the civilizations experienced a Golden Age."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.3d",
    "Description": "Political, socioeconomic, and environmental issues, external conflicts, and nomadic invasions led to the decline and fall of Classical empires. Students will compare and contrast the forces that led to the fall of the Han Dynasty, the Mayan civilization, and the Roman Empire."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.4a",
    "Description": "Afro-Eurasian transregional trade networks grew across land and bodies of water. Students will identify the location of the transregional trade networks noting regional connections between the Indian Ocean complex, Mediterranean Sea complex, Silk Roads, and Trans-Saharan routes."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.4b",
    "Description": "New technologies facilitated and improved interregional travel during this era by allowing people to traverse previously prohibitive physical landscapes and waterways. Students will examine the technologies that facilitated and improved interregional travel along the Indian Ocean and Trans-Saharan networks of exchange."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.4c",
    "Description": "Interregional travelers, traders, missionaries, and nomads carried products and natural resources, and brought with them enslaved people and ideas that led to cultural diffusion. Students will identify and explain the importance of at least two key resources and/or products and/or luxury items vital to exchanges along the Indian Ocean complex, Mediterranean Sea complex, Silk Roads, and Trans-Saharan routes. Students will identify trade networks involved in the exchange of enslaved people and explore the nature of slavery during this time period. Students will examine the diffusion of religious ideas along the Indian Ocean complex, Silk Roads, and Trans-Saharan routes. Students will examine the travels of Zheng He, Ibn Battuta, and Marco Polo and the influence of their journeys."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.4d",
    "Description": "Control of transregional trade and economic growth contributed to the emergence and expansion of political states. Students will examine the emergence and expansion of political states along the Mediterranean Sea complex (the Byzantine"
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.5a",
    "Description": "Following the fall of the Roman Empire, divergent societies emerged in Europe. Students will examine the political, economic, and social institutions of feudal Western Europe and the Byzantine Empire, including the role of Justinian and Theodora during the Middle Ages. Students will compare and contrast the institutions in feudal Western Europe and the Byzantine Empire ca. 500 to ca. 1200"
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.5b",
    "Description": "Political states and empires employed a variety of techniques for expanding and maintaining control, and sometimes disrupted state-building in other regions. Students will examine the locations and relative sizes of postclassical states and empires at the heights of their power, including the Abbasid Caliphate, Byzantine Empire, Mongol Empire, and Song and Tang dynasties, noting relative position, power within their regions and the areas they influenced. Students will compare and contrast the empire-building processes of the Mongols and the Islamic caliphates, noting important disruptions in other regions."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.5c",
    "Description": "Periods of stability and prosperity enabled cultural, technological, and scientific achievements and innovations that built on or blended with available knowledge, and often led to cultural diffusion. Students will compare and contrast the achievements and innovations of the Tang and Song dynasties with the Abbasid Caliphate. Students will explore the spread and evolution of technology and learning from East Asia to Western Europe via the Middle East (e.g., gunpowder, ship technology, navigation, printing, paper). Students will examine feudal Japan, tracing the previous arrival of elements of Chinese culture (e.g., Buddhism, writing, poetry, art) and how those elements were adopted in and adapted to Japanese society."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.6a",
    "Description": "Internal tensions and challenges grew as disputes over authority and power arose between and among religious and political leaders. Students will investigate the divisions within Islam (Sunni-Shia) and the Great Schism between Roman Catholic Christianity and Orthodox Christianity and their impacts. Students will investigate the Crusades and the Delhi Sultanate from multiple perspectives. Students will examine the development of Sikhism in South Asia during this time period."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.6b",
    "Description": "Networks of exchange facilitated the spread of disease, which affected social, cultural, economic, and demographic development. Students will map the spread of the Black Death (Bubonic Plague) as it was carried westward from Asia to Africa and Europe. Students will evaluate the effects of the Black Death on these regions"
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.7a",
    "Description": "Three belief systems influenced numerous, powerful states and empires across the Eastern Hemisphere. Students will map the extent of the Muslim, Neo-Confucian, and Christian realms and compare the relative size and power of these realms ca. 1400 Students will map the extent of the Ottoman Empire and the Ming Dynasty at the height of their power."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.7b",
    "Description": "The dominant belief systems and the ethnic and religious compositions of the Ottoman Empire and the Ming Dynasty affected their social, political, and economic structures and organizations. Students will analyze how the ethnic and religious compositions of the Ottoman Empire and the Ming Dynasty were reflected in their political and societal organizations."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.7c",
    "Description": "The Ottoman Empire and the Ming Dynasty had different views of the world and their place in it. Islam under the Ottoman Empire and Neo-Confucianism under the Ming Dynasty influenced the political, economic, military, and diplomatic interactions with others outside of their realm. Students will examine Ming interactions with European traders and Christian missionaries. Students will examine how the Ottomans interacted with Europeans noting the role of Suleiman the Magnificent"
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.8a",
    "Description": "Complex societies and civilizations continued to develop in Africa and the Americas. The environment, the availability of resources, and the use of trade networks shaped the growth of the Aztec, Inca, and Songhai empires and East African city-states. This growth also influenced their economies and relationships with others. Students will locate the extent of the Songhai and East African states in Africa and the Aztec and Incan empires in the Americas using an Atlantic Ocean-centered map. Students will examine the adaptations made to the environment by the Aztecs and Incas. Students will examine the relationships with neighboring peoples in the region considering warfare, tribute, and trade. Students will examine the influence of Islam on the growth of trade networks and power relations in the Songhai Empire and in East African city-states."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.8b",
    "Description": "Local traditional religions influenced the development of complex societies and civilizations in Africa and the Americas ca. 1325–1600. Students will examine the role of nature and the traditional religious beliefs in the Americas and Africa (e.g., animism) during this period. Students will explore the relationships between religious beliefs and political power in the Aztec and Inca empires."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.8c",
    "Description": "Complex societies and civilizations made unique cultural achievements and contributions. Students will investigate the achievements and contributions of the Aztec, Inca, and Songhai empires."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.9a",
    "Description": "The Renaissance was influenced by the diffusion of technology and ideas. The Islamic caliphates played an important role in this diffusion. Students will investigate technologies and ideas, including printing and paper, navigational tools, and mathematics and medical science that diffused to Europe, noting the role of the Islamic caliphates. Students will explore shifts in the Western European Medieval view of itself and the world as well as key Greco-Roman legacies that influenced Renaissance thinkers and artists. Students will examine political ideas developed during the Renaissance, including those of Machiavelli."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.9b",
    "Description": "The Reformation challenged traditional religious authority, which prompted a counter reformation that led to a religiously fragmented Western Europe and political conflicts. This religious upheaval continued the marginalization of Jews in European society. Students will explore the roles of key individuals, including Martin Luther, John Calvin, Elizabeth I, and Ignatius Loyola, and the impacts that they had on the religious and political unity of Europe. Students will trace the discrimination against and persecution of Jews."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.9c",
    "Description": "Absolutist governments emerged as Western European and Russian monarchs consolidated power and wealth. Students will investigate Russian efforts to remove Mongol and Islamic influence and to expand and transform their society. Students will investigate autocratic and absolutist rule by comparing and contrasting the reigns of Louis XIV and Peter the Great."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.9d",
    "Description": "The development of the Scientific Revolution challenged traditional authorities and beliefs. Students will examine the Scientific Revolution, including the influence of Galileo and Newton."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.9e",
    "Description": "The Enlightenment challenged views of political authority and how power and authority were conceptualized. Students will investigate the Enlightenment by comparing and contrasting the ideas expressed in The Leviathan and The Second Treatise on Government. Students will investigate the context and challenge to authority in the English Civil War and Glorious Revolution."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.10a",
    "Description": "Various motives, new knowledge, and technological innovations influenced exploration and the development of European transoceanic trade routes. Students will explore the relationship between knowledge and technological innovations, focusing on how knowledge of wind and current patterns, combined with technological innovations, influenced exploration and transoceanic travel. Students will trace major motivations for European interest in exploration and oceanic trade, including the influence of Isabella and Ferdinand."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.10b",
    "Description": "Transatlantic exploration led to the Encounter, colonization of the Americas, and the Columbian exchange. Students will map the exchange of crops and animals and the spread of diseases across the world during the Columbian exchange. Students will investigate the population of the Americas before the Encounter and evaluate the impact of the arrival of the Europeans on the indigenous populations. Students will contrast the demographic impacts on Europe and China after the introduction of new crops with demographic effects on the Americas resulting from the Columbian exchange."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.10c",
    "Description": "The decimation of indigenous populations in the Americas influenced the growth of the Atlantic slave trade. The trade of enslaved peoples resulted in exploitation, death, and the creation of wealth. Students will examine how the demand for labor, primarily for sugar cultivation and silver mining, influenced the growth of the trade of enslaved African peoples. Students will investigate European and African roles in the development of the slave trade, and investigate the conditions and treatment of enslaved Africans during the Middle Passage and in the Americas."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.10d",
    "Description": "European colonization in the Americas and trade interactions with Africa led to instability, decline, and near destruction of once-stable political and cultural systems. Students will examine the political, economic, cultural, and geographic impacts of Spanish colonization on the Aztec and Inca societies. Students will investigate the different degrees of social and racial integration and assimilation that occurred under colonizing powers, laying the foundations for complex and varying social hierarchies in the Americas. Students will examine the social, political, and economic impact of the Atlantic slave trade on Africa, including the development of the kingdoms of the Ashanti and Dahomey."
  },
  {
    "Course": "Social Studies",
    "Grade": 9,
    "Standard": "9.10e",
    "Description": "The Eastern Hemisphere trade networks were disrupted by the European development of new transoceanic trade across the Indian, Pacific, and Atlantic Oceans. Shifts in global trade networks and the use of gunpowder had positive and negative effects on Asian and European empires. Students will explore how new transoceanic routes shifted trade networks (e.g., Indian Ocean, the Silk Road, Trans-Saharan) in the Eastern Hemisphere. Students will explore how shifts in the global trade networks and the use of gunpowder affected the Ottoman Empire. Students will examine the development of European maritime empires and mercantilism."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.1a",
    "Description": "Powerful Eurasian states and empires faced and responded to challenges ca. 1750"
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.1a-1",
    "Description": "Students will compare and contrast the Mughal Empire and the Ottoman Empire in 1750 in terms of religious and ethnic tolerance, political organization, and commercial activity."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.1a-2",
    "Description": "Students will examine efforts to unify, stabilize, and centralize Japan under the rule of the Tokugawa Shogunate."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.1a-3",
    "Description": "Students will compare and contrast the Tokugawa Shogunate in Japan with France under the rule of the Bourbon Dynasty, looking at the role of Edo and Paris/Versailles, attempts to control the daimyo and nobles, and the development of bureaucracies."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.1b",
    "Description": "Perceptions of outsiders and interactions with them varied across Eurasia."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.1b-1",
    "Description": "Students will compare and contrast the Tokugawa and Mughal responses to outsiders, with attention to the impacts of those decisions."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.1b-2",
    "Description": "Students will create a world map showing the extent of European maritime empires, the Russian Empire, the Ottoman Empire, Mughal Empire, China under the Qing Dynasty, Japan under the Tokugawa Shogunate, Ashanti, Benin, and Dahomey ca. 1750"
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.1b-3",
    "Description": "Students will compare the size of these states, empires, and kingdoms relative to the power they wielded in their regions and in the world."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2a",
    "Description": "Enlightenment thinkers developed political philosophies based on natural laws, which included the concepts of social contract, consent of the governed, and the rights of citizens."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2a-1",
    "Description": "Students will examine at least three Enlightenment thinkers, including John Locke, Baron de Montesquieu, and Jean-Jacques Rousseau, and key ideas from their written works."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2b",
    "Description": "Individuals used Enlightenment ideals to challenge traditional beliefs and secure people’s rights in reform movements, such as women’s rights and abolition; some leaders may be considered enlightened despots."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2b-1",
    "Description": "Students will explore the influence of Enlightenment ideals on issues of gender and abolition by examining the ideas of individuals such as Mary Wollstonecraft and William Wilberforce."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2b-2",
    "Description": "Students will examine enlightened despots including Catherine the Great."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2c",
    "Description": "Individuals and groups drew upon principles of the Enlightenment to spread rebellions and call for revolutions in France and the Americas."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2c-1",
    "Description": "Students will examine evidence related to the preconditions of the French Revolution and the course of the revolution, noting the roles of Olympe de Gouges, Maximilien Robespierre, and Napoleon Bonaparte."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2c-2",
    "Description": "Students will examine the evidence related to the impacts of the French Revolution on resistance and revolutionary movements, noting the roles of Toussaint L’Ouverture and Simon Bolivar."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2d",
    "Description": "Cultural identity and nationalism inspired political movements that attempted to unify people into new nation-states and posed challenges to multinational states."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.2d-1",
    "Description": "Students will investigate the role of cultural identity and nationalism in the unification of Italy and Germany and in the dissolution of the Ottoman and Austrian Empires."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3a",
    "Description": "Agricultural innovations and technologies enabled people to alter their environment, allowing them to increase and support farming on a large scale."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3a-1",
    "Description": "Students will examine the agricultural revolution in Great Britain."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3b",
    "Description": "Factors including new economic theories and practices, new sources of energy, and technological innovations influenced the development of new communication and transportation systems and new methods of production. These developments had numerous effects."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3b-1",
    "Description": "Students will analyze the factors and conditions needed to industrialize and to expand industrial production, as well as shifts in economic practices."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3b-2",
    "Description": "Students will examine the economic theory presented in The Wealth of Nations."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3b-3",
    "Description": "Students will examine changes and innovations in energy, technology, communication, and transportation that enabled industrialization."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3c",
    "Description": "Shifts in population from rural to urban areas led to social changes in class structure, family structure, and the daily lives of people."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3c-1",
    "Description": "Students will investigate the social, political, and economic impacts of industrialization in Victorian England and Meiji Japan and compare and contrast them."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3d",
    "Description": "Social and political reform, as well as new ideologies, developed in response to industrial growth."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3d-1",
    "Description": "Students will investigate suffrage, education, and labor reforms, as well as ideologies such as Marxism, that were intended to transform society."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.3d-2",
    "Description": "Students will examine the Irish potato famine within the context of the British agricultural revolution and Industrial Revolution."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.4a",
    "Description": "European industrialized states and Japan sought to play a dominant role in the world and to control natural resources for political, economic, and cultural reasons."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.4a-1",
    "Description": "Students will explore imperialism from a variety of perspectives such as those of missionaries, indigenous peoples, women, merchants/business people, and government officials."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.4a-2",
    "Description": "Students will trace how imperial powers politically and economically controlled territories and people, including direct and indirect rule in Africa (South Africa, Congo, and one other territory), India, Indochina, and spheres of influence in China."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.4b",
    "Description": "Those who faced being colonized engaged in varying forms of resistance and adaptation to colonial rule with varying degrees of success."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.4b-1",
    "Description": "Students will investigate one example of resistance in Africa (Zulu, Ethiopia, or Southern Egypt/Sudan) and one in China (Taiping Rebellion or Boxer Rebellion and the role of Empress Dowager CiXi)."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.4b-2",
    "Description": "Students will investigate how Japan reacted to the threat of Western imperialism in Asia."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.4c",
    "Description": "International conflicts developed as imperial powers competed for control. Claims over land often resulted in borders being shifted on political maps, often with little regard for traditional cultures and commerce (e.g., Berlin Conference)."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.4c-1",
    "Description": "Students will compare and contrast maps of Africa from ca. 1800 and ca. 1914, noting the changes and continuities of ethnic groups and regions, African states, and European claims."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5a",
    "Description": "International competition, fueled by nationalism, imperialism, and militarism along with shifts in the balance of power and alliances, led to world wars."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5a-1",
    "Description": "Students will compare and contrast long- and short-term causes and effects of World War I and World War II."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5b",
    "Description": "Technological developments increased the extent of damage and casualties in both World War I and World War II."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5b-1",
    "Description": "Students will compare and contrast the technologies utilized in both World War I and World War II, noting the human and environmental devastation."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5c",
    "Description": "The devastation of the world wars and use of total war led people to explore ways to prevent future world wars."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5c-1",
    "Description": "Students will examine international efforts to work together to build stability and peace, including Wilson’s Fourteen Points, the Treaty of Versailles, the League of Nations, and the United Nations."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5d",
    "Description": "Nationalism and ideology played a significant role in shaping the period between the world wars."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5d-1",
    "Description": "Students will examine the Russian Revolution and the development of Soviet ideology and nationalism under Lenin and Stalin."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5d-2",
    "Description": "Students will examine the role of nationalism and the development of the National Socialist state under Hitler in Germany."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5d-3",
    "Description": "Students will examine the role of nationalism and militarism in Japan."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5d-4",
    "Description": "Students will investigate the causes of the Great Depression and its influence on the rise of totalitarian dictators and determine the common characteristics of these dictators."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.5e",
    "Description": "Human atrocities and mass murders occurred in this time period."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": 1.05,
    "Description": "Students will examine the atrocities against the Armenians; examine the Ukrainian Holodomor, and examine the Holocaust."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6a",
    "Description": "The Cold War originated from tensions near the end of World War II as plans for peace were made and implemented. The Cold War was characterized by competition for power and ideological differences between the United States and the Soviet Union."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6a-1",
    "Description": "Students will compare and contrast how peace was conceived at Yalta and Potsdam with what happened in Europe in the four years after World War II (i.e., Soviet occupation of Eastern Europe, Truman Doctrine, Berlin blockade, NATO)."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6b",
    "Description": "The Cold War was a period of confrontations and attempts at peaceful coexistence."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6b-1",
    "Description": "Students will investigate the efforts to expand and contain communism in Cuba, Vietnam, and Afghanistan from multiple perspectives."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6b-2",
    "Description": "Students will examine the new military alliances, nuclear proliferation, and the rise of the military-industrial complex."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6b-3",
    "Description": "Students will examine the reasons countries such as Egypt and India chose nonalignment."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6b-4",
    "Description": "Students will explore the era of détente from both American and Soviet perspectives."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6c",
    "Description": "The end of the Cold War and the collapse of the communist bloc in Europe had a global impact."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6c-1",
    "Description": "Students will investigate the political reforms of glasnost and economic reforms of perestroika."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.6c-2",
    "Description": "Students will examine the impacts of those reforms within the Soviet Union, on the Soviet communist bloc, and in the world."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7a",
    "Description": "Independence movements in India and Indochina developed in response to European control."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7a-1",
    "Description": "Students will explore Gandhi’s nonviolent nationalist movement and nationalist efforts led by the Muslim League aimed at the masses that resulted in a British-partitioned subcontinent."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7a-2",
    "Description": "Students will compare and contrast the ideologies and methodologies of Gandhi and Ho Chi Minh as nationalist leaders."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7b",
    "Description": "African independence movements gained strength as European states struggled economically after World War II. European efforts to limit African nationalist movements were often unsuccessful."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7b-1",
    "Description": "Students will explore at least two of these three African independence movements: Ghana, Algeria, Kenya."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7c",
    "Description": "Nationalism in the Middle East was often influenced by factors such as religious beliefs and secularism."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7c-1",
    "Description": "Students will investigate Zionism, the mandates created at the end of World War I, and Arab nationalism."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7c-2",
    "Description": "Students will examine the creation of the State of Israel and the Arab-Israeli conflict."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7d",
    "Description": "Nationalism in China influenced the removal of the imperial regime, led to numerous conflicts, and resulted in the formation of the communist People’s Republic of China."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7d-1",
    "Description": "Students will trace the Chinese Civil War, including the role of warlords, nationalists, communists, and the world wars that resulted in the division of China into a communist-run People’s Republic of China and a nationalist-run Taiwan."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.7d-2",
    "Description": "Students will investigate political, economic, and social policies under Mao Zedong and Deng Xiaoping and compare and contrast these policies."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.8a",
    "Description": "Cultures and countries experience and view modernization differently. For some, it is a change from a traditional rural, agrarian condition to a secular, urban, industrial condition. Some see modernization as a potential threat and others as an opportunity to be met."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.8a1",
    "Description": "Students will investigate the extent to which urbanization and industrialization have modified the roles of social institutions such as family, religion, education, and government by examining one case study in each of these regions: Africa (e.g., Zimbabwe, Kenya, Nigeria, Sierra Leone), Latin America (e.g., Brazil, Argentina, Chile, Mexico), and Asia (e.g., China, India, Indonesia, South Korea)."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.8b",
    "Description": "Tensions between agents of modernization and traditional cultures have resulted in ongoing debates within affected societies regarding social norms, gender roles, and the role of authorities and institutions."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.8b-1",
    "Description": "Students will investigate, compare, and contrast tensions between modernization and traditional culture in Turkey under the rule of Kemal Atatürk and in Iran under the Pahlavis and the Ayatollahs."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.8b-2",
    "Description": "Students will explore how changes in technology, such as communication and transportation, have affected interactions between people and those in authority (e.g., efforts to affect change in government policy, engage people in the political process including use of social media, control access to information, and use terrorism as a tactic)."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9a",
    "Description": "Technological changes in communication and transportation systems allow for instantaneous interconnections and new networks of exchange between people and places that have lessened the effects of time and distance."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9a-1",
    "Description": "Students will explore how information is accessed, exchanged, and controlled and how business is conducted in light of changing technology."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9a-2",
    "Description": "Students will investigate the causes and effects of, and responses to, one infectious disease (e.g., malaria, HIV/AIDS)."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9b",
    "Description": "Globalization is contentious, supported by some and criticized by others."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9b-1",
    "Description": "Students will compare and contrast arguments supporting and criticizing globalization by examining concerns including: free market, export-oriented economies vs. localized, sustainable activities development of a mixed economy in China and China’s role in the global economy multinational corporations and cartels (e.g., Organization of Petroleum Exporting Countries) roles of the World Trade Organization, the World Bank, the International Monetary Fund, and microfinance institutions economic growth and economic downturns (e.g., recession, depression) on a national and a global scale economic development and inequality (e.g., access to water, food, education, health care, energy) migration and labor ethnic diversity vs. homogenization (e.g., shopping malls, fast food franchises, language, popular culture)"
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9c",
    "Description": "Population pressures, industrialization, and urbanization have increased demands for limited natural resources and food resources, often straining the environment."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9c-1",
    "Description": "Students will examine how the world’s population is growing exponentially for numerous reasons and how it is not evenly distributed."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9c-2",
    "Description": "Students will explore efforts to increase and intensify food production through industrial agriculture (e.g., Green Revolutions, use of fertilizers and pesticides, irrigation, and genetic modifications)."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9c-3",
    "Description": "Students will examine strains on the environment, such as threats to wildlife and degradation of the physical environment (i.e., desertification, deforestation and pollution) due to population growth, industrialization, and urbanization."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9d",
    "Description": "Globalization has created new possibilities for international cooperation and for international conflict."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9d-1",
    "Description": "Students will examine the roles of the United Nations (UN), North Atlantic Treaty Organization (NATO), the European Union, nongovernmental organizations (NGOs), and efforts to build coalitions to promote international cooperation to address conflicts and issues. They will also examine the extent to which these efforts were successful."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9d-2",
    "Description": "Students will investigate one organization and one international action that sought to provide solutions to environmental issues, including the Kyoto Protocol."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.9d-3",
    "Description": "Students will examine threats to global security, such as international trade in weapons (e.g., chemical, biological, and nuclear), nuclear proliferation, cyber war, and terrorism, including a discussion of the events of September 11, 2001"
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10a",
    "Description": "Following World War II, the United Nations Universal Declaration of Human Rights -1948 was written. This provides a set of principles to guide efforts to protect threatened groups."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10a-1",
    "Description": "Students will investigate and analyze the historical context of the Holocaust, Nuremberg Trials, and Tokyo Trials and their impacts on the UN Universal Declaration of Human Rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10a-2",
    "Description": "Students will examine the articles contained in the UN Universal Declaration of Human Rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10b",
    "Description": "Governments, groups, and individuals have responded in various ways to the human atrocities committed in the 20th and 21st centuries."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10b-1",
    "Description": "Students will explore multinational treaties and international court systems that bind countries to adhere to international human rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10b-2",
    "Description": "Students will explore international organizations that work to maintain peace, stability, and economic prosperity, and to protect nations and people from oppressive governments and political violence."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10c",
    "Description": "Historical and contemporary violations of human rights can be evaluated, using the principles and articles established within the UN Universal Declaration of Human Rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10c-1",
    "Description": "Students will examine the atrocities committed under Augusto Pinochet, Deng Xiaoping, and Slobodan Milosevic in light of the principles and articles within the UN Universal Declaration of Human Rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10c-2",
    "Description": "Students will examine and analyze the roles of perpetrators and bystanders in human rights violations in Cambodia, Rwanda, and Darfur in light of the principles and articles within the UN Universal Declaration of Human Rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10c-3",
    "Description": "Students will examine the policy of apartheid in South Africa and the growth of the anti-apartheid movements, exploring Nelson Mandela’s role in these movements and in the post-apartheid period."
  },
  {
    "Course": "Social Studies",
    "Grade": 10,
    "Standard": "10.10c-4",
    "Description": "Students will explore efforts to address human rights violations by individuals and groups, including the efforts of Mother Teresa, Aung San Suu Kyi, and the Mothers of the Plaza de Mayo."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1a",
    "Description": "Contact between Native American∗ groups and Europeans occurred through cultural exchanges, resistance efforts, and conflict."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1a-1",
    "Description": "Students will trace European contact with Native Americans, including the Dutch, the English, the French and the Spanish."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1a-2",
    "Description": "Students will examine the impacts of European colonization on Native Americans, who eventually lost much of their land and experienced a drastic decline in population through diseases and armed conflict."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1b",
    "Description": "A number of factors influenced colonial economic development, social structures, and labor systems, causing variation by region."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1b-1",
    "Description": "Students will examine the impacts of geographic factors on patterns of settlement and the development of colonial economic systems."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1b-2",
    "Description": "Students will examine the factors influencing variations in colonial social structures and labor systems."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1b-3",
    "Description": "Students will analyze slavery as a deeply established component of the colonial economic system and social structure, indentured servitude vs. slavery, the increased concentration of slaves in the South, and the development of slavery as a racial institution."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1c",
    "Description": "Colonial political developments were influenced by British political traditions, Enlightenment ideas, and the colonial experience. Self-governing structures were common, and yet varied across the colonies."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1c-1",
    "Description": "Students will examine colonial political institutions to determine how they were influenced by Enlightenment ideas, British traditions such as the Magna Carta, and the colonial experience."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.1c-2",
    "Description": "Students will examine colonial democratic principles by studying documents such as the Mayflower Compact and the Maryland Toleration Act of 1649, colonial governmental structures such as New England town meetings and the Virginia House of Burgesses, and the practice of the right of petition in New Netherland."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2a",
    "Description": "Following the French and Indian War, the British government attempted to gain greater political and economic control over the colonies. Colonists resisted these efforts, leading to increasing tensions between the colonists and the British government."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2a-1",
    "Description": "Students will examine British efforts to gain greater political and economic control, such as the Proclamation of 1763, the Stamp Act, the Townsend Acts, the Tea Act, the Boston Massacre, and the Coercive Acts, and colonial reactions to these efforts."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2b",
    "Description": "Failed attempts to mitigate the conflicts between the British government and the colonists led the colonists to declare independence, which they eventually won through the Revolutionary War, which affected individuals in different ways."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2b-1",
    "Description": "Students will examine the purpose of and the ideas contained in the Declaration of Independence and consider its long term impacts."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2b-2",
    "Description": "Students will examine the impacts of the Revolutionary War on workers, African Americans, women, and Native Americans."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2c",
    "Description": "Weaknesses of the Articles of Confederation led to a convention whose purpose was to revise the Articles of Confederation but instead resulted in the writing of a new Constitution. The ratification debate over the proposed Constitution led the Federalists to agree to add a bill of rights to the Constitution."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2c-1",
    "Description": "Students will examine the weaknesses and successes of government under the Articles of Confederation."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2c-2",
    "Description": "Students will explore the development of the Constitution, including the major debates and their resolutions, which included compromises over representation, taxation, and slavery."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2c-3",
    "Description": "Students will examine the structure, power, and function of the federal government as created by the Constitution, including key constitutional principles such as the division of power between federal and state government, the separation of powers at the federal level, the creation of checks and balances, the sovereignty of the people, and judicial independence."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2c-4",
    "Description": "Students will examine the key points of debate expressed in the Federalist Papers and the Antifederalist Papers, focusing on the protection of individual rights and the proper size for a republic."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2c-5",
    "Description": "Students will examine the rights and protections provided by the Bill of Rights and to whom they initially applied."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2d",
    "Description": "Under the new Constitution, the young nation sought to achieve national security and political stability, as the three branches of government established their relationships with each other and the states."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2d-1",
    "Description": "Students will identify presidential actions and precedents established by George Washington, including those articulated in his Farewell Address."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2d-2",
    "Description": "Students will examine Hamilton’s economic plan, the debate surrounding the plan, and its impacts on the development of political parties."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2d-3",
    "Description": "Students will examine the tradition of a peaceful transfer of power established in the presidential election of 1800 and compare it to the presidential election of 2000, focusing on the roles of the Electoral College and Congress in 1800 and the Electoral College and the Supreme Court in 2000"
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.2d-4",
    "Description": "Students will examine Supreme Court cases, including Marbury v. Madison, McCulloch v. Maryland, and Gibbons v. Ogden, and analyze how these decisions strengthened the powers of the federal government."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3a",
    "Description": "American nationalism was both strengthened and challenged by territorial expansion and economic growth."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3a-1",
    "Description": "Students will examine how the Louisiana Purchase, the War of 1812, and the Monroe Doctrine strengthened nationalism."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3a-2",
    "Description": "Students will examine the market revolution, including technological developments, the development of transportation networks, the growth of domestic industries, the increased demands for free and enslaved labor, the changing role of women, and the rise of political democracy."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3a-3",
    "Description": "Students will examine Jackson’s presidency, noting the ways it strengthened presidential power yet challenged constitutional principles in the case of Worcester v. Georgia (1832), including the controversy concerning the Indian Removal Act and its implementation."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3b",
    "Description": "Different perspectives concerning constitutional, political, economic, and social issues contributed to the growth of sectionalism."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3b-1",
    "Description": "Students will compare different perspectives on States rights by examining the Kentucky and Virginia Resolutions and the nullification crisis."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3b-2",
    "Description": "Students will investigate the development of the abolitionist movement, focusing on Nat Turner’s Rebellion, Sojourner Truth, William Lloyd Garrison (The Liberator), Frederick Douglass (The Autobiography of Frederick Douglass and The North Star), and Harriet Beecher Stowe (Uncle Tom’s Cabin)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3b-3",
    "Description": "Students will examine the emergence of the women’s rights movement out of the abolitionist movement, including the role of the Grimké sisters, Lucretia Mott, and Elizabeth Cady Stanton, and evaluate the demands made at the Seneca Falls Convention (1848)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3b-4",
    "Description": "Students will examine the issues surrounding the expansion of slavery into new territories, by exploring the Missouri Compromise, Manifest Destiny, Texas and the Mexican-American war, the Compromise of 1850, the Kansas-Nebraska Act, the Dred Scott decision, and John Brown’s raid."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3c",
    "Description": "Long-standing disputes over States rights and slavery and the secession of Southern states from the Union, sparked by the election of Abraham Lincoln, led to the Civil War. After the issuance of the Emancipation Proclamation, freeing the slaves became a major Union goal. The Civil War resulted in tremendous human loss and physical destruction."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3c-1",
    "Description": "Students will compare the relative strengths of the Union and the Confederacy in terms of industrial capacity, transportation facilities, and military leadership, and evaluate the reasons why the North prevailed over the South and the impacts of the war."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3c-2",
    "Description": "Students will examine the expansion of executive and federal power as they relate to the suspension of habeas corpus within the Union and the issuance of the Emancipation Proclamation."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.3c-3",
    "Description": "Students will analyze the ideas expressed in the Gettysburg Address, considering its long-term effects."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4a",
    "Description": "Between 1865 and 1900, constitutional rights were extended to African Americans. However, their ability to exercise these rights was undermined by individuals, groups, and government institutions."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4a-1",
    "Description": "Students will examine the 13th, 14th, and 15th amendments and consider the role of Radical Republicans in Reconstruction."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4a-2",
    "Description": "Students will investigate the ways individuals, groups, and government institutions limited the rights of African Americans, including the use of Black Codes, the passage of Jim Crow laws, the Ku Klux Klan, restrictions on voting rights, and Supreme Court cases including the Civil Rights Cases -1883 and Plessy v. Ferguson (1896)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4a-3",
    "Description": "Students will examine the ways in which freedmen attempted to build independent lives, including the activities of the Freedmen’s Bureau, the creation of educational institutions, and political participation."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4a-4",
    "Description": "Students will examine the impacts of the election of 1876 and the compromise of 1877on African Americans."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4b",
    "Description": "The 14th and 15th amendments failed to address the rights of women."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4b-1",
    "Description": "Students will examine the exclusion of women from the 14th and 15th amendments and the subsequent struggle for voting and increased property rights in the late 19th century. The students will examine the work of Susan B. Anthony."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4c",
    "Description": "Federal policies regarding westward expansion had positive effects on the national economy but negative consequences for Native Americans."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4c-1",
    "Description": "Students will examine the economic effects of the Homestead Act -1862 and the Pacific Railway Act -1862 on westward expansion."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4c-2",
    "Description": "Students will examine the effect of federal policies on Native Americans on the Great Plains, including reservation policies, the Dawes Act (1887), and forced acculturation efforts (Carlisle Indian School)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4d",
    "Description": "Racial and economic motives contributed to long-standing discrimination against Mexican Americans and opposition to Chinese immigration."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4d-1",
    "Description": "Students will analyze relevant provisions of the Treaty of Guadalupe Hidalgo as compared with the actual treatment of Mexicans and Mexican Americans in the Southwest, including California, from 1848 to 1900"
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.4d-2",
    "Description": "Students will examine the contributions of Chinese to the national economy and reasons for nativist opposition to their continued immigration (Chinese Exclusion Act of 1882)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5a",
    "Description": "New technologies and economic models created rapid industrial growth and transformed the United States."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5a-1",
    "Description": "Students will examine the technological innovations that facilitated industrialization, considering energy sources, natural resources, transportation, and communication."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5a-2",
    "Description": "Students will examine the growth of industries under the leadership of businessmen such as John D. Rockefeller, Andrew Carnegie, J.P. Morgan, and Henry Ford and analyze their business practices and organizational structures."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5a-3",
    "Description": "Students will evaluate the effectiveness of state and federal attempts to regulate business by examining the Supreme Court decision in Wabash, St. Louis & Pacific R.R. v. Illinois (1886), the Interstate Commerce Act (1887), the Sherman Antitrust Act (1890), and President Theodore Roosevelt’s trust-busting role as evidenced in Northern Securities Co. v. United States (1904)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5b",
    "Description": "Rapid industrialization and urbanization created significant challenges and societal problems that were addressed by a variety of reform efforts."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5b-1",
    "Description": "Students will examine demographic trends associated with urbanization and immigration between 1840 and 1920, including push-pull factors regarding Irish immigration and immigration from southern and eastern Europe."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5b-2",
    "Description": "Students will examine problems faced by farmers between 1870 and 1900 and examine the goals and achievements of the Grange Movement and the Populist Party."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5b-3",
    "Description": "Students will examine the attempts of workers to unionize from 1870 to 1920 in response to industrial working conditions, including the Knights of Labor, the American Federation of Labor, the American Railway Union, the International Ladies Garment Workers’ Union, and the Industrial Workers of the World, considering actions taken by the unions and the responses to these actions."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5b-4",
    "Description": "Students will examine Progressive Era reforms, such as the 16th and 17th amendments -1913 and the establishment of the Federal Reserve System (1913)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5b-5",
    "Description": "Students will examine the efforts of the woman’s suffrage movement after 1900, leading to ratification of the 19th amendment (1920)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5b-6",
    "Description": "Students will trace the temperance and prohibition movements leading to the ratification of the 18th amendment (1919)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.5b-7",
    "Description": "Students will trace reform efforts by individuals and the consequences of those efforts, including:, Jane Addams and Hull House, Jacob Riis’ How the Other Half Lives, New York Governor Theodore Roosevelt and the Tenement Reform Commission, Upton Sinclair’s The Jungle and the Meat Inspection Act, Margaret Sanger and birth control, Ida Tarbell’s The History of the Standard Oil Company, Ida Wells and her writings about lynching of African Americans, Booker T. Washington’s contributions to education, including the creation of Tuskegee Institute, W. E. B. Du Bois and the founding of the National Association for the Advancement of Colored People (NAACP), the publication of The Crisis, and the Silent Protest -1917"
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6a",
    "Description": "In the late 1800s, various strategic and economic factors led to a greater focus on foreign affairs and debates over the United States’ role in the world."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6a-1",
    "Description": "Students will examine factors such as the economic and strategic interests that led the United States to seek foreign markets, resources, and coaling stations, including interest in Hawaii."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6a-2",
    "Description": "Students will investigate the causes and effects of the Spanish-American War, evaluating Spanish, Cuban, and United States interests and actions."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6a-3",
    "Description": "Students will examine debates between anti-imperialists and imperialists surrounding ratification of the Treaty of Paris of 1898 and annexation of the Philippines."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6a-4",
    "Description": "Students will investigate expanding American influence in the Caribbean and Latin America through the creation of the Panama Canal and the Roosevelt Corollary."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6b",
    "Description": "While the United States attempted to follow its traditional policy of neutrality at the beginning of World War I, the nation eventually became involved in the war. President Woodrow Wilson led the nation into war with the hope of reforming the international order through his Fourteen Points."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6b-1",
    "Description": "Students will investigate the reasons for President Wilson’s shift from neutrality to involvement in World War I."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6b-2",
    "Description": "Students will examine Wilson’s goals as expressed in the Fourteen Points, his role at the Versailles Peace Conference, and the compromises he was forced to make to gain approval for the League of Nations."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6b-3",
    "Description": "Students will examine the reasons why President Wilson was unsuccessful in gaining support for Senate ratification of the Treaty of Versailles."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6c",
    "Description": "World War I had important social, political, and economic effects on American society."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6c-1",
    "Description": "Students will investigate the effects of mobilization on the United States economy, including the role and contributions of women and African Americans in the war effort."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6c-2",
    "Description": "Students will investigate the causes and effects of the Great Migration on American society."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6c-3",
    "Description": "Students will examine the Supreme Court decision concerning civil liberties in Schenck v. United States (1919)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.6c-4",
    "Description": "Students will examine the relationship between postwar recession, fear of radicals, xenophobia, and the Red Scare (1919–1921)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7a",
    "Description": "The 1920s was a time of cultural change in the country, characterized by clashes between modern and traditional values."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7a-1",
    "Description": "Students will examine the cultural trends associated with the Roaring Twenties, including women’s efforts at self-expression and their changing roles."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7a-2",
    "Description": "Students will examine the impact of Prohibition on American society."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7a-3",
    "Description": "Students will examine change in immigration policy as reflected by the passage of the Quota Acts of the 1920s."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7a-4",
    "Description": "Students will examine the reasons for the resurgence of the Ku Klux Klan."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7a-5",
    "Description": "Students will examine the key issues related to the Scopes trial."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7b",
    "Description": "African Americans continued to struggle for social and economic equality while expanding their own thriving and unique culture. African American cultural achievements were increasingly integrated into national culture."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7b-1",
    "Description": "Students will examine literary and artistic contributions associated with the Harlem Renaissance and its impact on national culture."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7b-2",
    "Description": "Students will examine the rise of African American racial pride and Black Nationalism, including the role of Marcus Garvey."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7c",
    "Description": "For many Americans, the 1920s was a time of prosperity. However, underlying economic problems, reflected in the stock market crash of 1929, led to the Great Depression. President Franklin D. Roosevelt’s responses to the Great Depression increased the role of the federal government."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7c-1",
    "Description": "Students will examine the reasons for economic prosperity during the 1920s."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7c-2",
    "Description": "Students will examine the underlying weaknesses of the economy that led to the stock market crash of 1929 and the Great Depression."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7c-3",
    "Description": "Students will compare and contrast the responses of Presidents Herbert Hoover and Franklin D. Roosevelt to the Great Depression."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7c-4",
    "Description": "Students will examine the human and environmental causes of the Dust Bowl and its effects."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.7c-5",
    "Description": "Students will evaluate President Roosevelt’s leadership during the Depression, including key legislative initiatives of the New Deal, expansion of federal government power, and the constitutional challenge represented by his court-packing effort."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8a",
    "Description": "As situations overseas deteriorated, President Roosevelt’s leadership helped to move the nation from a policy of neutrality to a pro-Allied position and, ultimately, direct involvement in the war."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8a-1",
    "Description": "Students will examine reasons for the passage of the Neutrality Acts (1935–1937) and consider the national debate as a shift to pro-Allied policies, including “cash and carry” and Lend-Lease."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8a-2",
    "Description": "Students will trace ongoing negotiations with Japan and United States efforts to stop Japanese aggression without resorting to war and without appeasing Japanese demands."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8a-3",
    "Description": "Students will examine the impact of the Japanese attack on Pearl Harbor."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8a-4",
    "Description": "Students will examine President Roosevelt’s leadership during World War II, including his role as commander in chief and his diplomatic efforts to maintain the Grand Alliance."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8a-5",
    "Description": "Students will examine how technological advancements altered the nature of war and the extent of its devastation, including the use of air power over civilian targets and President Truman’s decision to use the atomic bomb against the Japanese cities of Hiroshima and Nagasaki."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8b",
    "Description": "United States entry into World War II had a significant impact on American society."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8b-1",
    "Description": "Students will examine United States mobilization efforts and wartime production and their effects on unemployment rates."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8b-2",
    "Description": "Students will examine the reasons for President Roosevelt’s executive order for Japanese removal, the impact of removal on Japanese people living in the United States, and the Supreme Court’s decision in Korematsu v. United States (1944)."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8b-3",
    "Description": "Students will examine the contributions of women, African Americans, Native Americans, Asian Americans, Mexican workers, and Mexican Americans to the war effort, as well as the discrimination that they experienced in the military and workforce."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8c",
    "Description": "In response to World War II and the Holocaust, the United States played a major role in efforts to prevent such human suffering in the future."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8c-1",
    "Description": "Students will investigate American officials' knowledge of the Holocaust, evaluating the degree to which intervention may have been possible."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8c-2",
    "Description": "Students will examine the contributions of Supreme Court Justice Robert Jackson and his arguments made as Chief Prosecutor for the United States at the Nuremberg War Crimes trials."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.8c-3",
    "Description": "Students will investigate the role of Eleanor Roosevelt in creating the United Nations Universal Declaration of Human Rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9a",
    "Description": "After World War II, ideological differences led to political tensions between the United States and the Soviet Union. In an attempt to halt the spread of Soviet influence, the United States pursued a policy of containment."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9a-1",
    "Description": "Students will trace key decisions made at wartime conferences as they applied to Poland, Eastern Europe, and postwar Germany, and note how continuing disagreements over these decisions helped bring about the start of the Cold War."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9a-2",
    "Description": "Students will trace United States containment policies, including the Truman Doctrine (1947), the Marshall Plan (1948), and the North Atlantic Treaty Organization (1949), and actions taken during the Berlin blockade, and consider how they represent a shift in American foreign policy."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9a-3",
    "Description": "Students will examine domestic concerns about the spread of communism and the rise of McCarthyism."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9a-4",
    "Description": "Students will examine the consequences of Truman’s decision to fight a limited war in defense of South Korea."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9a-5",
    "Description": "Students will trace the United States involvement in Vietnam, including President Johnson’s decision to escalate the fighting in Vietnam."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9a-6",
    "Description": "Students will examine reasons for declining public confidence in government, including America’s involvement in Vietnam, student protests, the growing antiwar movement, and the Watergate affair."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9a-7",
    "Description": "Students will examine the congressional effort to limit presidential power through the War Powers Act."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9b",
    "Description": "The United States and the Soviet Union engaged in a nuclear arms race that eventually led to agreements that limited the arms buildup and improved United States-Soviet relations."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9b-1",
    "Description": "Students will trace the acceleration of the nuclear arms race, beginning with from the detonation of an atomic bomb by the Soviet Union in 1949, through 1969, including the effects of Sputnik and the Space Race."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9b-2",
    "Description": "Students will examine Soviet motives for placing missiles in Cuba and the impact of the Cuban missile crisis on Soviet-American relations, leading to the adoption of the Nuclear Test Ban Treaty."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9b-3",
    "Description": "Students will examine the policy of détente and its effect on the nuclear arms race."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9c",
    "Description": "American strategic interests in the Middle East grew with the Cold War, the creation of the State of Israel, and the increased United States dependence on Middle Eastern oil. The continuing nature of the Arab-Israeli dispute has helped to define the contours of American policy in the Middle East."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9c-1",
    "Description": "Students will examine United States foreign policy toward the Middle East, including the recognition of and support for the State of Israel, the Camp David Accords, and the interaction with radical groups in the region."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9d",
    "Description": "A combination of factors contributed to the end of the Cold War, including American policies and Soviet economic and political problems that led to the loss of Soviet control over Eastern Europe."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.9d-1",
    "Description": "Students will trace factors that led to the fall of the Berlin Wall and the end of the Cold War, including American policies, Soviet economic problems, Soviet efforts at reform, and the loss of Soviet control over Eastern Europe."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10a",
    "Description": "After World War II, long-term demands for equality by African Americans led to the civil rights movement. The efforts of individuals, groups, and institutions helped to redefine African American civil rights, though numerous issues remain unresolved."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10a-1",
    "Description": "Students will examine the roles and impact of individuals such as Rev. Martin Luther King Jr., Stokely Carmichael, Fannie Lou Hamer, and Malcolm X on the movement and their perspectives on change."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10a-2",
    "Description": "Students will examine the role of groups such as the National Association for the Advancement of Colored People (NAACP), Southern Christian Leadership Conference (SCLC), and Student Non-Violent Coordinating Committee (SNCC) in the movement, their goals and strategies, and major contributions."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10a-3",
    "Description": "Students will examine judicial actions and legislative achievements during the movement, such as Brown v. Board of Education of Topeka (1954), the Civil Rights Act of 1964, Heart of Atlanta Motel, Inc. v. United States -1964 and the Voting Rights Act of 1965"
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10a-4",
    "Description": "Students will analyze the significance of key events in the movement, including the Montgomery bus boycott, federal intervention at Little Rock, Arkansas; the Birmingham protest; and the March on Washington."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10b",
    "Description": "Individuals, diverse groups, and organizations have sought to bring about change in American society through a variety of methods."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10b-1",
    "Description": "Students will trace the following efforts in terms of issues/goals, key individuals and groups, and successes/limitations: Modern women’s movement (e.g., The Feminine Mystique [1963], National Organization for Women , Equal Pay Act and Title IX, Roe v. Wade), Native Americans (e.g., American Indian Movement, Russell Means, native identity, and land claims), Brown Power (Chicano) movement (e.g., Cesar Chavez, United Farm Workers), People with disabilities (e.g. Individuals with Disabilities Education Act [1975], Americans with Disabilities Act [1990] ), Rights of the accused (e.g., Mapp v. Ohio [1961], Gideon v. Wainwright [1963], Miranda v. Arizona [1966]), Immigration (e.g., Immigration Act of 1965, Immigration Act of 1986, continuing debates over immigration reform), Gay Rights and the LGBT movement (e.g., Stonewall Inn riots [1969], efforts for equal legal rights), Environment (e.g., Silent Spring [1962], Clean Air Act of 1970, Clean Water Act of 1972, Endangered Species Act of 1973, Environmental protection Agency [1970], Reagan’s policy), Student rights (e.g., Engel v. Vitale [1962], Tinker v. Des Moines School District [1969], New Jersey v. TLO [1985]), Students will thoroughly investigate at least one of the efforts above."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10c",
    "Description": "Varying political philosophies prompted debates over the role of the federal government in regulating the economy and providing a social safety net."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10c-1",
    "Description": "Students will compare and contrast the economic policies of President Johnson (Great Society) and President Reagan (Reaganomics) regarding the size and role of the federal government."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10c-2",
    "Description": "Students will examine the causes of the financial panic of 2008 and the federal government‘s response to the Great Recession."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.10c-3",
    "Description": "Students will examine the debates over the role of the government in providing a social safety net, including the stability of the Social Security Trust Fund and Medicare Trust Fund, as well as changes under the Affordable Care Act."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11a",
    "Description": "The United States created a coalition to defeat Iraq in the Persian Gulf War (1991), but was reluctant to commit American military power through the rest of the decade."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11a-1",
    "Description": "Students will examine the decision of President George H. W. Bush to oppose Iraq’s invasion of Kuwait. Students will evaluate the positive and negative consequences of the Persian Gulf War."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11a-2",
    "Description": "Students will trace United States foreign policy regarding Bosnia, Rwanda, and Kosovo, exploring the tension between defending human rights and the reluctance to intervene stemming from the Vietnam syndrome."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11b",
    "Description": "In response to the terrorist attacks of September 11, 2001, the United States launched the War on Terror, which involved controversial foreign and domestic policies."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11b-1",
    "Description": "Students will trace the reactions to the September 11, 2001, attacks, including responses of the American public, the authorization of the War on Terror, the invasion of Afghanistan, and the passage of the USA PATRIOT Act."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11b-2",
    "Description": "Students will examine the decision to invade Iraq, which was based on allegations concerning weapons of mass destruction, and trace the course of the war."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11b-3",
    "Description": "Students will evaluate the USA PATRIOT Act, including constitutional issues raised about the violation of civil liberties by the federal government’s electronic surveillance programs."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11c",
    "Description": "Globalization and advances in technology have affected the United States economy and society."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11c-1",
    "Description": "Students will examine the positive and negative consequences of globalization in relation to the United States economy."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11c-2",
    "Description": "Students will investigate the role of multinational corporations and their influence on both the United States economy and on other countries around the world."
  },
  {
    "Course": "Social Studies",
    "Grade": 11,
    "Standard": "11.11c-3",
    "Description": "Students will examine the economic relationship and the strategic rivalry between the United States and China."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G1",
    "Description": "FOUNDATIONS of AMERICAN DEMOCRACY: The principles of American democracy are reflected in the Constitution and the Bill of Rights and in the organization and actions of federal, state, and local government entities. The interpretation and application of American democratic principles continue to evolve and be debated."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G1a",
    "Description": "Enlightenment ideas such as natural rights, the social contract, popular sovereignty, and representative government greatly influenced the framers of the Constitution and Bill of Rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G1b",
    "Description": "The Constitution created a unique political system that distributes powers and responsibilities among three different branches of government at the federal level and between state and federal governments. State constitutions address similar structures and responsibilities for their localities."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G1c",
    "Description": "Limited government is achieved through the separation of powers between three different branches. The system of checks and balances is part of this limited government structure at all levels of government."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G1d",
    "Description": "The rule of law is a system in which no one, including government, is above the law. The United States legal system has evolved over time as the result of implementation and interpretation of common law, constitutional law, statutory law, and administrative regulations."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G1e",
    "Description": "The powers not delegated specifically in the Constitution are reserved to the states. Though the powers and responsibilities of the federal government have expanded over time, there is an ongoing debate over this shift in power and responsibility."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G1f",
    "Description": "The Constitution includes a clearly defined and intentionally rigorous process for amendment. This process requires state and federal participation, and allows the Constitution to evolve and change."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G2",
    "Description": "CIVIL RIGHTS and CIVIL LIBERTIES: The United States Constitution aims to protect individual freedoms and rights that have been extended to more groups of people over time. These rights and freedoms continue to be debated, extended to additional people, and defined through judicial interpretation. In engaging in issues of civic debate, citizens act with an appreciation of differences and are able to participate in constructive dialogue with those who hold different perspectives."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G2a",
    "Description": "Equality before the law and due process are two fundamental values that apply to all under the jurisdiction of the United States. While the United States legal system aims to uphold the values of equality before the law, due process, human dignity, freedom of conscience, inalienable rights, and civility, the extent to which the legal system upholds these values in practice is an issue of ongoing civic debate."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G2b",
    "Description": "The Constitution aims to protect, among other freedoms, individual and group rights to freedom of speech, freedom of the press, freedom of assembly, freedom of petition, and freedom of religion. The extent to which these ideals exist in practice and how these protections should be applied in a changing world continues to be an issue of ongoing civic debate."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G2c",
    "Description": "An independent judicial system is an integral part of the process that interprets and defends citizens’ freedoms and rights. Issues pertaining to the flexibility of judicial interpretation and the impartiality of justices in practice are continued sources of public debate."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G2d",
    "Description": "The definition of civil rights has broadened over the course of United States history, and the number of people and groups legally ensured of these rights has also expanded. However, the degree to which rights extend equally and fairly to all (e.g., race, class, gender, sexual orientation) is a continued source of civic contention."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G2e",
    "Description": "Rights are not absolute; they vary with legal status, with location (as in schools and workplaces), and with circumstance. The different statuses of United States residency bring with them specific protections, rights, and responsibilities. Minors have specific rights in school, in the workplace, in the community, and in the family. The extension of rights across location, circumstance, age, and legal status is a subject of civic discourse."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G2f",
    "Description": "Freedom of the press is an essential element of a democratic system, and allows for a citizen to receive and interpret information representing different points of view. Freedom of the press has limits, which are intended to protect the rights of individuals and other entities. The degree to which the press is free and impartial in practice is a source of ongoing debate."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G3",
    "Description": "RIGHTS, RESPONSIBILITIES, AND DUTIES OF CITIZENSHIP: Active, engaged, and informed citizens are critical to the success of the United States representative democracy. United States citizens have certain rights, responsibilities, and duties, the fulfillment of which help to maintain the healthy functioning of the national, state, and local communities."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G3a",
    "Description": "Citizens should be informed about rights and freedoms, and committed to balancing personal liberties with a social responsibility to others."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G3b",
    "Description": "The right to vote, a cornerstone of democracy, is the most direct way for citizens to participate in the government. A citizen must register to vote, and may register as a party member or select the status of independent."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G3c",
    "Description": "Citizens have opportunities to both participate in and influence their communities and country. Citizens contribute to government processes through legal obligations such as obeying laws, paying taxes, serving on juries, and registering for selective service."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G3d",
    "Description": "Citizens have the right to represent their locality, state, or country as elected officials. Candidates develop a campaign, when they decide to seek public office."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G4",
    "Description": "POLITICAL AND CIVIC PARTICIPATION: There are numerous avenues for engagement in the political process, from exercising the power of the vote, to affiliating with political parties, to engaging in other forms of civic participation. Citizens leverage both electoral and non-electoral means to participate in the political process."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G4a",
    "Description": "Depending on the level of government and type of election, there are different processes and mechanisms within the United States electoral and representational systems, including the electoral college and winner-take all systems. Advantages and drawbacks of election processes and mechanisms continue to be an issue of ongoing debate in the United States."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G4b",
    "Description": "Allowing citizens to vote does not ensure that a system is a democracy. Open, safe, and honest elections are essential to a democratic system. Engaged and informed citizens should know the mechanics associated with voting, including when major local, state, and national elections are held, how to register to vote, who currently holds each office, who is running for office, and what the central issues are pertaining to that election."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G4c",
    "Description": "In addition to voting, there are many ways in which citizens can participate in the electoral process. These include joining a political organization, donating money, and doing volunteer work on a political campaign."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G4d",
    "Description": "The United States and New York have political party systems, and the political parties represent specific political, economic, and social philosophies. Debate over the role and influence of political parties continues, although they play a significant role in United States elections and politics. The role of political parties and the platforms they represent varies between states in the United States."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G4e",
    "Description": "Citizens participate in civic life through volunteerism and advocacy, including efforts such as contacting elected officials, signing/organizing petitions, protesting, canvassing, and participating in/organizing boycotts."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G5",
    "Description": "PUBLIC POLICY: All levels of government—local, state, and federal—are involved in shaping public policy and responding to public policy issues, all of which influence our lives beyond what appears in the Constitution. Engaged citizens understand how to find, monitor, evaluate, and respond to information on public policy issues."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G5a",
    "Description": "Each level of government has its own process of shaping, implementing, amending, and enforcing public policy. Customarily the executive branch will outline its plan and agenda in an executive address to the legislative body."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G5b",
    "Description": "On various issues, certain governmental branches and agencies are responsible for determining policy. Those who create public policies attempt to balance regional and national needs, existing political positions and loyalties, and sources of political power."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G5c",
    "Description": "Successful implementation of government policy often requires cooperation between many levels of government, as well as the cooperation of other public and private institutions. Conflicts between different levels of government sometimes emerge due to different goals, ideas, and resources regarding the creation and implementation of policy."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G5d",
    "Description": "Active and engaged citizens must be effective media consumers in order to be able to find, monitor, and evaluate information on political issues. The media have different venues, which have particular strengths and serve distinct and shared purposes. Knowing how to critically evaluate a media source is fundamental to being an informed citizen."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.G5e",
    "Description": "INDIVIDUAL RESPONSIBILITY AND THE ECONOMY: Individuals should set personal financial goals, recognize their income needs and debt obligations, and know how to utilize effective budgeting, borrowing, and investment strategies to maximize well-being."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E1a",
    "Description": "In making economic decisions in any role, individuals should consider the set of opportunities that they have, their resources (e.g., income and wealth), their preferences, and their ethics."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E1b",
    "Description": "Sound personal financial (money management) practices take into account wealth and income, the present and the future, and risk factors when setting goals and budgeting for anticipated saving and spending. Cost-benefit analysis is an important tool for sound decision making. All financial investments carry with them varying risks and rewards that must be fully understood in order to make informed decisions. Greater rewards generally come with higher risks."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E1c",
    "Description": "Managing personal finance effectively requires an understanding of the forms and purposes of financial credit, the effects of personal debt, the role and impact of interest, and the distinction between nominal and real returns. Predatory lending practices target and affect those who are least informed and can least afford such practices. Interest rates reflect perceived risk, so maintaining a healthy credit rating lowers the cost of borrowing."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E1d",
    "Description": "To be an informed participant in the global economy, one must be aware of inflation and have an understanding of how international currencies fluctuate in value relative to the United States dollar."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": 12,
    "Description": "E2 INDIVIDUALS AND BUSINESSES IN THE PRODUCT AND FACTOR MARKETS: Free enterprise is a pillar of the United States economy and is based on the principle that individuals and businesses are free to make their own economic choices as they participate in these markets. Individuals buy the goods and services that they desire from businesses in the product markets, and they contribute to producing these goods and services by supplying the resources that they own to businesses in the factor markets."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E2a",
    "Description": "Given that the resources of individuals (and societies) are limited, decisions as to what goods and services will be produced and to whom to sell one’s resources are driven by numerous factors, including a desire to derive the maximum benefit from and thus the most efficient allocation of those resources."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E2b",
    "Description": "The choices of buyers and sellers in the marketplace determine supply and demand, market prices, allocation of scarce resources, and the goods and services that are produced. In a perfect world, consumers influence product availability and price through their purchasing power in the product market. Product market supply and demand determine product availability and pricing.\\"
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E2c",
    "Description": "Businesses choose what to supply in the product market, based on product market prices, available technology, and prices of factors of production. The prices of those factors are determined based on supply and demand in the factor market. The supply and demand of each factor market is directly related to employment. Debates surround various ways to minimize unemployment (frictional, structural, cyclical)."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E2d",
    "Description": "THE IMPACT OF AMERICAN CAPITALISM IN A GLOBAL ECONOMY: There are various economic systems in the world. The United States operates within a mixed, free market economy that is characterized by competition and a limited role of government in economic affairs. Economic policy makers face considerable challenges within a capitalist system, including unemployment, inflation, poverty, and environmental consequences. Globalization increases the complexity of these challenges significantly, and has exerted strong and transformative effects on workers and entrepreneurs in the United States economy."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E3a",
    "Description": "As the United States has evolved from an agrarian to an industrial to an information economy, the workplace requires a more highly skilled and educated workforce."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E3b",
    "Description": "The government’s evolving role in protecting property rights, regulating working conditions, protecting the right to bargain collectively, and reducing discrimination in the workplace has attempted to balance the power between workers and employers. This role shifts in response to government’s need to stimulate the economy balanced against the need to curb abusive business practices."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E3c",
    "Description": "The freedom of the United States economy encourages entrepreneurialism. This is an important factor behind economic growth that can lead to intended consequences (e.g., growth, competition, innovation, improved standard of living, productivity, specialization, trade, outsourcing, class mobility, positive externalities) and unintended consequences (e.g., recession, depression, trade, unemployment, outsourcing, generational poverty, income inequality, the challenges of class mobility, negative externalities.)."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E3d",
    "Description": "A degree of regulation, oversight, or government control is necessary in some markets to ensure free and fair competition and to limit unintended consequences of American capitalism. Government attempts to protect the worker, ensure property rights, and to regulate the marketplace, as well as to promote income equality and social mobility, have had varied results."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E3e",
    "Description": "The degree to which economic inequality reflects social, political, or economic injustices versus individual choices is hotly debated. The role that the government should play in decreasing this gap, including the variety of government programs designed to combat poverty, is debated as well."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E3f",
    "Description": "THE TOOLS OF ECONOMIC POLICY IN A GLOBAL ECONOMY: Globalization and increased economic interdependence affect the United States economy significantly. The tools that the policy makers have available to address these issues are fiscal policy, monetary policy, and trade policy."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E4a",
    "Description": "Policy makers establish economic goals related to economic indicators, including the Gross National Product (GNP), Gross Domestic Product (GDP), Consumer Price Index (CPI), employment and interest rates, and aggregate supply and demand."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E4b",
    "Description": "The president and Congress determine fiscal policy by establishing the level of spending and taxing in the annual budget. Some tax programs are designed to provide incentives to individuals and businesses that influence private sector spending, saving, and investment."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E4c",
    "Description": "The Federal Reserve is the government institution responsible for managing the nation’s monetary policy, including regulating the amount of money in circulation and interest rates."
  },
  {
    "Course": "Social Studies",
    "Grade": 12,
    "Standard": "12.E4d",
    "Description": "Trade policies and agreements (tariffs, quotas, embargoes) set the rules for trade between the United States and other nations. Agreeing on such rules is very difficult because each nation has different interests, and each nation has special interest groups trying to influence the negotiations."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": 8.1,
    "Description": "Reconstruction: Regional tensions following the Civil War complicated efforts to heal the nation and to redefine the status of African Americans."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.1a",
    "Description": "Different approaches toward and policies for Reconstruction highlight the challenges faced in reunifying the nation."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.1b",
    "Description": "Freed African Americans created new lives for themselves in the absence of slavery. Constitutional amendments and federal legislation sought to expand the rights and protect the citizenship of African Americans."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.1c",
    "Description": "Federal initiatives begun during Reconstruction were challenged on many levels leading to negative impacts on the lives of African Americans."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": 8.2,
    "Description": "A Changing Society: Industrialization and immigration contributed to the urbanization of America. Problems resulting from these changes sparked the Progressive movement and increased calls for reform."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.2a",
    "Description": "Technological developments changed the modes of production and access to natural resources facilitated increased industrialization. The demand for labor in urban industrial areas resulted in increased migration from rural areas and a rapid increase in immigration to the United States. New York City became the nation's largest city and other New York cities experienced growth at this time."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.2b",
    "Description": "Population density diversity technologies and industry in urban areas shaped the social cultural and economic lives of people."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.2c",
    "Description": "Increased urbanization and industrialization contributed to increasing conflicts over immigration influenced changes in labor conditions and led to political corruption."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.2d",
    "Description": "In response to shifts in working conditions laborers organized and employed a variety of strategies in an attempt to improve their conditions."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.2e",
    "Description": "Progressive era reformers sought to address political and social issues at the local state and federal levels of government between 1890 and 1920 These efforts brought renewed attention to women's rights and the suffrage movement and spurred the creation of government reform policies."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": 8.3,
    "Description": "Expansion and Imperialism: Beginning in the second half of the 19th century economic political and cultural factors contributed to a push for westward expansion and more aggressive United States foreign policy."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.3a",
    "Description": "Continued westward expansion contributed to increased conflicts with Native Americans."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.3b",
    "Description": "The Spanish-American War contributed to the rise of the United States as an imperial power."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.3c",
    "Description": "Interest in Pacific trade contributed to an increase in United States foreign interactions."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.3d",
    "Description": "The Roosevelt Corollary expanded the Monroe Doctrine and increased United States involvement in the affairs of Latin America. This led to resentment of the United States among many in Latin America."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": 8.4,
    "Description": "World War I and the Roaring Twenties: Various diplomatic economic and ideological factors contributed to the United States decision to enter World War I. Involvement in the war significantly altered the lives of Americans. Postwar America was characterized by economic prosperity technological innovations and changes in the workplace."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.4a",
    "Description": "European militarism the alliance system imperialism and nationalism were all factors that contributed to the start of World War I."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.4b",
    "Description": "International economic and military developments swayed opinion in favor of the United States siding with the Allies and entering World War I. Domestic responses to World War I limited civil liberties within the United States."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.4c",
    "Description": "New military technologies changed military strategy in World War I and resulted in an unprecedented number of casualties."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.4d",
    "Description": "Following extensive political debate the United States refused to ratify the Treaty of Versailles. The United States then sought to return to prewar policies by focusing on domestic rather than international matters."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.4e",
    "Description": "After World War I the United States entered a period of economic prosperity and cultural change. This period is known as the Roaring Twenties. During this time new opportunities for women were gained and African Americans engaged in various efforts to distinguish themselves and celebrate their culture."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": 8.5,
    "Description": "Great Depression: Economic and environmental disasters in the 1930s created hardships for many Americans. Amidst much debate about the appropriate role of government President Franklin D. Roosevelt helped to create intensive government interventions in the United States economy and society."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.5a",
    "Description": "Risky investing protectionism and overproduction led to the collapse of the stock market a wave of bank failures and a long and severe downturn in the economy called the Great Depression."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.5b",
    "Description": "The Great Depression and the Dust Bowl affected American businesses and families."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.5c",
    "Description": "President Roosevelt issued the New Deal in an attempt to revive the economy and help Americans deal with the hardships of the Great Depression. These New Deal reforms had a long-lasting effect on the role of government in American society and its economic life but did not resolve all of the hardships Americans faced."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": 8.6,
    "Description": "World War II: The aggression of the Axis powers threatened United States security and led to its entry into World War II. The nature and consequences of warfare during World War II transformed the United States and the global community. The damage from total warfare and atrocities such as the Holocaust led to a call for international efforts to protect human rights and prevent future wars."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.6a",
    "Description": "Worldwide economic depression militant nationalism the rise of totalitarian rule and the unsuccessful efforts of the League of Nations to preserve peace contributed to the outbreak of war in Europe and Asia."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.6b",
    "Description": "From 1939 to 1941 the United States government tried to maintain neutrality while providing aid to Britain but was drawn into the war by the Japanese attack on Pearl Harbor. The United States fought a war on multiple fronts. At home the economy was converted to war production and essential resources were rationed to ensure adequate supplies for military use."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.6c",
    "Description": "The nature and consequences of warfare during World War II transformed the United States and the global community. The damage from total warfare and human atrocities including the Holocaust led to a call for an international organization and the protection of human rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": 8.7,
    "Description": "Foreign Policy: The period after World War II has been characterized by an ideological and political struggle first between the United States and communism during the Cold War then between the United States and forces of instability in the Middle East. Increased economic interdependence and competition as well as environmental concerns are challenges faced by the United States."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.7a",
    "Description": "The Cold War was an ongoing struggle between the two nuclear superpowers the United States and the Soviet Union. The Cold War shaped the reconstruction of national boundaries and political alliances across the globe."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.7b",
    "Description": "The United States based its military and diplomatic policies from 1945 to 1990 on a policy of containment of communism."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.7c",
    "Description": "Following the end of the Cold War the United States sought to define a new role in global affairs but the legacies of Cold War actions continue to affect United States foreign policy today."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.7d",
    "Description": "Terrorist groups not representing any nation entered and reshaped global military and political alliances and conflicts. American foreign and domestic policies responded to terrorism in a variety of ways."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.7e",
    "Description": "Increased globalization has led to increased economic interdependence and competition."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": 8.8,
    "Description": "Demographic Change: After World War II the population of the United States rose sharply as a result of both natural increases and immigration. Population movements have resulted in changes to the American landscape and shifting political power. An aging population is affecting the economy and straining public resources."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.8a",
    "Description": "After World War II the United States experienced various shifts in population and demographics that resulted in social political and economic consequences."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.8b",
    "Description": "The postwar United States experienced increasing immigration debates over immigration policy and an increase in cultural diversity."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.8c",
    "Description": "Pollution population growth the consumption of natural resources clearing of land for human sustenance and large-scale industrialization have put added stress on the global environment."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": 8.9,
    "Description": "Domestic Politics and Reform: The civil rights movement and the Great Society were attempts by people and the government to address major social legal economic and environmental problems. Subsequent economic recession called for a new economic program."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.9a",
    "Description": "The civil rights movement began in the postwar era in response to long-standing inequalities in American society and eventually brought about equality under the law but slower progress on economic improvements."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.9b",
    "Description": "The civil rights movement prompted renewed efforts for equality by women and other groups."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.9c",
    "Description": "The Great Society programs of President Lyndon Johnson strengthened efforts aimed at reducing poverty and providing health care for the elderly but the Vietnam War drained resources and divided society."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.9d",
    "Description": "Economic recession during the 1970s and concerns about the growth and size of the federal government encouraged fiscal conservatives to push for changes in regulation and policy."
  },
  {
    "Course": "Social Studies",
    "Grade": 8,
    "Standard": "8.9e",
    "Description": "Constitutional issues involving the violation of civil liberties and the role of the federal government are a source of debate in American society."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": 7.1,
    "Description": "Native Americans: The physical environment and natural resources of North America influenced the development of the first human settlements and the culture of Native Americans. Native American societies varied across North America."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.1a",
    "Description": "Geography and climate influenced the migration and cultural development of Native Americans. Native Americans in North America settled into different regions and developed distinct cultures."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": 7.2,
    "Description": "Colonial Developments: European exploration of the New World resulted in various interactions with Native Americans and in colonization. The American colonies were established for a variety of reasons and developed differently based on economic social and geographic factors. Colonial America had a variety of social structures under which not all people were treated equally."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.2a",
    "Description": "Social economic and scientific improvements helped European nations launch an Age of Exploration."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.2b",
    "Description": "Different European groups had varied interactions and relationships with the Native American societies they encountered. Native American societies suffered from losses of life due to disease and conflict and loss of land due to the encroachment of European settlers and differing conceptions of property and land ownership."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.2c",
    "Description": "European nations established colonies in North America for economic religious and political reasons. Differences in climate physical features access to water and sources of labor contributed to the development of different economies in the New England Middle and Southern Colonies."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.2d",
    "Description": "In New York the Dutch established settlements along the Hudson River and the French established settlements in the Champlain Valley. Dutch contributions to American society were long-lasting."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.2e",
    "Description": "Over the course of the 17th and 18th centuries slavery grew in the colonies. Enslaved Africans utilized a variety of strategies to both survive and resist their conditions."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.2f",
    "Description": "the context of New York history students will distinguish between indentured servitude and slavery."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": 7.3,
    "Description": "American Independence: Growing tensions over political power and economic issues sparked a movement for independence from Great Britain. New York played a critical role in the course and outcome of the American Revolution."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.3a",
    "Description": "Conflicts between France and Great Britain in the 17th and 18th centuries in North America altered the relationship between the colonies and Great Britain."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.3b",
    "Description": "Stemming from the French and Indian War the British government enacted and attempted to enforce new political and economic policies in the colonies. These policies triggered varied colonial responses including protests and dissent."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.3c",
    "Description": "Influenced by Enlightenment ideas and their rights as Englishmen American colonial leaders outlined their grievances against British policies and actions in the Declaration of Independence."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.3d",
    "Description": "The outcome of the American Revolution was influenced by military strategies geographic considerations the involvement of the Haudenosaunee (Iroquois) and other Native American groups in the war and aid from other nations. The Treaty of Paris -1789 established the terms of peace."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": 7.4,
    "Description": "Historical Development of the Constitution: The newly independent states faced political and economic struggles under the Articles of Confederation. These challenges resulted in a Constitutional Convention a debate over ratification and the eventual adoption of the Bill of Rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.4a",
    "Description": "Throughout the American Revolution the colonies struggled to address their differing social political and economic interests and to establish unity. The Articles of Confederation created a form of government that loosely united the states but allowed states to maintain a large degree of sovereignty."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.4b",
    "Description": "The lack of a strong central government under the Articles of Confederation presented numerous challenges. A convention was held to revise the Articles the result of which was the Constitution. The Constitution established a democratic republic with a stronger central government."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.4c",
    "Description": "Advocates for and against a strong central government were divided on issues of States rights role/limits of federal power and guarantees of individual freedoms. Compromises were needed between the states in order to ratify the Constitution."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.4d",
    "Description": "counting of the enslaved African American community for purposes of congressional representation and taxation (the Three-Fifths Compromise)"
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": 7.5,
    "Description": "The Constitution in Practice: The United States Constitution serves as the foundation of the United States government and outlines the rights of citizens. The Constitution is considered a living document that can respond to political and social changes. The New York Constitution also has been changed over time."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.5a",
    "Description": "The Constitution outlined a federalist system of government that shares powers among the federal state and local governments."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.5b",
    "Description": "The Constitution established three branches of government as well as a system of checks and balances that guides the relationship between the branches. Individual rights of citizens are addressed in the Bill of Rights."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.5c",
    "Description": "While the Constitution provides a formal process for change through amendments the Constitution can respond to change in other ways. The New York State Constitution changed over time with changes in the early 19th century making it more democratic."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.5d",
    "Description": "Foreign and domestic disputes tested the strength of the Constitution particularly the separation of powers the system of checks and balances and the issue of States rights. The United States sought to implement isolationism while protecting the Western Hemisphere from European interference."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": 7.6,
    "Description": "Westward Expansion: Driven by political and economic motives the United States expanded its physical boundaries to the Pacific Ocean between 1800 and 1860 This settlement displaced Native Americans as the frontier was pushed westward."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.6a",
    "Description": "Some Native Americans who aligned with the British during the American Revolution lost land and were forced to move."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.6b",
    "Description": "Some Native Americans who aligned with the British during the American Revolution lost land and were forced to move."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.6c",
    "Description": "Westward expansion provided opportunities for some groups while harming others."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": 7.7,
    "Description": "Reform Movements: Social political and economic inequalities sparked various reform movements and resistance efforts. Influenced by the Second Great Awakening New York played a key role in major reform efforts."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.7a",
    "Description": "The Second Great Awakening which had a strong showing in New York inspired reform movements."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.7b",
    "Description": "Enslaved African Americans resisted slavery in various ways in the 19th century. The abolitionist movement also worked to raise awareness and generate resistance to the institution of slavery."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.7c",
    "Description": "Women joined the movements for abolition and temperance and organized to advocate for women's property rights fair wages education and political equality."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.7d",
    "Description": "The Anti-Rent movement in New York State was an attempt by tenant farmers to the protest the landownership system."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": 7.8,
    "Description": "A Nation Divided: Westward expansion the industrialization of the North and the increase of slavery in the South contributed to the growth of sectionalism. Constitutional conflicts between advocates of States rights and supporters of federal power increased tensions in the nation; attempts to compromise ultimately failed to keep the nation together leading to the Civil War."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.8a",
    "Description": "Early United States industrialization affected different parts of the country in different ways. Regional economic differences and values as well as different conceptions of the Constitution laid the basis for tensions between States rights advocates and supporters of a strong federal government."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.8b",
    "Description": "As the nation expanded geographically the question of slavery in new territories and states led to increased sectional tensions. Attempts at compromise ended in failure."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.8c",
    "Description": "Perspectives on the causes of the Civil War varied based on geographic region but the election of a Republican president was one of the immediate causes for the secession of the Southern states."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.8d",
    "Description": "The course and outcome of the Civil War were influenced by strategic leaders from both the North and South decisive battles and military strategy and technology that utilized the region's geography."
  },
  {
    "Course": "Social Studies",
    "Grade": 7,
    "Standard": "7.8e",
    "Description": "The Civil War impacted human lives physical infrastructure economic capacity and governance of the United States."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": 6.1,
    "Description": "Present-Day Eastern Hemisphere Geography: The diverse geography of the Eastern Hemisphere has influenced human culture and settlement patterns in distinct ways. Human communities in the Eastern Hemisphere have adapted to or modified the physical environment."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.1a",
    "Description": "Maps can be used to represent varied climate zones landforms bodies of water and resources of the Eastern Hemisphere."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.1b",
    "Description": "The Eastern Hemisphere can be divided into regions. Regions are areas that share common identifiable characteristics such as physical political economic or cultural features. Regions within the Eastern Hemisphere include: <ul><li>Middle East (North Africa and Southwest Asia)</li><li>Sub-Saharan Africa</li><li>Europe (West North South Central and Southeast)</li><li>Russia and the Independent States (Russia Caucasia Central Asia the region of Belarus Moldova and Ukraine)</li><li>East Asia (People's Republic of China North Korea South Korea Japan and Taiwan)</li><li>Southeast Asia (Vietnam Cambodia Laos Thailand Myanmar [Burma] Malaysia Singapore Indonesia Brunei Philippines)</li><li>South Asia (Afghanistan Pakistan India Bangladesh Nepal Bhutan)</li><li>Oceania (Australia New Zealand the Pacific)</li></ul>"
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.1c",
    "Description": "The physical environment influences human population distribution land use economic activities and political connections."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.1d",
    "Description": "Issues and problems experienced in the regions of the Eastern Hemisphere have roots in the past."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": 6.2,
    "Description": "The First Humans Through the Neolithic Revolution in the Eastern Hemisphere: The first humans modified their physical environment as well as adapted to their environment."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.2a",
    "Description": "Human populations that settled along rivers in rainforests along coastlines in deserts and in mountains made use of the resources and the environment around them in developing distinct ways of life."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.2b",
    "Description": "Early peoples in the Eastern Hemisphere are often studied by analyzing artifacts and archaeological features. Archaeologists engage in digs and study artifacts and features in a particular location to gather evidence about a group of people and how they lived at a particular time."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.2c",
    "Description": "The Neolithic Revolution was marked by technological advances in agriculture and domestication of animals that allowed people to form semi-sedentary and sedentary settlements."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.2d",
    "Description": "Historians use archaeological and other types of evidence to investigate patterns in history and identify turning points. A turning point can be an event era and/or development in history that has brought about significant social cultural ecological political or economic change."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": 6.3,
    "Description": "Early River Valley Civilizations in the Eastern Hemisphere (ca. 3500 B.C.E. – ca. 500 B.C.E.): Complex societies and civilizations developed in the Eastern Hemisphere. Although these complex societies and civilizations have certain defining characteristics in common each is also known for unique cultural achievements and contributions. Early human communities in the Eastern Hemisphere adapted to and modified the physical environment."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.3a",
    "Description": "Humans living together in settlements develop shared customs beliefs ideas and languages that give identity to the group."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.3b",
    "Description": "Complex societies and civilizations share the common characteristics of religion job specialization cities government language/record keeping system technology and social hierarchy. People in Mesopotamia Yellow River valley Indus River valley and Nile River valley developed complex societies and civilizations."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.3c",
    "Description": "Mesopotamia Yellow River valley Indus River valley and Nile River valley complex societies and civilizations adapted to and modified their environment to meet the needs of their population."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.3d",
    "Description": "Political and social hierarchies influenced the access that groups and individuals had to power wealth and jobs and influenced their roles within a society."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": 6.4,
    "Description": "Comparative World Religions (ca. 2000 B.C.E – ca. 630 C.E): Major religions and belief systems developed in the Eastern Hemisphere. There were important similarities and differences between these belief systems."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.4a",
    "Description": "Civilizations and complex societies developed belief systems and religions that have similar as well as different characteristics."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.4b",
    "Description": "Belief systems and religions are based on a set of mutually held values."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.4c",
    "Description": "Belief systems and religions often are used to unify groups of people and may affect social order and gender roles."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": 6.5,
    "Description": "Comparative Classical Civilizations in the Eastern Hemisphere (ca. 600 B.C.E. – ca. 500 C.E.): As complex societies and civilizations change over time their political and economic structures evolve. A golden age may be indicated when there is an extended period of time that is peaceful prosperous and demonstrates great cultural achievements."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.5a",
    "Description": "Geographic factors influence the development of classical civilizations and their political structures."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.5b",
    "Description": "Political structures were developed to establish order to create and enforce laws and to enable decision making."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.5c",
    "Description": "A period of peace prosperity and cultural achievements may be indicative of a golden age."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": 6.6,
    "Description": "Mediterranean World: Feudal Western Europe The Byzantine Empire and the Islamic Caliphates (ca. 600 C.E. – ca. 1450): The Mediterranean world was reshaped with the fall of the Roman Empire. Three distinct cultural regions developed: feudal Western Europe the Byzantine Empire and the Islamic caliphates. These regions interacted with each other and clashed over control of holy lands."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.6a",
    "Description": "Overexpansion corruption invasions civil wars and discord led to the fall of Rome. Feudalism developed in Western Europe in reaction to a need for order and to meet basic needs."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.6b",
    "Description": "The Byzantine Empire preserved elements of the Roman Empire controlled lands within the Mediterranean basin and began to develop Orthodox Christianity."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.6c",
    "Description": "Islam spread within the Mediterranean region from southwest Asia to northern Africa and the Iberian Peninsula."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.6d",
    "Description": "Competition and rivalry over religious economic and political control over the holy lands led to conflict such as the Crusades."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": 6.7,
    "Description": "Interactions Across the Eastern Hemisphere (ca. 600 C.E. – ca. 1450): Trade networks promoted the exchange and diffusion of language belief systems tools intellectual ideas inventions and diseases."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.7a",
    "Description": "The Silk Roads the Indian Ocean and the Trans-Saharan routes formed the major Afro-Eurasian trade networks connecting the East and the West. Ideas people technologies products and diseases moved along these routes."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.7b",
    "Description": "The Mongol conquests in Eurasia fostered connections between the East and the West and the Mongols served as important agents of change and cultural diffusion."
  },
  {
    "Course": "Social Studies",
    "Grade": 6,
    "Standard": "6.7c",
    "Description": "Complex societies and civilizations adapted and designed technologies for transportation that allowed them to cross challenging landscapes and move people and goods efficiently."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": 5.1,
    "Description": "EARLY PEOPLES OF THE AMERICAS: The first humans in the Western Hemisphere modified their physical environment as well as adapted to their environment. Their interactions with their environment led to various innovations and to the development of unique cultures."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.1a",
    "Description": "Various forms of scientific evidence suggest that humans came to North America approximately 25000 to 14000 years ago and spread southward to South America."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.1b",
    "Description": "Human populations that settled along rivers, in rainforests, along oceans, in deserts, on plains, in mountains, and in cold climates adapted to and made use of the resources and environment around them in developing distinct ways of life."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.1c",
    "Description": "Early peoples living together in settlements developed shared cultures with customs, beliefs, values, and languages that give identity to the group. These early peoples also developed patterns of organization and governance to manage their societies."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": 5.2,
    "Description": "COMPLEX SOCIETIES AND CIVILIZATIONS: Between 1100 B.C.E. and 1500 C.E, complex societies and civilizations developed in the Western Hemisphere. Although these complex societies and civilizations have certain defining characteristics in common, each is also known for unique cultural achievements and contributions."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.2a",
    "Description": "Civilizations share certain common characteristics of religion, job specialization, cities, government, language and writing systems, technology, and social hierarchy."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.2b",
    "Description": "Complex societies and civilizations adapted to and modified their environment to meet the needs of their people."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.2c",
    "Description": "Political states can take different forms, such as city-states and empires. A city-state is comprised of a city with a government that controls the surrounding territory, while an empire is a political organization developed when a single, supreme authority conquers other geographic and/or cultural regions beyond its initial settlements."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": 5.3,
    "Description": "EUROPEAN EXPLORATION AND ITS EFFECTS: Various European powers explored and eventually colonized the Western Hemisphere. This had a profound effect on Native Americans and led to the transatlantic slave trade."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.3a",
    "Description": "Europeans traveled to the Americas in search of new trade routes, including a northwest passage, and resources. They hoped to gain wealth, power, and glory."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.3b",
    "Description": "Europeans encountered and interacted with Native Americans in a variety of ways."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.3c",
    "Description": "The transatlantic trade of goods, movement of people, and spread of ideas and diseases resulted in cultural diffusion. This cultural diffusion became known as the Columbian Exchange which reshaped the lives and influenced the beliefs of people."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.3d",
    "Description": "Africans were captured, brought to the Americas, and sold as slaves. Their transport across the Atlantic was known as the Middle Passage."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": 5.4,
    "Description": "GEOGRAPHY IN THE WESTERN HEMISPHERE: The diverse geography of the Western Hemisphere has influenced human culture and settlement in distinct ways. Human communities in the Western Hemisphere have modified the physical environment."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.4a",
    "Description": "Physical maps reflect the varied climate zones, landforms, bodies of water, and natural resources of the Western Hemisphere."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.4b",
    "Description": "The Western Hemisphere can be divided into regions. Regions are areas that share common, identifiable characteristics such as physical, political, economic, or cultural features. Regions within the Western Hemisphere include: North America (Canada and the United States), Mesoamerica (Mexico and Central America), Caribbean, South America"
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.4c",
    "Description": "The physical environment influences human population distribution, land use, and other forms of economic activity."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": 5.5,
    "Description": "COMPARATIVE CULTURES: The countries of the Western Hemisphere are diverse and the cultures of these countries are rich and varied. Due to their proximity to each other, the countries of the Western Hemisphere share some of the same concerns and issues."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.5a",
    "Description": "The countries of the Western Hemisphere have varied characteristics and contributions that distinguish them from other countries."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.5b.",
    "Description": "Countries in the Western Hemisphere face a variety of concerns and issues specific to the region."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": 5.6,
    "Description": "GOVERNMENT: The political systems of the Western Hemisphere vary in structure and organization across time and place."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.6a",
    "Description": "Government structures, functions, and founding documents vary from place to place in the countries of the Western Hemisphere."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.6b",
    "Description": "Legal, political, and historic documents define the values, beliefs, and principles of constitutional democracy."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.6c",
    "Description": "Across time and place, different groups of people in the Western Hemisphere have struggled and fought for equality and civil rights or sovereignty."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.6d",
    "Description": "Multinational organizations and nongovernmental organizations in the Western Hemisphere seek to encourage cooperation between nations, protect human rights, support economic development, and provide assistance in challenging situations."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": 5.7,
    "Description": "ECONOMICS: The peoples of the Western Hemisphere have developed various ways to meet their needs and wants. Many of the countries of the Western Hemisphere trade with each other, as well as with other countries around the world."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.7a",
    "Description": "Different types of economic systems have developed across time and place within the Western Hemisphere. These economic systems, including traditional, market, and command, address the three economic questions: what will be produced, how it will be produced, and who will get what is produced?"
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.7b",
    "Description": "Peoples of the Western Hemisphere have engaged in a variety of economic activities to meet their needs and wants."
  },
  {
    "Course": "Social Studies",
    "Grade": 5,
    "Standard": "5.7c",
    "Description": "Countries trade with other countries to meet economic needs and wants. They are interdependent."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "3-5-ETS1-1.",
    "Description": "Define a simple design problem reflecting a need or a want that includes specified criteria for success and constraints on materials, time, or cost."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "3-5-ETS1-2.",
    "Description": "Generate and compare multiple possible solutions to a problem based on how well each is likely to meet the criteria and constraints of the problem."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "3-5-ETS1-3.",
    "Description": "Plan and carry out fair tests in which variables are controlled and failure points are considered to identify aspects of a model or prototype that can be improved."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-ESS1-1.",
    "Description": "Support an argument that differences in the apparent brightness of the Sun compared to other stars is due to their relative distances from Earth."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-ESS1-2.",
    "Description": "Represent data in graphical displays to reveal patterns of daily changes in length and direction of shadows, day and night, and the seasonal appearance of some stars in the night sky."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-ESS2-1.",
    "Description": "Develop a model using an example to describe ways the geosphere, biosphere, hydrosphere, and/or atmosphere interact."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-ESS2-2.",
    "Description": "Describe and graph the amounts of salt water and fresh water in various reservoirs to provide evidence about the distribution of water on Earth."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-ESS3-1.",
    "Description": "Obtain and combine information about ways individual communities use science ideas to protect Earth’s resources and environment."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-LS1-1.",
    "Description": "Support an argument that plants get the materials they need for growth chiefly from air and water."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-LS2-1.",
    "Description": "Develop a model to describe the movement of matter among plants (producers), animals (consumers), decomposers, and the environment."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-PS1-1.",
    "Description": "Develop a model to describe that matter is made of particles too small to be seen."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-PS1-2.",
    "Description": "Measure and graph quantities to provide evidence that regardless of the type of change that occurs when heating, cooling, or mixing substances the total amount of matter is conserved."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-PS1-3.",
    "Description": "Make observations and measurements to identify materials based on their properties."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-PS1-4.",
    "Description": "Conduct an investigation to determine whether the mixing of two or more substances results in new substances."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-PS2-1.",
    "Description": "Support an argument that the gravitational force exerted by Earth on objects is directed down."
  },
  {
    "Course": "Science",
    "Grade": 5,
    "Standard": "5-PS3-1.",
    "Description": "Use models to describe that energy in animals’ food (used for body repair, growth, motion, and to maintain body warmth) was once energy from the Sun."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS1-1.",
    "Description": "Develop and use a model of the Earth-Sun-moon system to describe the cyclic patterns of lunar phases, eclipses of the Sun and moon, and seasons."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS1-2.",
    "Description": "Develop and use a model to describe the role of gravity in the motions within galaxies and the solar system."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS1-3.",
    "Description": "Analyze and interpret data to determine scale properties of objects in the solar system."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS1-4.",
    "Description": "Construct a scientific explanation based on evidence from rock strata for how the geologic time scale is used to organize Earth’s 4.6-billion-year-old history."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS2-1.",
    "Description": "Develop a model to describe the cycling of Earth’s materials and the flow of energy that drives this process."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS2-2.",
    "Description": "Construct an explanation based on evidence for how geoscience processes have changed Earth’s surface at varying temporal and spatial scales."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS2-3.",
    "Description": "Analyze and interpret data on the distribution of fossils and rocks, continental shapes, and seafloor structures to provide evidence of the past plate motions."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS2-4.",
    "Description": "Develop a model to describe the cycling of water through Earth’s systems driven by energy from the Sun and the force of gravity."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS2-5.",
    "Description": "Collect data to provide evidence for how the motions and complex interactions of air masses results in changes in weather conditions."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS2-6.",
    "Description": "Develop and use a model to describe how unequal heating and rotation of Earth cause patterns of atmospheric and oceanic circulation that determine regional climates."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS3-1.",
    "Description": "Construct a scientific explanation based on evidence for how the uneven distributions of Earth’s mineral, energy, and groundwater resources are the result of past and current geologic processes."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS3-2.",
    "Description": "Analyze and interpret data on natural hazards to forecast future catastrophic events and inform the development of technologies to mitigate their effects."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS3-3.",
    "Description": "Apply scientific principles to design a method for monitoring and minimizing a human impact on the environment."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS3-4.",
    "Description": "Construct an argument supported by evidence for how increases in human population and per-capita consumption of natural resources impact Earth’s systems."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ESS3-5.",
    "Description": "Ask questions to clarify evidence of the factors that have caused the rise in global temperatures over the past century."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ETS1-1.",
    "Description": "Define the criteria and constraints of a design problem with sufficient precision to ensure a successful solution, taking into account relevant scientific principles and potential impacts on people and the natural environment that may limit possible solutions."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ETS1-2.",
    "Description": "Evaluate competing design solutions using a systematic process to determine how well they meet the criteria and constraints of the problem."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ETS1-3.",
    "Description": "Analyze data from tests to determine similarities and differences among several design solutions to identify the best characteristics of each that can be combined into a new solution to better meet the criteria for success."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-ETS1-4.",
    "Description": "Develop a model to generate data for iterative testing and modification of a proposed object, tool, or process such that an optimal design can be achieved."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS1-1.",
    "Description": "Plan and conduct an investigation to provide evidence that living things are made of cells; either one cell or many different numbers and types of cells."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS1-2.",
    "Description": "Develop and use a model to describe the function of a cell as a whole and ways parts of cells contribute to the function."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS1-3.",
    "Description": "Construct an explanation supported by evidence for how the body is composed of interacting systems consisting of cells, tissues, and organs working together to maintain homeostasis."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS1-4.",
    "Description": "Use argument based on empirical evidence and scientific reasoning to support an explanation for how characteristic animal behaviors and specialized plant structures affect the probability of successful reproduction of animals and plants, respectively."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS1-5.",
    "Description": "Construct a scientific explanation based on evidence for how environmental and genetic factors influence the growth of organisms."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS1-6.",
    "Description": "Construct a scientific explanation based on evidence for the role of photosynthesis in the cycling of matter and flow of energy into and out of organisms."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS1-7.",
    "Description": "Develop a model to describe how food molecules are rearranged through chemical reactions to release energy during cellular respiration and/or form new molecules that support growth as this matter moves through an organism."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS1-8.",
    "Description": "Gather and synthesize information that sensory receptors respond to stimuli, resulting in immediate behavior and/or storage as memories."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS2-1.",
    "Description": "Analyze and interpret data to provide evidence for the effects of resource availability on organisms and populations of organisms in an ecosystem."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS2-2.",
    "Description": "Construct an explanation that predicts patterns of interactions among organisms in a variety of ecosystems."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS2-3.",
    "Description": "Develop a model to describe the cycling of matter and flow of energy among living and nonliving parts of an ecosystem."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS2-4.",
    "Description": "Construct an argument supported by empirical evidence that changes to physical or biological components of an ecosystem affect populations."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS2-5.",
    "Description": "Evaluate competing design solutions for maintaining biodiversity and protecting ecosystem stability."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS3-1.",
    "Description": "Develop and use a model to explain why structural changes to genes (mutations) located on chromosomes may affect proteins and may result in harmful, beneficial, or neutral effects to the structure and function of the organism."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS3-2.",
    "Description": "Develop and use a model to describe how asexual reproduction results in offspring with identical genetic information and sexual reproduction results in offspring with genetic variation."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS4-1.",
    "Description": "Analyze and interpret data for patterns in the fossil record that document the existence, diversity, extinction, and change of life forms throughout the history of life on Earth under the assumption that natural laws operate today as in the past."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS4-2.",
    "Description": "Apply scientific ideas to construct an explanation for the anatomical similarities and differences among modern organisms and between modern and fossil organisms to infer evolutionary relationships."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS4-3.",
    "Description": "Analyze displays of pictorial data to compare patterns of similarities in the embryological development across multiple species to identify relationships not evident in the fully formed anatomy."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS4-4.",
    "Description": "Construct an explanation based on evidence that describes how genetic variations of traits in a population increase some individuals’ probability of surviving and reproducing in a specific environment."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS4-5.",
    "Description": "Gather and synthesize information about the technologies that have changed the way humans influence the inheritance of desired traits in organisms."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-LS4-6.",
    "Description": "Use mathematical representations to support explanations of how natural selection may lead to increases and decreases of specific traits in populations over time."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS1-1.",
    "Description": "Develop models to describe the atomic composition of simple molecules and extended structures."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS1-2.",
    "Description": "Analyze and interpret data on the properties of substances before and after the substances interact to determine if a chemical reaction has occurred."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS1-3.",
    "Description": "Gather and make sense of information to describe that synthetic materials come from natural resources and impact society."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS1-4.",
    "Description": "Develop a model that predicts and describes changes in particle motion, temperature, and phase (state) of a substance when thermal energy is added or removed."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS1-5.",
    "Description": "Develop and use a model to describe how the total number of atoms does not change in a chemical reaction and thus mass is conserved."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS1-6.",
    "Description": "Undertake a design project to construct, test, and modify a device that either releases or absorbs thermal energy during a chemical and/or physical process."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS1-7.",
    "Description": "Use evidence to illustrate that density is a property that can be used to identify samples of matter."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS1-8.",
    "Description": "Plan and conduct an investigation to demonstrate that mixtures are combinations of substances."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS2-1.",
    "Description": "Apply Newton’s Third Law to design a solution to a problem involving the motion of two colliding objects."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS2-2.",
    "Description": "Plan and conduct an investigation to provide evidence that the change in an object’s motion depends on the sum of the forces on the object and the mass of the object."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS2-3.",
    "Description": "Ask questions about data to determine the factors that affect the strength of electric and magnetic forces."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS2-4.",
    "Description": "Construct and present arguments using evidence to support the claim that gravitational interactions are attractive and depend on the masses of interacting objects and the distance between them."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS2-5.",
    "Description": "Conduct an investigation and evaluate the experimental design to provide evidence that fields exist between objects exerting forces on each other even though the objects are not in contact."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS3-1.",
    "Description": "Construct and interpret graphical displays of data to describe the relationships of kinetic energy to the mass of an object and to the speed of an object."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS3-2.",
    "Description": "Develop a model to describe that when the arrangement of objects interacting at a distance changes, different amounts of potential energy are stored in the system."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS3-3.",
    "Description": "Apply scientific principles to design, construct, and test a device that either minimizes or maximizes thermal energy transfer."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS3-4.",
    "Description": "Plan and conduct an investigation to determine the relationships among the energy transferred, the type of matter, the mass, and the change in the temperature of the sample of matter."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS3-5.",
    "Description": "Construct, use, and present an argument to support the claim that when work is done on or by a system, the energy of the system changes as energy is transferred to or from the system."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS3-6.",
    "Description": "Make observations to provide evidence that energy can be transferred by electric currents."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS4-1.",
    "Description": "Develop a model and use mathematical representations to describe waves that includes frequency, wavelength, and how the amplitude of a wave is related to the energy in a wave."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS4-2.",
    "Description": "Develop and use a model to describe that waves are reflected, absorbed, or transmitted through various materials."
  },
  {
    "Course": "Science",
    "Grade": 6,
    "Standard": "MS-PS4-3.",
    "Description": "Integrate qualitative scientific and technical information to support the claim that digitized signals are a more reliable way to encode and transmit information than analog signals."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS1-1.",
    "Description": "Develop and use a model of the Earth-Sun-moon system to describe the cyclic patterns of lunar phases, eclipses of the Sun and moon, and seasons."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS1-2.",
    "Description": "Develop and use a model to describe the role of gravity in the motions within galaxies and the solar system."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS1-3.",
    "Description": "Analyze and interpret data to determine scale properties of objects in the solar system."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS1-4.",
    "Description": "Construct a scientific explanation based on evidence from rock strata for how the geologic time scale is used to organize Earth’s 4.6-billion-year-old history."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS2-1.",
    "Description": "Develop a model to describe the cycling of Earth’s materials and the flow of energy that drives this process."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS2-2.",
    "Description": "Construct an explanation based on evidence for how geoscience processes have changed Earth’s surface at varying temporal and spatial scales."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS2-3.",
    "Description": "Analyze and interpret data on the distribution of fossils and rocks, continental shapes, and seafloor structures to provide evidence of the past plate motions."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS2-4.",
    "Description": "Develop a model to describe the cycling of water through Earth’s systems driven by energy from the Sun and the force of gravity."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS2-5.",
    "Description": "Collect data to provide evidence for how the motions and complex interactions of air masses results in changes in weather conditions."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS2-6.",
    "Description": "Develop and use a model to describe how unequal heating and rotation of Earth cause patterns of atmospheric and oceanic circulation that determine regional climates."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS3-1.",
    "Description": "Construct a scientific explanation based on evidence for how the uneven distributions of Earth’s mineral, energy, and groundwater resources are the result of past and current geologic processes."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS3-2.",
    "Description": "Analyze and interpret data on natural hazards to forecast future catastrophic events and inform the development of technologies to mitigate their effects."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS3-3.",
    "Description": "Apply scientific principles to design a method for monitoring and minimizing a human impact on the environment."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS3-4.",
    "Description": "Construct an argument supported by evidence for how increases in human population and per-capita consumption of natural resources impact Earth’s systems."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ESS3-5.",
    "Description": "Ask questions to clarify evidence of the factors that have caused the rise in global temperatures over the past century."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ETS1-1.",
    "Description": "Define the criteria and constraints of a design problem with sufficient precision to ensure a successful solution, taking into account relevant scientific principles and potential impacts on people and the natural environment that may limit possible solutions."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ETS1-2.",
    "Description": "Evaluate competing design solutions using a systematic process to determine how well they meet the criteria and constraints of the problem."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ETS1-3.",
    "Description": "Analyze data from tests to determine similarities and differences among several design solutions to identify the best characteristics of each that can be combined into a new solution to better meet the criteria for success."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-ETS1-4.",
    "Description": "Develop a model to generate data for iterative testing and modification of a proposed object, tool, or process such that an optimal design can be achieved."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS1-1.",
    "Description": "Plan and conduct an investigation to provide evidence that living things are made of cells; either one cell or many different numbers and types of cells."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS1-2.",
    "Description": "Develop and use a model to describe the function of a cell as a whole and ways parts of cells contribute to the function."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS1-3.",
    "Description": "Construct an explanation supported by evidence for how the body is composed of interacting systems consisting of cells, tissues, and organs working together to maintain homeostasis."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS1-4.",
    "Description": "Use argument based on empirical evidence and scientific reasoning to support an explanation for how characteristic animal behaviors and specialized plant structures affect the probability of successful reproduction of animals and plants, respectively."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS1-5.",
    "Description": "Construct a scientific explanation based on evidence for how environmental and genetic factors influence the growth of organisms."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS1-6.",
    "Description": "Construct a scientific explanation based on evidence for the role of photosynthesis in the cycling of matter and flow of energy into and out of organisms."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS1-7.",
    "Description": "Develop a model to describe how food molecules are rearranged through chemical reactions to release energy during cellular respiration and/or form new molecules that support growth as this matter moves through an organism."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS1-8.",
    "Description": "Gather and synthesize information that sensory receptors respond to stimuli, resulting in immediate behavior and/or storage as memories."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS2-1.",
    "Description": "Analyze and interpret data to provide evidence for the effects of resource availability on organisms and populations of organisms in an ecosystem."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS2-2.",
    "Description": "Construct an explanation that predicts patterns of interactions among organisms in a variety of ecosystems."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS2-3.",
    "Description": "Develop a model to describe the cycling of matter and flow of energy among living and nonliving parts of an ecosystem."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS2-4.",
    "Description": "Construct an argument supported by empirical evidence that changes to physical or biological components of an ecosystem affect populations."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS2-5.",
    "Description": "Evaluate competing design solutions for maintaining biodiversity and protecting ecosystem stability."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS3-1.",
    "Description": "Develop and use a model to explain why structural changes to genes (mutations) located on chromosomes may affect proteins and may result in harmful, beneficial, or neutral effects to the structure and function of the organism."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS3-2.",
    "Description": "Develop and use a model to describe how asexual reproduction results in offspring with identical genetic information and sexual reproduction results in offspring with genetic variation."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS4-1.",
    "Description": "Analyze and interpret data for patterns in the fossil record that document the existence, diversity, extinction, and change of life forms throughout the history of life on Earth under the assumption that natural laws operate today as in the past."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS4-2.",
    "Description": "Apply scientific ideas to construct an explanation for the anatomical similarities and differences among modern organisms and between modern and fossil organisms to infer evolutionary relationships."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS4-3.",
    "Description": "Analyze displays of pictorial data to compare patterns of similarities in the embryological development across multiple species to identify relationships not evident in the fully formed anatomy."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS4-4.",
    "Description": "Construct an explanation based on evidence that describes how genetic variations of traits in a population increase some individuals’ probability of surviving and reproducing in a specific environment."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS4-5.",
    "Description": "Gather and synthesize information about the technologies that have changed the way humans influence the inheritance of desired traits in organisms."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-LS4-6.",
    "Description": "Use mathematical representations to support explanations of how natural selection may lead to increases and decreases of specific traits in populations over time."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS1-1.",
    "Description": "Develop models to describe the atomic composition of simple molecules and extended structures."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS1-2.",
    "Description": "Analyze and interpret data on the properties of substances before and after the substances interact to determine if a chemical reaction has occurred."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS1-3.",
    "Description": "Gather and make sense of information to describe that synthetic materials come from natural resources and impact society."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS1-4.",
    "Description": "Develop a model that predicts and describes changes in particle motion, temperature, and phase (state) of a substance when thermal energy is added or removed."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS1-5.",
    "Description": "Develop and use a model to describe how the total number of atoms does not change in a chemical reaction and thus mass is conserved."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS1-6.",
    "Description": "Undertake a design project to construct, test, and modify a device that either releases or absorbs thermal energy during a chemical and/or physical process."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS1-7.",
    "Description": "Use evidence to illustrate that density is a property that can be used to identify samples of matter."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS1-8.",
    "Description": "Plan and conduct an investigation to demonstrate that mixtures are combinations of substances."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS2-1.",
    "Description": "Apply Newton’s Third Law to design a solution to a problem involving the motion of two colliding objects."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS2-2.",
    "Description": "Plan and conduct an investigation to provide evidence that the change in an object’s motion depends on the sum of the forces on the object and the mass of the object."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS2-3.",
    "Description": "Ask questions about data to determine the factors that affect the strength of electric and magnetic forces."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS2-4.",
    "Description": "Construct and present arguments using evidence to support the claim that gravitational interactions are attractive and depend on the masses of interacting objects and the distance between them."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS2-5.",
    "Description": "Conduct an investigation and evaluate the experimental design to provide evidence that fields exist between objects exerting forces on each other even though the objects are not in contact."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS3-1.",
    "Description": "Construct and interpret graphical displays of data to describe the relationships of kinetic energy to the mass of an object and to the speed of an object."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS3-2.",
    "Description": "Develop a model to describe that when the arrangement of objects interacting at a distance changes, different amounts of potential energy are stored in the system."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS3-3.",
    "Description": "Apply scientific principles to design, construct, and test a device that either minimizes or maximizes thermal energy transfer."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS3-4.",
    "Description": "Plan and conduct an investigation to determine the relationships among the energy transferred, the type of matter, the mass, and the change in the temperature of the sample of matter."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS3-5.",
    "Description": "Construct, use, and present an argument to support the claim that when work is done on or by a system, the energy of the system changes as energy is transferred to or from the system."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS3-6.",
    "Description": "Make observations to provide evidence that energy can be transferred by electric currents."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS4-1.",
    "Description": "Develop a model and use mathematical representations to describe waves that includes frequency, wavelength, and how the amplitude of a wave is related to the energy in a wave."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS4-2.",
    "Description": "Develop and use a model to describe that waves are reflected, absorbed, or transmitted through various materials."
  },
  {
    "Course": "Science",
    "Grade": 7,
    "Standard": "MS-PS4-3.",
    "Description": "Integrate qualitative scientific and technical information to support the claim that digitized signals are a more reliable way to encode and transmit information than analog signals."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS1-1.",
    "Description": "Develop and use a model of the Earth-Sun-moon system to describe the cyclic patterns of lunar phases, eclipses of the Sun and moon, and seasons."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS1-2.",
    "Description": "Develop and use a model to describe the role of gravity in the motions within galaxies and the solar system."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS1-3.",
    "Description": "Analyze and interpret data to determine scale properties of objects in the solar system."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS1-4.",
    "Description": "Construct a scientific explanation based on evidence from rock strata for how the geologic time scale is used to organize Earth’s 4.6-billion-year-old history."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS2-1.",
    "Description": "Develop a model to describe the cycling of Earth’s materials and the flow of energy that drives this process."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS2-2.",
    "Description": "Construct an explanation based on evidence for how geoscience processes have changed Earth’s surface at varying temporal and spatial scales."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS2-3.",
    "Description": "Analyze and interpret data on the distribution of fossils and rocks, continental shapes, and seafloor structures to provide evidence of the past plate motions."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS2-4.",
    "Description": "Develop a model to describe the cycling of water through Earth’s systems driven by energy from the Sun and the force of gravity."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS2-5.",
    "Description": "Collect data to provide evidence for how the motions and complex interactions of air masses results in changes in weather conditions."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS2-6.",
    "Description": "Develop and use a model to describe how unequal heating and rotation of Earth cause patterns of atmospheric and oceanic circulation that determine regional climates."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS3-1.",
    "Description": "Construct a scientific explanation based on evidence for how the uneven distributions of Earth’s mineral, energy, and groundwater resources are the result of past and current geologic processes."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS3-2.",
    "Description": "Analyze and interpret data on natural hazards to forecast future catastrophic events and inform the development of technologies to mitigate their effects."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS3-3.",
    "Description": "Apply scientific principles to design a method for monitoring and minimizing a human impact on the environment."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS3-4.",
    "Description": "Construct an argument supported by evidence for how increases in human population and per-capita consumption of natural resources impact Earth’s systems."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ESS3-5.",
    "Description": "Ask questions to clarify evidence of the factors that have caused the rise in global temperatures over the past century."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ETS1-1.",
    "Description": "Define the criteria and constraints of a design problem with sufficient precision to ensure a successful solution, taking into account relevant scientific principles and potential impacts on people and the natural environment that may limit possible solutions."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ETS1-2.",
    "Description": "Evaluate competing design solutions using a systematic process to determine how well they meet the criteria and constraints of the problem."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ETS1-3.",
    "Description": "Analyze data from tests to determine similarities and differences among several design solutions to identify the best characteristics of each that can be combined into a new solution to better meet the criteria for success."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-ETS1-4.",
    "Description": "Develop a model to generate data for iterative testing and modification of a proposed object, tool, or process such that an optimal design can be achieved."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS1-1.",
    "Description": "Plan and conduct an investigation to provide evidence that living things are made of cells; either one cell or many different numbers and types of cells."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS1-2.",
    "Description": "Develop and use a model to describe the function of a cell as a whole and ways parts of cells contribute to the function."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS1-3.",
    "Description": "Construct an explanation supported by evidence for how the body is composed of interacting systems consisting of cells, tissues, and organs working together to maintain homeostasis."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS1-4.",
    "Description": "Use argument based on empirical evidence and scientific reasoning to support an explanation for how characteristic animal behaviors and specialized plant structures affect the probability of successful reproduction of animals and plants, respectively."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS1-5.",
    "Description": "Construct a scientific explanation based on evidence for how environmental and genetic factors influence the growth of organisms."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS1-6.",
    "Description": "Construct a scientific explanation based on evidence for the role of photosynthesis in the cycling of matter and flow of energy into and out of organisms."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS1-7.",
    "Description": "Develop a model to describe how food molecules are rearranged through chemical reactions to release energy during cellular respiration and/or form new molecules that support growth as this matter moves through an organism."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS1-8.",
    "Description": "Gather and synthesize information that sensory receptors respond to stimuli, resulting in immediate behavior and/or storage as memories."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS2-1.",
    "Description": "Analyze and interpret data to provide evidence for the effects of resource availability on organisms and populations of organisms in an ecosystem."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS2-2.",
    "Description": "Construct an explanation that predicts patterns of interactions among organisms in a variety of ecosystems."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS2-3.",
    "Description": "Develop a model to describe the cycling of matter and flow of energy among living and nonliving parts of an ecosystem."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS2-4.",
    "Description": "Construct an argument supported by empirical evidence that changes to physical or biological components of an ecosystem affect populations."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS2-5.",
    "Description": "Evaluate competing design solutions for maintaining biodiversity and protecting ecosystem stability."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS3-1.",
    "Description": "Develop and use a model to explain why structural changes to genes (mutations) located on chromosomes may affect proteins and may result in harmful, beneficial, or neutral effects to the structure and function of the organism."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS3-2.",
    "Description": "Develop and use a model to describe how asexual reproduction results in offspring with identical genetic information and sexual reproduction results in offspring with genetic variation."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS4-1.",
    "Description": "Analyze and interpret data for patterns in the fossil record that document the existence, diversity, extinction, and change of life forms throughout the history of life on Earth under the assumption that natural laws operate today as in the past."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS4-2.",
    "Description": "Apply scientific ideas to construct an explanation for the anatomical similarities and differences among modern organisms and between modern and fossil organisms to infer evolutionary relationships."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS4-3.",
    "Description": "Analyze displays of pictorial data to compare patterns of similarities in the embryological development across multiple species to identify relationships not evident in the fully formed anatomy."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS4-4.",
    "Description": "Construct an explanation based on evidence that describes how genetic variations of traits in a population increase some individuals’ probability of surviving and reproducing in a specific environment."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS4-5.",
    "Description": "Gather and synthesize information about the technologies that have changed the way humans influence the inheritance of desired traits in organisms."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-LS4-6.",
    "Description": "Use mathematical representations to support explanations of how natural selection may lead to increases and decreases of specific traits in populations over time."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS1-1.",
    "Description": "Develop models to describe the atomic composition of simple molecules and extended structures."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS1-2.",
    "Description": "Analyze and interpret data on the properties of substances before and after the substances interact to determine if a chemical reaction has occurred."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS1-3.",
    "Description": "Gather and make sense of information to describe that synthetic materials come from natural resources and impact society."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS1-4.",
    "Description": "Develop a model that predicts and describes changes in particle motion, temperature, and phase (state) of a substance when thermal energy is added or removed."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS1-5.",
    "Description": "Develop and use a model to describe how the total number of atoms does not change in a chemical reaction and thus mass is conserved."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS1-6.",
    "Description": "Undertake a design project to construct, test, and modify a device that either releases or absorbs thermal energy during a chemical and/or physical process."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS1-7.",
    "Description": "Use evidence to illustrate that density is a property that can be used to identify samples of matter."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS1-8.",
    "Description": "Plan and conduct an investigation to demonstrate that mixtures are combinations of substances."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS2-1.",
    "Description": "Apply Newton’s Third Law to design a solution to a problem involving the motion of two colliding objects."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS2-2.",
    "Description": "Plan and conduct an investigation to provide evidence that the change in an object’s motion depends on the sum of the forces on the object and the mass of the object."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS2-3.",
    "Description": "Ask questions about data to determine the factors that affect the strength of electric and magnetic forces."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS2-4.",
    "Description": "Construct and present arguments using evidence to support the claim that gravitational interactions are attractive and depend on the masses of interacting objects and the distance between them."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS2-5.",
    "Description": "Conduct an investigation and evaluate the experimental design to provide evidence that fields exist between objects exerting forces on each other even though the objects are not in contact."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS3-1.",
    "Description": "Construct and interpret graphical displays of data to describe the relationships of kinetic energy to the mass of an object and to the speed of an object."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS3-2.",
    "Description": "Develop a model to describe that when the arrangement of objects interacting at a distance changes, different amounts of potential energy are stored in the system."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS3-3.",
    "Description": "Apply scientific principles to design, construct, and test a device that either minimizes or maximizes thermal energy transfer."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS3-4.",
    "Description": "Plan and conduct an investigation to determine the relationships among the energy transferred, the type of matter, the mass, and the change in the temperature of the sample of matter."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS3-5.",
    "Description": "Construct, use, and present an argument to support the claim that when work is done on or by a system, the energy of the system changes as energy is transferred to or from the system."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS3-6.",
    "Description": "Make observations to provide evidence that energy can be transferred by electric currents."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS4-1.",
    "Description": "Develop a model and use mathematical representations to describe waves that includes frequency, wavelength, and how the amplitude of a wave is related to the energy in a wave."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS4-2.",
    "Description": "Develop and use a model to describe that waves are reflected, absorbed, or transmitted through various materials."
  },
  {
    "Course": "Science",
    "Grade": 8,
    "Standard": "MS-PS4-3.",
    "Description": "Integrate qualitative scientific and technical information to support the claim that digitized signals are a more reliable way to encode and transmit information than analog signals."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS1-1.",
    "Description": "Develop a model based on evidence to illustrate the life span of the Sun and the role of nuclear fusion in the Sun’s core to release energy that eventually reaches Earth in the form of radiation."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS1-2.",
    "Description": "Construct an explanation of the Big Bang theory based on astronomical evidence of light spectra, motion of distant galaxies, and composition of matter in the universe."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS1-3.",
    "Description": "Communicate scientific ideas about the way stars, over their life cycle, produce elements."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS1-4.",
    "Description": "Use mathematical or computational representations to predict the motion of orbiting objects in the solar system."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS1-5.",
    "Description": "Evaluate evidence of the past and current movements of continental and oceanic crust and the theory of plate tectonics to explain the ages of crustal rocks."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS1-6.",
    "Description": "Apply scientific reasoning and evidence from ancient Earth materials, meteorites, and other planetary surfaces to construct an account of Earth’s formation and early history."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS1-7.",
    "Description": "Construct an explanation using evidence to support the claim that the phases of the moon, eclipses, tides and seasons change cyclically."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS2-1.",
    "Description": "Develop a model to illustrate how Earth’s internal and surface processes operate at different spatial and temporal scales to form continental and ocean-floor features."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS2-2.",
    "Description": "Analyze geoscience data to make the claim that one change to Earth’s surface can create feedbacks that cause changes to Earth’s systems."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS2-3.",
    "Description": "Develop a model based on evidence of Earth’s interior to describe the cycling of matter by thermal convection."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS2-4.",
    "Description": "Use a model to describe how variations in the flow of energy into and out of Earth’s systems result in changes in climate."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS2-5.",
    "Description": "Plan and conduct an investigation of the properties of water and its effects on Earth materials and surface processes."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS2-6.",
    "Description": "Develop a quantitative model to describe the cycling of carbon among the hydrosphere, atmosphere, geosphere, and biosphere."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS2-7.",
    "Description": "Construct an argument based on evidence about the coevolution of Earth’s systems and life on Earth."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS2-8.",
    "Description": "Evaluate data and communicate information to explain how the movement and interactions of air masses result in changes in weather conditions."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS3-1.",
    "Description": "Construct an explanation based on evidence for how the availability of natural resources, occurrence of natural hazards, and changes in climate have influenced human activity."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS3-2.",
    "Description": "Evaluate competing design solutions for developing, managing, and utilizing energy and mineral resources based on cost-benefit ratios."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS3-3.",
    "Description": "Create a computational simulation to illustrate the relationships among management of natural resources, the sustainability of human populations, and biodiversity."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS3-4.",
    "Description": "Evaluate or refine a technological solution that reduces impacts of human activities on natural systems."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS3-5.",
    "Description": "Analyze geoscience data and the results from global climate models to make an evidence-based forecast of the current rate of global or regional climate change and associated future impacts to Earth systems."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ESS3-6.",
    "Description": "Use a computational representation to illustrate the relationships among Earth systems and how those relationships are being modified due to human activity."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ETS1-1.",
    "Description": "Analyze a major global challenge to specify qualitative and quantitative criteria and constraints for solutions that account for societal needs and wants."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ETS1-2.",
    "Description": "Design a solution to a complex real-world problem by breaking it down into smaller, more manageable problems that can be solved through engineering."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ETS1-3.",
    "Description": "Evaluate a solution to a complex real-world problem based on prioritized criteria and trade-offs that account for a range of constraints, including cost, safety, reliability, and aesthetics, as well as possible social, cultural, and environmental impacts."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-ETS1-4.",
    "Description": "Use a computer simulation to model the impact of proposed solutions to a complex real-world problem with numerous criteria and constraints on interactions within and between systems relevant to the problem."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS1-1.",
    "Description": "Construct an explanation based on evidence for how the structure of DNA determines the structure of proteins which carry out the essential functions of life through systems of specialized cells."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS1-2.",
    "Description": "Develop and use a model to illustrate the hierarchical organization of interacting systems that provide specific functions within multicellular organisms."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS1-3.",
    "Description": "Plan and conduct an investigation to provide evidence that feedback mechanisms maintain homeostasis."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS1-4.",
    "Description": "Use a model to illustrate cellular division (mitosis) and differentiation."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS1-5.",
    "Description": "Use a model to illustrate how photosynthesis transforms light energy into stored chemical energy."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS1-6.",
    "Description": "Construct and revise an explanation based on evidence for how carbon, hydrogen, and oxygen from sugar molecules may combine with other elements such as nitrogen, sulfur, and phosphorus to form amino acids and other carbon-based molecules."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS1-7.",
    "Description": "Use a model to illustrate that aerobic cellular respiration is a chemical process whereby the bonds of food molecules and oxygen molecules are broken and the bonds in new compounds are formed resulting in a net transfer of energy."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS1-8.",
    "Description": "Use models to illustrate how human reproduction and development maintains continuity of life."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS2-1.",
    "Description": "Use mathematical and/or computational representations to support explanations of biotic and abiotic factors that affect carrying capacity of ecosystems at different scales."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS2-2.",
    "Description": "Use mathematical representations to support and revise explanations based on evidence about factors affecting biodiversity and populations in ecosystems of different scales."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS2-3.",
    "Description": "Construct and revise an explanation based on evidence for the cycling of matter and flow of energy in ecosystems."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS2-4.",
    "Description": "Use mathematical representations to support claims for the cycling of matter and flow of energy among organisms in an ecosystem."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS2-5.",
    "Description": "Develop a model to illustrate the role of various processes in the cycling of carbon among the biosphere, atmosphere, hydrosphere, and geosphere."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS2-6.",
    "Description": "Evaluate the claims, evidence, and reasoning that the complex interactions in ecosystems maintain relatively consistent numbers and types of organisms in stable conditions, but changing conditions may result in a new ecosystem."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS2-7.",
    "Description": "Design, evaluate, and refine a solution for reducing the impacts of human activities on the environment and biodiversity."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS2-8.",
    "Description": "Evaluate the evidence for the role of group behavior on individual and species’ chances to survive and reproduce."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS3-1.",
    "Description": "Ask questions to clarify relationships about the role of DNA and chromosomes in coding the instructions for characteristic traits passed from parents to offspring."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS3-2.",
    "Description": "Make and defend a claim based on evidence that inheritable genetic variations may result from: -1 new genetic combinations through meiosis, -2 viable errors occurring during replication, -3 mutations caused by environmental factors and/or -4 genetic engineering."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS3-3.",
    "Description": "Apply concepts of statistics and probability to explain the variation and distribution of expressed traits in a population."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS4-1.",
    "Description": "Communicate scientific information that common ancestry and biological evolution are supported by multiple lines of empirical evidence."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS4-2.",
    "Description": "Construct an explanation based on evidence that the process of evolution primarily results from four factors: -1 the potential for a species to increase in number, -2 the heritable genetic variation of individuals in a species due to mutation and sexual reproduction, -3 competition for limited resources, and -4 the proliferation of those organisms that are better able to survive and reproduce in the environment."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS4-3.",
    "Description": "Apply concepts of statistics and probability to support explanations that organisms with an advantageous heritable trait tend to increase in proportion to organisms lacking this trait."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS4-4.",
    "Description": "Construct an explanation based on evidence for how natural selection leads to adaptation of populations."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-LS4-5.",
    "Description": "Evaluate the evidence supporting claims that changes in environmental conditions may result in: -1 increases in the number of individuals of some species, -2 the emergence of new species over time, and -3 the extinction of other species."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-1.",
    "Description": "Use the periodic table as a model to predict the relative properties of elements based on the patterns of electrons in the outermost energy level of atoms."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-10.",
    "Description": "Use evidence to support claims regarding the formation, properties and behaviors of solutions at bulk scales."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-11.",
    "Description": "Plan and conduct an investigation to compare properties and behaviors of acids and bases."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-12.",
    "Description": "Use evidence to illustrate that some chemical reactions involve the transfer of electrons as an energy conversion occurs within a system."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-2.",
    "Description": "Construct and revise an explanation for the outcome of a simple chemical reaction based on the outermost electron states of atoms, trends in the periodic table, and knowledge of the patterns of chemical properties."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-3.",
    "Description": "Plan and conduct an investigation to gather evidence to compare the structure of substances at the bulk scale to infer the strength of electrical forces between particles."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-4.",
    "Description": "Develop a model to illustrate that the release or absorption of energy from a chemical reaction system depends upon the changes in total bond energy."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-5.",
    "Description": "Apply scientific principles and evidence to explain how the rate of a physical or chemical change is affected when conditions are varied."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-6.",
    "Description": "Refine the design of a chemical system by specifying a change in conditions that would produce increased amounts of products at equilibrium."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-7.",
    "Description": "Use mathematical representations to support the claim that atoms, and therefore mass, are conserved during a chemical reaction."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-8.",
    "Description": "Develop models to illustrate the changes in the composition of the nucleus of the atom and the energy released during the processes of fission, fusion, and radioactive decay."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS1-9.",
    "Description": "Analyze data to support the claim that the combined gas law describes the relationships among volume, pressure, and temperature for a sample of an ideal gas."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS2-1.",
    "Description": "Analyze data to support the claim that Newton’s Second Law of Motion describes the mathematical relationship among the net force on a macroscopic object, its mass, and its acceleration."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS2-2.",
    "Description": "Use mathematical representations to support the claim that the total momentum of a system of objects is conserved when there is no net force on the system."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS2-3.",
    "Description": "Apply scientific and engineering ideas to design, evaluate, and refine a device that minimizes the force on a macroscopic object during a collision."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS2-4.",
    "Description": "Use mathematical representations of Newton’s Law of Gravitation and Coulomb’s Law to describe and predict the gravitational and electrostatic forces between objects."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS2-5.",
    "Description": "Plan and conduct an investigation to provide evidence that an electric current can produce a magnetic field and that a changing magnetic field can produce an electric current."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS2-6.",
    "Description": "Communicate scientific and technical information about why the particulate-level structure is important in the functioning of designed materials."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS3-1.",
    "Description": "Create a computational model to calculate the change in the energy of one component in a system when the change in energy of the other component(s) and energy flows in and out of the system are known."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS3-2.",
    "Description": "Develop and use models to illustrate that energy at the macroscopic scale can be accounted for as a combination of energy associated with the motions of particles (objects) and energy associated with the relative position of particles (objects)."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS3-3.",
    "Description": "Design, build, and refine a device that works within given constraints to convert one form of energy into another form of energy."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS3-4.",
    "Description": "Plan and conduct an investigation to provide evidence that the transfer of thermal energy when two components of different temperature are combined within a closed system results in a more uniform energy distribution among the components in the system (second law of thermodynamics)."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS3-5.",
    "Description": "Develop and use a model of two objects interacting through electric or magnetic fields to illustrate the forces between objects and the changes in energy of the objects due to the interaction."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS3-6.",
    "Description": "Analyze data to support the claim that Ohm’s Law describes the mathematical relationship among the potential difference, current, and resistance of an electric circuit."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS4-1.",
    "Description": "Use mathematical representations to support a claim regarding relationships among the period, frequency, wavelength, and speed of waves traveling and transferring energy (amplitude, frequency) in various media."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS4-2.",
    "Description": "Evaluate questions about the advantages of using a digital transmission and storage of information."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS4-3.",
    "Description": "Evaluate the claims, evidence, and reasoning behind the idea that electromagnetic radiation can be described either by a wave model or a particle model (quantum theory), and that for some situations one model is more useful than the other."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS4-4.",
    "Description": "Evaluate the validity and reliability of claims in published materials of the effects that different frequencies of electromagnetic radiation have when absorbed by matter."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS4-5.",
    "Description": "Communicate technical information about how some technological devices use the principles of wave behavior and wave interactions with matter to transmit and capture information and energy."
  },
  {
    "Course": "Living Environment",
    "Grade": 9,
    "Standard": "HS-PS4-6.",
    "Description": "Use mathematical models to determine relationships among the size and location of images, size and location of objects, and focal lengths of lenses and mirrors."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS1-1.",
    "Description": "Develop a model based on evidence to illustrate the life span of the Sun and the role of nuclear fusion in the Sun’s core to release energy that eventually reaches Earth in the form of radiation."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS1-2.",
    "Description": "Construct an explanation of the Big Bang theory based on astronomical evidence of light spectra, motion of distant galaxies, and composition of matter in the universe."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS1-3.",
    "Description": "Communicate scientific ideas about the way stars, over their life cycle, produce elements."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS1-4.",
    "Description": "Use mathematical or computational representations to predict the motion of orbiting objects in the solar system."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS1-5.",
    "Description": "Evaluate evidence of the past and current movements of continental and oceanic crust and the theory of plate tectonics to explain the ages of crustal rocks."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS1-6.",
    "Description": "Apply scientific reasoning and evidence from ancient Earth materials, meteorites, and other planetary surfaces to construct an account of Earth’s formation and early history."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS1-7.",
    "Description": "Construct an explanation using evidence to support the claim that the phases of the moon, eclipses, tides and seasons change cyclically."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS2-1.",
    "Description": "Develop a model to illustrate how Earth’s internal and surface processes operate at different spatial and temporal scales to form continental and ocean-floor features."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS2-2.",
    "Description": "Analyze geoscience data to make the claim that one change to Earth’s surface can create feedbacks that cause changes to Earth’s systems."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS2-3.",
    "Description": "Develop a model based on evidence of Earth’s interior to describe the cycling of matter by thermal convection."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS2-4.",
    "Description": "Use a model to describe how variations in the flow of energy into and out of Earth’s systems result in changes in climate."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS2-5.",
    "Description": "Plan and conduct an investigation of the properties of water and its effects on Earth materials and surface processes."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS2-6.",
    "Description": "Develop a quantitative model to describe the cycling of carbon among the hydrosphere, atmosphere, geosphere, and biosphere."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS2-7.",
    "Description": "Construct an argument based on evidence about the coevolution of Earth’s systems and life on Earth."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS2-8.",
    "Description": "Evaluate data and communicate information to explain how the movement and interactions of air masses result in changes in weather conditions."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS3-1.",
    "Description": "Construct an explanation based on evidence for how the availability of natural resources, occurrence of natural hazards, and changes in climate have influenced human activity."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS3-2.",
    "Description": "Evaluate competing design solutions for developing, managing, and utilizing energy and mineral resources based on cost-benefit ratios."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS3-3.",
    "Description": "Create a computational simulation to illustrate the relationships among management of natural resources, the sustainability of human populations, and biodiversity."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS3-4.",
    "Description": "Evaluate or refine a technological solution that reduces impacts of human activities on natural systems."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS3-5.",
    "Description": "Analyze geoscience data and the results from global climate models to make an evidence-based forecast of the current rate of global or regional climate change and associated future impacts to Earth systems."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ESS3-6.",
    "Description": "Use a computational representation to illustrate the relationships among Earth systems and how those relationships are being modified due to human activity."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ETS1-1.",
    "Description": "Analyze a major global challenge to specify qualitative and quantitative criteria and constraints for solutions that account for societal needs and wants."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ETS1-2.",
    "Description": "Design a solution to a complex real-world problem by breaking it down into smaller, more manageable problems that can be solved through engineering."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ETS1-3.",
    "Description": "Evaluate a solution to a complex real-world problem based on prioritized criteria and trade-offs that account for a range of constraints, including cost, safety, reliability, and aesthetics, as well as possible social, cultural, and environmental impacts."
  },
  {
    "Course": "Earth Science",
    "Grade": 10,
    "Standard": "HS-ETS1-4.",
    "Description": "Use a computer simulation to model the impact of proposed solutions to a complex real-world problem with numerous criteria and constraints on interactions within and between systems relevant to the problem."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS1-1.",
    "Description": "Construct an explanation based on evidence for how the structure of DNA determines the structure of proteins which carry out the essential functions of life through systems of specialized cells."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS1-2.",
    "Description": "Develop and use a model to illustrate the hierarchical organization of interacting systems that provide specific functions within multicellular organisms."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS1-3.",
    "Description": "Plan and conduct an investigation to provide evidence that feedback mechanisms maintain homeostasis."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS1-4.",
    "Description": "Use a model to illustrate cellular division (mitosis) and differentiation."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS1-5.",
    "Description": "Use a model to illustrate how photosynthesis transforms light energy into stored chemical energy."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS1-6.",
    "Description": "Construct and revise an explanation based on evidence for how carbon, hydrogen, and oxygen from sugar molecules may combine with other elements such as nitrogen, sulfur, and phosphorus to form amino acids and other carbon-based molecules."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS1-7.",
    "Description": "Use a model to illustrate that aerobic cellular respiration is a chemical process whereby the bonds of food molecules and oxygen molecules are broken and the bonds in new compounds are formed resulting in a net transfer of energy."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS1-8.",
    "Description": "Use models to illustrate how human reproduction and development maintains continuity of life."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS2-1.",
    "Description": "Use mathematical and/or computational representations to support explanations of biotic and abiotic factors that affect carrying capacity of ecosystems at different scales."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS2-2.",
    "Description": "Use mathematical representations to support and revise explanations based on evidence about factors affecting biodiversity and populations in ecosystems of different scales."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS2-3.",
    "Description": "Construct and revise an explanation based on evidence for the cycling of matter and flow of energy in ecosystems."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS2-4.",
    "Description": "Use mathematical representations to support claims for the cycling of matter and flow of energy among organisms in an ecosystem."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS2-5.",
    "Description": "Develop a model to illustrate the role of various processes in the cycling of carbon among the biosphere, atmosphere, hydrosphere, and geosphere."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS2-6.",
    "Description": "Evaluate the claims, evidence, and reasoning that the complex interactions in ecosystems maintain relatively consistent numbers and types of organisms in stable conditions, but changing conditions may result in a new ecosystem."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS2-7.",
    "Description": "Design, evaluate, and refine a solution for reducing the impacts of human activities on the environment and biodiversity."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS2-8.",
    "Description": "Evaluate the evidence for the role of group behavior on individual and species’ chances to survive and reproduce."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS3-1.",
    "Description": "Ask questions to clarify relationships about the role of DNA and chromosomes in coding the instructions for characteristic traits passed from parents to offspring."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS3-2.",
    "Description": "Make and defend a claim based on evidence that inheritable genetic variations may result from: -1 new genetic combinations through meiosis, -2 viable errors occurring during replication, -3 mutations caused by environmental factors and/or -4 genetic engineering."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS3-3.",
    "Description": "Apply concepts of statistics and probability to explain the variation and distribution of expressed traits in a population."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS4-1.",
    "Description": "Communicate scientific information that common ancestry and biological evolution are supported by multiple lines of empirical evidence."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS4-2.",
    "Description": "Construct an explanation based on evidence that the process of evolution primarily results from four factors: -1 the potential for a species to increase in number, -2 the heritable genetic variation of individuals in a species due to mutation and sexual reproduction, -3 competition for limited resources, and -4 the proliferation of those organisms that are better able to survive and reproduce in the environment."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS4-3.",
    "Description": "Apply concepts of statistics and probability to support explanations that organisms with an advantageous heritable trait tend to increase in proportion to organisms lacking this trait."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS4-4.",
    "Description": "Construct an explanation based on evidence for how natural selection leads to adaptation of populations."
  },
  {
    "Course": "Life Science",
    "Grade": 10,
    "Standard": "HS-LS4-5.",
    "Description": "Evaluate the evidence supporting claims that changes in environmental conditions may result in: -1 increases in the number of individuals of some species, -2 the emergence of new species over time, and -3 the extinction of other species."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-1.",
    "Description": "Use the periodic table as a model to predict the relative properties of elements based on the patterns of electrons in the outermost energy level of atoms."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-10.",
    "Description": "Use evidence to support claims regarding the formation, properties and behaviors of solutions at bulk scales."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-11.",
    "Description": "Plan and conduct an investigation to compare properties and behaviors of acids and bases."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-12.",
    "Description": "Use evidence to illustrate that some chemical reactions involve the transfer of electrons as an energy conversion occurs within a system."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-2.",
    "Description": "Construct and revise an explanation for the outcome of a simple chemical reaction based on the outermost electron states of atoms, trends in the periodic table, and knowledge of the patterns of chemical properties."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-3.",
    "Description": "Plan and conduct an investigation to gather evidence to compare the structure of substances at the bulk scale to infer the strength of electrical forces between particles."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-4.",
    "Description": "Develop a model to illustrate that the release or absorption of energy from a chemical reaction system depends upon the changes in total bond energy."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-5.",
    "Description": "Apply scientific principles and evidence to explain how the rate of a physical or chemical change is affected when conditions are varied."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-6.",
    "Description": "Refine the design of a chemical system by specifying a change in conditions that would produce increased amounts of products at equilibrium."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-7.",
    "Description": "Use mathematical representations to support the claim that atoms, and therefore mass, are conserved during a chemical reaction."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-8.",
    "Description": "Develop models to illustrate the changes in the composition of the nucleus of the atom and the energy released during the processes of fission, fusion, and radioactive decay."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS1-9.",
    "Description": "Analyze data to support the claim that the combined gas law describes the relationships among volume, pressure, and temperature for a sample of an ideal gas."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS2-1.",
    "Description": "Analyze data to support the claim that Newton’s Second Law of Motion describes the mathematical relationship among the net force on a macroscopic object, its mass, and its acceleration."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS2-2.",
    "Description": "Use mathematical representations to support the claim that the total momentum of a system of objects is conserved when there is no net force on the system."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS2-3.",
    "Description": "Apply scientific and engineering ideas to design, evaluate, and refine a device that minimizes the force on a macroscopic object during a collision."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS2-4.",
    "Description": "Use mathematical representations of Newton’s Law of Gravitation and Coulomb’s Law to describe and predict the gravitational and electrostatic forces between objects."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS2-5.",
    "Description": "Plan and conduct an investigation to provide evidence that an electric current can produce a magnetic field and that a changing magnetic field can produce an electric current."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS2-6.",
    "Description": "Communicate scientific and technical information about why the particulate-level structure is important in the functioning of designed materials."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS3-1.",
    "Description": "Create a computational model to calculate the change in the energy of one component in a system when the change in energy of the other component(s) and energy flows in and out of the system are known."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS3-2.",
    "Description": "Develop and use models to illustrate that energy at the macroscopic scale can be accounted for as a combination of energy associated with the motions of particles (objects) and energy associated with the relative position of particles (objects)."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS3-3.",
    "Description": "Design, build, and refine a device that works within given constraints to convert one form of energy into another form of energy."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS3-4.",
    "Description": "Plan and conduct an investigation to provide evidence that the transfer of thermal energy when two components of different temperature are combined within a closed system results in a more uniform energy distribution among the components in the system (second law of thermodynamics)."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS3-5.",
    "Description": "Develop and use a model of two objects interacting through electric or magnetic fields to illustrate the forces between objects and the changes in energy of the objects due to the interaction."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS3-6.",
    "Description": "Analyze data to support the claim that Ohm’s Law describes the mathematical relationship among the potential difference, current, and resistance of an electric circuit."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS4-1.",
    "Description": "Use mathematical representations to support a claim regarding relationships among the period, frequency, wavelength, and speed of waves traveling and transferring energy (amplitude, frequency) in various media."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS4-2.",
    "Description": "Evaluate questions about the advantages of using a digital transmission and storage of information."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS4-3.",
    "Description": "Evaluate the claims, evidence, and reasoning behind the idea that electromagnetic radiation can be described either by a wave model or a particle model (quantum theory), and that for some situations one model is more useful than the other."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS4-4.",
    "Description": "Evaluate the validity and reliability of claims in published materials of the effects that different frequencies of electromagnetic radiation have when absorbed by matter."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS4-5.",
    "Description": "Communicate technical information about how some technological devices use the principles of wave behavior and wave interactions with matter to transmit and capture information and energy."
  },
  {
    "Course": "Physical Science",
    "Grade": 10,
    "Standard": "HS-PS4-6.",
    "Description": "Use mathematical models to determine relationships among the size and location of images, size and location of objects, and focal lengths of lenses and mirrors."
  }
]
     }
     try {
        const userRef = db.collection("standards");
       
        const docRef = await userRef.add(Standards);
        console.log(docRef)
       // this.id=docRef.id
      } catch (error) {
        console.error("Error adding document:", error);
      }
       this.isLoading = false
       
    }

    },
    mounted (){
  this.updateLicenses()
 }
}
</script>
